'use strict';
import angular from 'angular';
import $ from 'jquery';
import _ from 'lodash';
import swal from 'bootstrap-sweetalert';

angular.module('importwizard.ctrls', [])
    .controller('ImportWizardListController', ImportWizardListController)
    .controller('ImportWizardController', ImportWizardController)
    .controller('ImportWizardManualUploadController', ImportWizardManualUploadController)
    .controller('ImportWizardDeleteDataController', ImportWizardDeleteDataController);

/**
 * Import wizard grid controller
 * @ngInject
 * @param UIFactory
 * @param data
 * @param metadata
 * @param IMPORT_AUTH_TYPE
 * @constructor
 */
function ImportWizardListController(
    $scope,
    $http,
    UIFactory,
    data,
    metadata,
    IMPORT_AUTH_TYPE
) {
    $scope.is_admin = $scope.util.isSuperAdmin();
    $scope.dtOptions = {};
    $scope.dtOptions.hasFixedHeader = false;
    $scope.dtOptions.data = data;
    $scope.dtOptions.columns = metadata.plain();
    // The parameter to show/hide the sortable icon from the Datatable Angular code is "sortable" - the actual
    // back-end return is_sortable instead of sortable.
    for (let i = 0, len = $scope.dtOptions.columns.length; i < len; i++) {
        $scope.dtOptions.columns[i].sortable = $scope.dtOptions.columns[i].is_sortable;
    }
    $scope.dtOptions.entityName = 'importwizard';
    $scope.dtOptions.itemName = 'smart connector';
    $scope.dtOptions.iActions = 1;
    $scope.dtOptions.customRenders = {};
    $scope.dtOptions.customRenders['id'] = function (data, type, full) {
        let uploadBtn = '<a class="action has-tooltip" href="#/importwizard/manual-upload/' + full.id + '" data-original-title="Upload"><span class="icon icon-upload"></span></a>';
        let editBtn = $.core.datatable.displayEditRowButton(data, $scope.dtOptions, full);
        let cloneBtn = '<a class="action has-tooltip clone-action" data-id="'+full.id+'" data-name="'+full.name+'" data-original-title="Clone"><span class="icon icon-copy"></span></a>';
        let buttons = uploadBtn + editBtn + cloneBtn;

        if ($scope.util.isSuperAdmin()) {

            buttons += '<a class="action has-tooltip" href="/server/api/importwizard/export/json/' + full.id + '" data-original-title="Export"><span class="icon icon-download"></span></a>';

            let deleteDataBtn;

            if (full.has_data || full.has_mapping) {
                deleteDataBtn = '<a class="action has-tooltip" href="#/importwizard/delete-data/' + full.id + '" data-original-title="Delete Data/Assignments"><span class="icon icon-bomb"></span></a>';
            } else {
                deleteDataBtn = '<a class="action disabled"><span class="icon icon-bomb"></span></a>';
            }

            buttons += deleteDataBtn;
        }

        return buttons;
    };
    $scope.dtOptions.customRenders['name'] = function (data, type, full) {
        let color = full.custom_color || full.color;
        let name = full.custom_name || data;
        return '<div class="service-square service-square-16" style="background-color:' + color + '"><div class="icon ' + full.custom_icon + '"></div></div>' + name;
    };
    $scope.dtOptions.customRenders['auth_type'] = function (data) {
        let preFormat = '<div style="white-space: nowrap;">',
            postFormat = '</div>';
        if (data === 'Google Drive')
        {
            return preFormat + $.core.datatable.render('prependIcon', {
                value: data,
                icon: 'serviceicon-googledrive',
                color: '#0f9d58'
            }) + postFormat;
        }
        else if (data === 'Dropbox')
        {
            return preFormat + $.core.datatable.render('prependIcon', {
                value: data,
                icon: 'serviceicon-dropbox',
                color: '#007ee5'
            }) + postFormat;
        }
        else if (data === 'Amazon S3')
        {
            return preFormat + $.core.datatable.render('prependIcon', {
                value: data,
                icon: 'serviceicon-amazon',
                color: '#ff9900'
            }) + postFormat;
        }

        return preFormat + data + postFormat;
    };

    // adding created row
    $scope.dtOptions.fnCreatedRow = function(row, data) {
        $(row).find('td.action-cell a').each(function () {
            if ($(this).hasClass('clone-action')) {
                $(this).click(function(e) {
                    let entityName = $(this).data('name');
                    let id = $(this).data('id');
                    swal({
                            title: 'Clone',
                            text: 'You are about to clone <b>' + entityName + '</b>',
                            html: true,
                            type: 'warning',
                            showCancelButton: true,
                            confirmButtonText: 'Yes, clone it',
                            cancelButtonText: 'Cancel',
                            closeOnConfirm: true,
                            closeOnCancel: true,
                            showLoaderOnConfirm: true
                        },
                        function(isConfirm) {
                            if (isConfirm) {
                                $http.post(
                                    '/server/api/importwizard/clone/'+id, {}
                                ).then(function successCallback(json) {
                                    if (json.data && json.data.data.link) {
                                        window.location.href = json.data.data.link + '?import=1';
                                    } else {
                                        UIFactory.notify.showError('We were unable to clone this Smart Connector. Please try again or contact your account manager if the problem persists. [err 1]');
                                    }
                                });
                            }
                        });
                });
            }
        });
    };

    $scope.successUploadJsonImportCallback = function(data) {
        $('#import-smart-connector-modal button.close').click();
        let link = '#/importwizard/detail/' + data.id + '?import=1';
        if (data.warning) {
            let warningTitle = 'Your Smart Connector has been created!';
            let textLink = '<p><a href="'+link+'">Click here to edit your Smart Connector now</p>';
            UIFactory.notify.showWarning('<br />'+data.warning+textLink, {autoHide: false, title: warningTitle});
        } else {
            window.location.href = link;
        }
    };
    $scope.errorUploadJsonImportCallback = function(data) {
        if (data.jqXHR && data.jqXHR.responseJSON.error) {
            UIFactory.notify.showError(data.jqXHR.responseJSON.error);
        }
    };
}

function ImportWizardSublistListController($scope, subleveldata, sublevelmetadata, $timeout, UIFactory) {
    $scope.is_sublevel_reloaded = false;
    $scope.is_sublevel_contains_errors = false;
    if ($scope.dtOptionsSublevel !== undefined) {
        $scope.is_sublevel_reloaded = true;
        $scope.is_sublevel_contains_errors = false;
        $('#sublevellist > tbody  > tr').each(function(index, tr) {
            $.core.datatable.oTables.sublevellist.oTable.api().row($(tr)).remove();
        });
        $.each(subleveldata, function(key, value) {
            $.core.datatable.oTables.sublevellist.oTable.fnAddData(value, true);
        });
        $timeout(function() {
            if ($scope.is_sublevel_contains_errors) {
                UIFactory.notify.showWarning('Please ensure that your sublevel dataviews inheritance fields are correctly set up.');
            }
        }, 4000);
        return;
    }
    $scope.dtOptionsSublevel = {};
    $scope.dtOptionsSublevel.hasFixedHeader = false;
    $scope.dtOptionsSublevel.data = subleveldata;
    $scope.dtOptionsSublevel.columns = sublevelmetadata.plain();
    $scope.dtOptionsSublevel.entityName = 'importwizardsublevel';
    $scope.dtOptionsSublevel.itemName = 'smart connector sublevel';
    $scope.dtOptionsSublevel.iActions = 1;
    $scope.dtOptionsSublevel.customRenders = {};
    $scope.dtOptionsSublevel.customRenders['id'] = function(data, type, full) {
        let uploadBtn = '<a class="action has-tooltip" href="#/importwizard/manual-upload/' + full.id + '" data-original-title="Upload"><span class="icon icon-upload"></span></a>';
        let editBtn = '<a class="action has-tooltip" title="" href="#/importwizard/detail/'+full.parent_id+'/sub/'+full.id+'" data-original-title="Edit"><span class="icon icon-pencil"></span></a>';

        return uploadBtn + editBtn;
    };

    $scope.dtOptionsSublevel.customRenders['custom_validations'] = function(data, type, full) {
        if (!full.custom_validations) {
            return '<div class="icon icon-check connectionstatus-success"></div>';
        }
        // Turn out warning if sublevel is activated:
        if (full.is_sublevel_active) {
            $scope.is_sublevel_contains_errors = true;
        }

        return '<span class="icon icon-exclamation-circle connectionstatus-error has-tooltip" title="' + full.custom_validations.replace(/"/g, '&quot;') + '"</span>';
    };
    $scope.dtOptionsSublevel.customRenders['name'] = function(data, type, full) {
        let color = full.custom_color || full.color;
        let name = full.custom_name || data;

        return '<div class="service-square service-square-16" style="background-color:' + color + '"><div class="icon ' + full.custom_icon + '"></div></div>' + name;
    };
    $scope.dtOptionsSublevel.customRenders['auth_type'] = function(data) {
        let preFormat = '<div style="white-space: nowrap;">',
            postFormat = '</div>';

        if (data === 'Google Drive') {
            return preFormat + $.core.datatable.render('prependIcon', {
                value: data,
                icon: 'serviceicon-googledrive',
                color: '#0f9d58'
            }) + postFormat;
        } else if (data === 'Dropbox') {
            return preFormat + $.core.datatable.render('prependIcon', {
                value: data,
                icon: 'serviceicon-dropbox',
                color: '#007ee5'
            }) + postFormat;
        } else if (data === 'Amazon S3') {
            return preFormat + $.core.datatable.render('prependIcon', {
                value: data,
                icon: 'serviceicon-amazon',
                color: '#ff9900'
            }) + postFormat;
        }

        return preFormat + data + postFormat;
    };
}

//
// Import wizard controller
//
ImportWizardController.$inject = ['$http', '$rootScope', '$scope', '$timeout', '$stateParams', 'UIFactory', 'IMPORT_CONN_TYPE', 'IMPORT_AUTH_TYPE', 'IMPORT_WIZARD', 'DEFAULT_PREPROCESS_FUNCTION', 'PREPROCESS_FUNCTION_CUSTOM_FUNCTION_VALUE', 'AppFactory', 'ColumnFormat', 'service', 'authTypes', 'dateFormats', 'isCloning', 'oauthUser', 'subleveldata', 'sublevelmetadata', 'parents', 'sc_modules', 'PubSub', 'genericInfo', 'ImportWizardFactory'];

/**
 * Import wizard controller
 * @ngInject
 *
 * @param $http
 * @param $rootScope
 * @param $scope
 * @param $timeout
 * @param $stateParams
 * @param UIFactory
 * @param IMPORT_CONN_TYPE
 * @param IMPORT_AUTH_TYPE
 * @param IMPORT_WIZARD
 * @param DEFAULT_PREPROCESS_FUNCTION
 * @param PREPROCESS_FUNCTION_CUSTOM_FUNCTION_VALUE
 * @param AppFactory
 * @param ColumnFormat
 * @param service
 * @param authTypes
 * @param dateFormats
 * @param isCloning
 * @param oauthUser
 * @param subleveldata
 * @param sublevelmetadata
 * @param parents
 * @param sc_modules
 * @param PubSub
 * @param genericInfo
 * @param ImportWizardFactory
 */
function ImportWizardController(
    $http,
    $rootScope,
    $scope,
    $timeout,
    $stateParams,
    UIFactory,
    IMPORT_CONN_TYPE,
    IMPORT_AUTH_TYPE,
    IMPORT_WIZARD,
    DEFAULT_PREPROCESS_FUNCTION,
    PREPROCESS_FUNCTION_CUSTOM_FUNCTION_VALUE,
    AppFactory,
    ColumnFormat,
    service,
    authTypes,
    dateFormats,
    isCloning,
    oauthUser,
    subleveldata,
    sublevelmetadata,
    parents,
    sc_modules,
    PubSub,
    genericInfo,
    ImportWizardFactory
) {
    $rootScope.redirector.link = AppFactory.getMdsRoute();
    $scope.manage_url = AppFactory.getMdsRoute();
    $rootScope.redirector.param.key = 'service_id';
    $scope.defaultIcon = 'icon-bar-chart-o'; // default custom icon
    let brandMappings = AppFactory.getBrandMappings();
    let isSublevel = !_.isEmpty($stateParams.parent_id);
    $scope.isSublevel = isSublevel;
    $scope.origin_url = window.location.href;
    $scope.ColumnFormat = ColumnFormat;

    $scope.tooltip = {};
    $scope.tooltip.tabParsingTabNameFilter = 'Isolates which tabs in your file data rows should be fetched from. Only tabs that match the indicated pattern will be used.';
    $scope.tooltip.preprocessFunctions = 'Send your files to an external endpoint for pre-processing before being parsed by the Smart Connector.';
    $scope.tooltip.useForMargin = 'This feature only works with currency type fields.';
    $scope.tooltip.jsonFileConfig = 'Allows you to parse JSON files from a specific root object and/or to exclude some object paths when parsing the data.';
    $scope.tooltip.quickMapping = 'Allows you to present the assignment options on screen from a static location without waiting for the platform to comb through all rows of data to create an assignment list on demand. This feature allows your assignment screen to load faster.';
    $scope.tooltip.mapSingleClient = 'Assign an entire Smart Connector\'s data to a single client. This eliminates the need for an assignment field in the file. Once your data fields are configured, this switch becomes disabled.';
    $scope.tooltip.useForMapping = '"Assignment Field" is a required field that indicates the advertiser/' + brandMappings.campaign + ' name that will be shown on the manage data sources screen. Typically, this would be a ' + brandMappings.campaign + ' Name or ' + brandMappings.client + ' Name.';
    $scope.tooltip.advancedAssignment = 'If selected, you can select a field to be use as the backend assignment value (ID) and choose another field to be displayed as the assignment value in the application. Example: Assignment ID: ABC123 (used in the back end code); Assignment value: Campaign ABC (shown in the application). If you already have assignment values loaded, the toggle cannot be updated.';
    $scope.tooltip.advancedAssignmentId = '"Assignment ID" is a required field that indicates the advertiser/' + brandMappings.campaign + ' ID that will be shown on the manage data sources screen. Typically, this would be a ' + brandMappings.campaign + ' ID or ' + brandMappings.client + ' ID.';
    $scope.tooltip.advancedAssignmentLabel = '"Assignment Value" is a required field that indicates the advertiser/' + brandMappings.campaign + ' Value that will be shown on the manage data sources screen. Typically, this would be a ' + brandMappings.campaign + ' Name or ' + brandMappings.client + ' Name.';
    $scope.tooltip.isActive = 'Indicates whether the Smart Connector is active or not.';
    $scope.tooltip.isGeoDataActive = 'Checking this box indicates that the Smart Connector should work with Geo dashboard widgets. Please configure them in the Geo Configuration section after activating this toggle switch.';
    $scope.tooltip.isSublevelActive = 'Indicates whether this child data view is active or not. If deactivated, data will not be fetched for this data view or for any child data views under this data view. When re-activating a data view and it\'s children, it is strongly suggested to delete all campaign data on this data views and it\'s children, then issue a data reload.';
    $scope.tooltip.sampleFileData = 'This list shows the values from the first row of data from your sample file. It is provided for reference to help configure your data source properly.';
    $scope.tooltip.sampleFileColumn = '"Column Name" is the name of the column from your file. This column name must match the column names in the data file you plan to process.';
    $scope.tooltip.columnLabel = '"Field Name" will be the name used within the application to identify the data or metric imported.';
    $scope.tooltip.parentInheritance = 'Parent inheritance is a required field that links a child sublevel data view to a parent data view. The parent field link is most commonly a unique key field.';
    $scope.tooltip.useForDateRange = 'Use for date range indicates the field that will be used as the data date for the rows processed. This date controls what data is retrieved when using the date range selection within the reports and dashboard. This usually corresponds to the date to which the performance data applies. If you data does not have a reporting date, you can elect to configure your Smart Connector to process data with no date - see Advanced Options.';
    $scope.tooltip.useForDateOrDatetimeRange = 'Use for date/datetime range indicates the field that will be used as the data date or datetime for the rows processed. This date or datetime controls what data is retrieved when using the date/datetime range selection within the reports and dashboard. This usually corresponds to the date/datetime to which the performance data applies. If your data does not have a reporting date or datetime, you can elect to configure your Smart Connector to process data with no date or datetime - see Advanced Options. Note: the "datetime" field type is available on request.';
    $scope.tooltip.useForUnique = 'Unique fields are a required configuration that indicates the field or fields from your source data that differentiates one row of data from another. When in doubt, look at your data for one day, and ask yourself what makes that row of data different (or the most unique) from the other rows.  By default we will include "Date" as a part of the Unique Key and it cannot be set here.';
    $scope.tooltip.isMetric = 'This indicates that the field represents a numeric value that should be treated as a performance metric. Metrics are automatically aggregated over timeframes and can be summarized in reports and dashboards. Metrics are always Number, Decimal, Percent, or Currency, Duration, or Number (large) types fields.';
    $scope.tooltip.includeInCharts = 'If you check this box, the metric will be part of the row breakdown chart in a few legacy parts of the platform.';
    $scope.tooltip.delete = 'Any fields deleted from this configuration will be ignored when processing incoming data.';
    $scope.tooltip.drilldownView = 'This data view name will be used as the name of the one level breakdown of each ' + brandMappings.campaign + '/' + brandMappings.client + '\'s data. Do not pluralize, the dashboard will do this for you.';
    $scope.tooltip.originOfData = 'Filling in this field helps us understand how people are using the Smart Connector and guides improvements to our overall Connector offerings.';
    $scope.tooltip.deliveryType = 'Select the source from which the application will retrieve data on a periodic/nightly basis or when "Update Dashboard" is selected.';
    $scope.tooltip.protocol = 'Select the security level of your connection.';
    $scope.tooltip.host = 'Specify URL/IP address where your database is hosted.';
    $scope.tooltip.port = 'Specify the port your server uses.';
    $scope.tooltip.azure_server = 'Specify the subdomain of the Microsoft Azure SQL server name.';
    $scope.tooltip.sql_server = 'Specify the domain of the Microsoft SQL server.';
    $scope.tooltip.database = 'This is the default database that the platform will connect to';
    $scope.tooltip.character_set = 'This is the character set of your database.';
    $scope.tooltip.username = 'This username will be used to connect to your server.';
    $scope.tooltip.password = 'This password will be used to connect to your server.';
    $scope.tooltip.access_key_id = 'This is the Access Key ID to use to connect to your Amazon S3 service.';
    $scope.tooltip.secret_access_key = 'This is the Secret Access Key to use to connect to your Amazon S3 service.';
    $scope.tooltip.bucket = 'This is the Amazon S3 bucket name.';
    $scope.tooltip.athena_catalog = 'This is the Amazon Athena catalog name.';
    $scope.tooltip.athena_database = 'This is the Amazon Athena database name.';
    $scope.tooltip.athena_output_location = 'This is the Amazon output location. ie: s3://myawsbucket/myathenaresult/';
    $scope.tooltip.bucket_gcs = 'This is the Google Cloud Storage bucket name. If provided to you as "gs://[bucket_name]" do not include the "gs://" prefix in order to connect.';
    $scope.tooltip.account = 'Your Snowflake account name, including the region (i.e. ml99999.us-east-1).';
    $scope.tooltip.project = 'Your Google BigQuery Project ID. Can be obtained from your Google Cloud console.';
    $scope.tooltip.googleSheetId = 'Specify your Sheet ID (https://docs.google.com/spreadsheets/d/[id:1234ABCD1234]/edit#gid=0)';
    $scope.tooltip.fileDateFormat = 'For file delivery types, data file names must contain a date if being processed by file name - this field tells the system what format that date is in. For example, a file named "julydata_2025-01-02.csv" could be "February 1, 2025" or "January 2, 2025". Choose the option that matches the format for your file names.  If you opt to process your files instead by Last Modified Date, then the file name format does not have any impact on data processing because processing is based on the last modified time stamp of the file.';
    $scope.tooltip.email = 'If this is turned off, email notifications will be sent at the end of all fetches for success, failures, and warnings. Otherwise, email notifications will only be sent if there are errors or warnings during the fetch.';
    $scope.tooltip.skipFooterRows = 'Some file delivery platforms will automatically add rows at the bottom of the data file.  This allows you to indicate how many rows to skip so that your data can be automated.';
    $scope.tooltip.processSampleFile = 'This will allow us to immediately process the sample file that was uploaded for the initial assignment of this Smart Connector.';
    $scope.tooltip.scheduleFileDelivery = 'Enabling scheduled file delivery will allow our system to automatically update your data on a daily basis.';
    $scope.tooltip.fileSortBy = 'The order in which the system will ingest or re-ingest data. If you are unsure please set to "Date" (the default setting).';
    $scope.tooltip.sql_statement = 'Specify the query that will fetch the data that will be used for data processing.';
    $scope.tooltip.tapapi_domain = 'Specify the URL of the dashboard you want to connect to (i.e. myinstance.tapclicks.com)';
    $scope.tooltip.fetchHistory = 'By default, every day, data for the last 3 days is fetched from the source. If you need to fetch more or less than 3 days of data you can update the default daily fetch period here. For example, if you know that the data could be modified in the source files from the last 6 days, you should set the daily fetch period to 7.';
    $scope.tooltip.fetchDelay = 'Daily fetches usually start at 6am eastern time. If your data source data is available at another time, you can use this field to modify the processing time. For example, let\'s say your source data is available to be pulled at 9am - you can set the Daily Fetch Delay to at least 3 hours (180 minutes) to control when the daily data fetch occurs.';
    $scope.tooltip.geoConfiguration = 'Can\'t find your Geo field? Please ensure that the fields you intend to use with the Geo feature are configured with "Field Type" = Text.';
    $scope.tooltip.exposeParentView = 'These are data views that can be used to inherit fields to this data view.';
    $scope.tooltip.exposeParentFileColumn = 'This is used within the application to identify the data or metric imported from the parent level.';
    $scope.tooltip.exposeParentDelete = 'Any fields deleted from this configuration will be ignored when processing data files. The field will not be deleted from the parent level.';
    $scope.tooltip.webhook_email_status = 'Allows you to add data to the Smart Connector by sending an email.';
    $scope.tooltip.webhook_email = 'This is the email address to which your data needs to be emailed to for this Smart Connector to process data from.';
    $scope.tooltip.webhook_pattern_attached_files = 'This will filter any attached files that only the ones that match your pattern will be used for processing. If not indicated, all attachments will be used in processing.';
    $scope.tooltip.webhook_pattern_links_from_email = 'This will filter any links in the email so that only the ones that match your pattern will be used for processing. If not indicated, all data from links in the email will be used in processing.';
    $scope.tooltip.tapdirect_pagination_next_page_url_json_path = 'Separate the object by a dot "." and array by a pipe "|". Example : if the value of the next page URL is in "{response:{\'next_page\'}}", then the path would be "response.next_page".';
    $scope.tooltip.tapdirect_pagination_next_page_url_prefix = 'If the next page URL does not contain the complete URL, add it here.';
    $scope.tooltip.tapdirect_bearer_token = 'Standard Header Prefix is usually "Bearer ". So it begins with "Bearer" followed by a space. Ensure that you have that space after "Bearer".';
    $scope.tooltip.display_marketplace_settings = 'By default, all settings are displayed and can be edited. If you enable this module, you can make settings read only or hidden, which will make the form appear less complex to your clients when they install on their dashboard. This functinality is only available for TapDirect.';
    $scope.tooltip.is_date_field_required = 'Most datasets processed by Smart Connector contain dates (example: campaign performance over time), and dashboards are filtered to show only the data in the date range selected on the dashboard. If your dataset does not contain dates, for example a list of the products you sell, you can enable this feature. In that case, when showing this data within a dashboard, the most current data from the database will be shown in your widgets, regardless of the date range selected on screen.';
    $scope.tooltip.google_root_drive = 'If your file directory is in a Team Drive, you can select it here. If not, use the default value "My Drive".';
    $scope.tooltip.bq_service_account = 'Copy/paste the content of the Service Account key file from visiting this location: https://cloud.google.com/iam/docs/creating-managing-service-account-keys';
    $scope.tooltip.bq_dataset = 'Specify the name of the Google BigQuery dataset.';
    $scope.tooltip.bq_table = 'Your Google BigQuery table name.';
    $scope.tooltip.snowflake_warehouse = 'Your Snowflake warehouse name.';
    $scope.tooltip.snowflake_database = 'Your Snowflake database name.';
    $scope.tooltip.snowflake_schema = 'Your Snowflake schema name.';
    $scope.tooltip.snowflake_table = 'Your Snowflake table name.';
    $scope.tooltip.snowflake_username = 'Your Snowflake username.';
    $scope.tooltip.snowflake_password = 'Your Snowflake password.';
    $scope.tooltip.stellar_content_key = 'When enabled, this Smart Connector can be used as a TapOrders content key. Once in use, this Smart Connector will be locked until it is removed from all content key configurations.';
    $scope.tooltip.timezone_type = 'Select the timezone for your datetime fields. Note that this selection applies to all datetime fields and only datetime fields types.';
    $scope.tooltip.currency_type = 'Select the currency code for your currency fields. Note that this selection applies to all currency fields on all levels of your Smart Connector.';
    $scope.tooltip.currency_type_sublevel = 'This represents the currency code for your currency fields. This selection applies to all currency fields on all levels of your Smart Connector. Changes can only be made at the Top/Main Level of your Smart Connector.';
    $scope.tooltip.display_campaign_name_module = 'This field will be used throughout the platform to show a friendly name instead of the Unique Key where applicable.';
    $scope.tooltip.preprocess_select_function = 'Choose a pre-process function to manipulate the data before it\'s processed by the Smart Connector. If you want to write your own, select "Use custom function".';
    $scope.tooltip.preprocess_function_url = 'URL that hosts your custom pre-process function.';
    $scope.tooltip.preprocess_code_samples = 'Code samples you can use to build your own pre-process function.';
    $scope.tooltip.tapdirect_keep_variables_secure = 'Use variables instead of typing sensitive data like passwords and tokens to to keep your data secure.';
    $scope.tooltip.use_flat_model_data = 'This setting assumes that the data will be processed in a flat manner, with no child data views (not supported when this feature is enabled).  This can be very useful in making your data fetches faster.  Please note that if you create a Smart Connector with this setting, it cannot be changed later.  Instead, you’ll need to copy your Smart Connector and make any necessary changes against the new record.';

    $scope.placeholder = {};
    $scope.placeholder.drilldownView = 'Name of the sublevel (e.g. Campaign, Ad, Site, Keyword)';
    $scope.placeholder.fileDateFormat = 'Date format for date strings that are appended to names of data files.';
    $scope.placeholder.passwordNote = 'If credentials have already been added, and haven’t changed, you can leave this blank';
    $scope.placeholder.email = 'Email(s) for processing confirmation and service alerts. Multiple addresses may be entered separated by a comma.';
    $scope.placeholder.editToUnlock = 'Save your Smart Connector to unlock the "Smart Connector Sublevels" features';
    $scope.placeholder.dateField = 'Date field';
    $scope.placeholder.dateOrDatetimeField = 'Date/Datetime field';

    $scope.placeholderGeoLabel = [];
    $scope.placeholderGeoLabel['country'] = 'Country Code';
    $scope.placeholderGeoLabel['state'] = 'State Code';
    $scope.placeholderGeoLabel['county'] = 'County Code';
    $scope.placeholderGeoSelect = [];
    $scope.placeholderGeoSelect['country'] = 'Select Country Iso Code Column';
    $scope.placeholderGeoSelect['state'] = 'Select State Iso Code Column';
    $scope.placeholderGeoSelect['county'] = 'Select County Code Column';

    $scope.sc_modules = sc_modules;
    $scope.show_manual_upload = false;

    $scope.IMPORT_WIZARD = IMPORT_WIZARD;
    $scope.isVirtualColumnHeaders = [$scope.IMPORT_WIZARD.SC_VIRTUAL_MAPPING, $scope.IMPORT_WIZARD.SC_TAB_MAPPING];

    $scope.onBackPressed = function () {
        if ($scope.isSublevel) {
            // for nested sublevels (sublevels of sublevels)
            if (parents.length > 1) {
                let first_parent = parents[parents.length - 1];

                window.location.hash = '#/importwizard/detail/' + first_parent['id'] + '/sub/' + $scope.model.parent_id;
            } else {
                window.location.hash = '#/importwizard/detail/' + $scope.model.parent_id;
            }
        } else {
            window.location.hash = '#/importwizard';
        }
    };

    $scope.copyCodeSampleToClipboard = function(el) {
        $scope.preprocessFunctionsCodeSampleCopiedToClipboard = false;
        navigator.clipboard.writeText($(el).val());
        $scope.preprocessFunctionsCodeSampleCopiedToClipboard = true;
    };

    $scope.copyCurlSampleToClipboard = function() {
        $scope.preprocessFunctionsCurlSampleCopiedToClipboard = false;
        navigator.clipboard.writeText($('#curl-sample').val());
        $scope.preprocessFunctionsCurlSampleCopiedToClipboard = true;
    };

    // data types white list:
    $scope.dataTypes = [
        ColumnFormat.FORMAT_INTEGER,
        ColumnFormat.FORMAT_DECIMAL,
        ColumnFormat.FORMAT_PERCENT,
        ColumnFormat.FORMAT_CURRENCY,
        ColumnFormat.FORMAT_STRING,
        ColumnFormat.FORMAT_DATE,
        ColumnFormat.FORMAT_DATETIME,
        ColumnFormat.FORMAT_TIME,
        ColumnFormat.FORMAT_LINK,
        ColumnFormat.FORMAT_AUDIO,
        ColumnFormat.FORMAT_THUMBNAIL,
        ColumnFormat.FORMAT_CLICK_TO_VIEW_LINK,
        ColumnFormat.FORMAT_PHONE_NUMBER,
        IMPORT_WIZARD.EXTRA_FORMAT_INTEGER_BIG,
        IMPORT_WIZARD.EXTRA_FORMAT_STRING_ID
    ];

    // parent fields type that can be exposed - white list:
    $scope.parentDataTypes = [
        ColumnFormat.FORMAT_STRING,
        ColumnFormat.FORMAT_LINK,
        ColumnFormat.FORMAT_AUDIO,
        ColumnFormat.FORMAT_THUMBNAIL,
        ColumnFormat.FORMAT_DATE,
        ColumnFormat.FORMAT_DATETIME,
        ColumnFormat.FORMAT_CLICK_TO_VIEW_LINK,
        ColumnFormat.FORMAT_PHONE_NUMBER,
        IMPORT_WIZARD.EXTRA_FORMAT_STRING_ID
    ];

    $scope.IMPORT_CONN_TYPE = IMPORT_CONN_TYPE;
    $scope.IMPORT_AUTH_TYPE = IMPORT_AUTH_TYPE;

    $scope.entity = {
        nameKey: 'name',
        idKey: 'id',
        pageTitle: 'New Smart Connector',
        action: 'importwizard',
        redrawServiceNavOnSave: true
    };

    // If we are loading for the first time a new import wizard which has just been imported :
    $scope.import = $stateParams.import;

    // All generic info, like connected user email
    $scope.genericInfo = genericInfo[0];

    $scope.is_smart_connector = true;

    $scope.model = {};
    $scope.model.active = true; // default is active (service level)
    $scope.model.is_sublevel_active = true; // default is sublevel active (private integration level)
    $scope.model.is_geo_data_active = false;
    $scope.is_module_sublevel_active = false;
    $scope.is_from_marketplace = false;
    $scope.model.color = $stateParams.serviceColor ? $stateParams.serviceColor : '#3498db'; //Default color
    $scope.model.custom_icon = $stateParams.serviceIcon ? 'serviceicon-' + $stateParams.serviceIcon : null; //If no icon selected, custom icon is null
    $scope.model.mapping_field = null;
    $scope.model.mapping_field_label = null;
    $scope.model.date_field = '';
    $scope.model.campaign_descriptor = (isSublevel) ? 'Sublevel view' : 'Campaign';
    $scope.model.skip_footer_rows = 0;
    $scope.model.process_sample_file = '0';
    $scope.model.file_sort_by = 'last_modified';
    $scope.model.email = ($scope.genericInfo.active_email_user) ? $scope.genericInfo.active_email_user : null;
    $scope.has_large_number_enabled = $scope.genericInfo.has_large_number_enabled ?? false;
    $scope.has_content_key_enabled = $scope.genericInfo.has_content_key_enabled ?? false;
    $scope.model.has_content_key_in_use = false;
    $scope.model.email_on_error_only = true;
    $scope.model.pi_temp_id = null;
    $scope.model.check_code = null;
    $scope.model.sql_statement = '';
    $scope.model.mapping_module_sql = '';
    $scope.mapping_module_sql_auto_suggested = true;
    $scope.model.soql_dateformat = 'datetime';
    $scope.model.fetch_history = 3;
    $scope.model.fetch_delay = 0;
    $scope.model.auth_type = null;
    $scope.auth_type_select2 = {};
    $scope.auth_type_mapping = [];
    $scope.model.conn_type = null;
    $scope.model.parent_mapping = [];
    $scope.model.mapping_geo_fields = [];
    $scope.model.mapping_module_active = false;
    $scope.model.mapping_module_path = '';
    $scope.model.is_date_field_required_reverse_value = false;
    $scope.model.is_date_field_required = true;
    $scope.model.margin_field = '';
    $scope.model.map_single_client = false;
    $scope.model.advanced_assignment = false;
    $scope.model.display_campaign_name_module = false;
    $scope.model.data_file_path = '';
    $scope.model.currency_type = (isSublevel) ? parents[0].currency_type : 0;
    $scope.currency_iso = (isSublevel) ? parents[0].currency_iso : 'USD';
    $scope.model.currency_iso = $scope.currency_iso;
    $scope.model.currency_field = (isSublevel) ? parents[0].currency_field : '';

    // For new sublevel, if parent timezone is set to manual timezone, let set that value by default
    $scope.model.timezone_type = (isSublevel && parents[0].timezone_type_parent == $scope.IMPORT_WIZARD.TIMEZONE_TYPE_CODE) ? $scope.IMPORT_WIZARD.TIMEZONE_TYPE_CODE_SELECTED : null;
    $scope.timezone_code = (isSublevel && parents[0].timezone_type_parent == $scope.IMPORT_WIZARD.TIMEZONE_TYPE_CODE) ? parents[0].timezone_code_parent : null;
    $scope.model.timezone_code = $scope.timezone_code;

    $scope.model.timezone_field = '';
    $scope.model.use_flat_model = false;
    $scope.model.last_data_row_count = 0;
    $scope.oversized_limit = '10,000,000';
    $scope.oversized_reached = false;

    // Webhook email :
    $scope.model.webhook_email = '';
    $scope.model.webhook_email_status = false;
    $scope.model.webhook_pattern_case_sensitive = false;
    $scope.model.webhook_pattern = '';
    $scope.model.webhook_pattern_advanced_regex = false;
    $scope.model.webhook_from_attached = true;
    $scope.model.webhook_from_links = false;
    $scope.model.webhook_pattern_links = '';
    $scope.model.webhook_pattern_advanced_regex_links = false;
    $scope.model.webhook_pattern_case_sensitive_links = false;
    $scope.show_list_regex = false;
    $scope.show_list_regex_links = false;
    $scope.show_date_format_characters = false;
    $scope.show_valid_encodings = false;
    $scope.show_code_samples = false;
    $scope.webhook_email_loading = false;
    $scope.webhook_email_has_been_changed = false;

    $scope.model.origin_of_data = null;
    $scope.model.origin_of_data_third_party = '';

    $scope.model.campaign_descriptor_disable_pluralized = false;
    $scope.model.campaign_descriptor_plural = '';

    $scope.model.brightedgeql = {
        url: 'query',
        raw: 'query={ \n  "dataset":"keyword",\n  "dimension":[\n    "keyword",\n    "time"\n  ], \n  "measures":[\n    "rank"\n  ], \n  "dimensionOptions":{\n    "time":"weekly"\n  }, \n  "filter":[\n    ["time","ge","%STARTING_DATE%"]\n  ],\n"count": %COUNT%,\n"offset": %OFFSET%\n}',
    };

    // TapDirect
    $scope.tapdirect_init = {
        authorization: 'none',
        authorization_type: {
            none: '',
            oauth2: {
                header: 'Bearer ',
                grant_type: 'authorization_code',
                callback_url: 'https://im.tapclicks.com/oauthredirector.php',
                auth_url: '',
                access_token_url: '',
                client_id: '',
                client_secret: '',
                scope: '',
                state: 1,
                use_pkce: 0,
                client_authentication: 'basic'
            },
            basic: {
                username: '',
                password: ''
            },
            bearer_token: {
                token: ''
            }
        },
        method: 'GET',
        url: '',
        headers: [],
        params: [],
        body: 'none',
        body_type: {
            none: '',
            form_data: [],
            x_www_form_urlencoded: [],
            raw: ''
        },
        environment_variables: [],
        environment_variables_global: [],
        environment_variables_house: [
            {key: 'starting_date', value: 'yyyy-mm-dd'},
            {key: 'ending_date', value: 'yyyy-mm-dd'},
            {key: 'starting_offset', value: '0'},
            {key: 'increment_offset_by', value: '1'},
        ],
        sample_load: 'dynamic',
        fetch_sample_days: 3,
        pagination: 'none',
        pagination_type: {
            none: '',
            incremental: {},
            nextpageurl: {
                prefix: '',
                json_path: ''
            }
        },
        quick_mapping: [],
        extra_calls: []
    };
    $scope.tapdirect_extra_calls_init = {
        position: 0,
        show: true,
        method: 'GET',
        url: '',
        headers: [],
        params: [],
        body: 'none',
        body_type: {
            none: '',
            form_data: [],
            x_www_form_urlencoded: [],
            raw: ''
        },
        pagination: 'none',
        pagination_type: {
            none: '',
            incremental: {},
            nextpageurl: {
                prefix: '',
                json_path: ''
            }
        },
        environment_variables_house: [],
        mapping_path: '',
        mapping_path_label: '',
        last_available_paths: []
    };
    $scope.model.tapdirect = $scope.tapdirect_init;
    $scope.tapdirect_quick_mapping_init = $scope.tapdirect_extra_calls_init;

    /*{key: 'mapping_id', value: ''},*/
    $scope.tapdirect_quick_mapping_init.environment_variables_house = [
        {key: 'mapping_starting_offset', value: '0'},
        {key: 'mapping_increment_offset_by', value: '1'}
    ];
    $scope.model.tapdirect.quick_mapping = [];
    $scope.model.tapdirect.quick_mapping[0] = $scope.tapdirect_quick_mapping_init;

    $scope.model.fields_config = {};

    $scope.model.auth_system = {
        storage_account: '',
        container: '',
        protocol: 'TCPIP',
        host: '',
        server: '',
        database: '',
        character_set: 'utf8',
        port: '',
        username: '',
        password: '',
        // Amazon S3
        access_key_id: '',
        secret_access_key: '',
        bucket: '',
        // Athena
        catalog: '',
        output_location: '',
        sublevel: false,
        // Snowflake
        account: '',
        // Google BigQuery
        project: '',
        // Google BigQuery (Live)
        service_account: '',
        dataset: '',
        table: '',
        // Snowflake (Live)
        warehouse: '',
        schema: '',
    };

    $scope.model.tapapi = {
        category: '',
        data_source_id: '',
        data_channel_id: '',
        data_view_id: '',
        data_name: '',
        data_view_name: '',
        unique_keys: ''
    };

    // default metadata for a new Live Connector
    $scope.model.liveconnector = {
        dataviews: {},
        attributes: {},
        metrics: {},
        selected_dataview: {
            'label': "Campaigns",
            'field': "cgn",
        },
        selected_attributes: ['log_date'],
        selected_metrics: [],
        parameters: [],
    };

    // Tab Parsing
    $scope.model.use_tab_parsing = false;
    $scope.model.tab_parsing_config = {
        loading_type: '',
        use_tab_name_for_mapping: null,
        exact_match: true,
        is_case_sensitive: false,
        pattern: '',
        use_regex: false
    };

    /**
     * Pre-process functions
     */
    $scope.model.use_preprocess_functions = false;
    $scope.model.preprocess_functions_library = [];
    $scope.model.preprocess_functions = [DEFAULT_PREPROCESS_FUNCTION];

    $scope.preprocessFunctionsUrlValidating = [false];
    $scope.preprocessFunctionsUrlValidated = [false];

    $scope.preprocessFunctionsCodeSampleCopiedToClipboard = false;
    $scope.preprocessFunctionsCurlSampleCopiedToClipboard = false;

    // Json config
    $scope.model.json_file_config_status = false;
    $scope.model.json_file_config_global_flatten = false;
    $scope.model.json_file_config_data_path = '';
    $scope.model.json_file_config_data_paths_exclude = '';
    $scope.model.json_file_config_flatten_paths = '';
    $scope.model.json_file_config_custom_field_path = '';
    $scope.model.json_file_config_custom_field_key = '';
    $scope.model.json_file_config_custom_field_value = '';

    $scope.model.google_drive_share_id = '';
    $scope.model.google_drive_share_last_response = [];

    $scope.original_auth_system = [];
    $scope.original_auth_type = null;

    $scope.testConnectionValidated = false;
    $scope.isNewSC = true;
    $scope.isEditing = false;

    $scope.loadLiveConnectorLoading = false;
    $scope.loadDataFieldsLoading = false;
    $scope.liveConnectorMetadataLoaded = false;
    $scope.liveConnectorDataFieldsLoaded = true;

    $scope.isCloning = isCloning;
    $scope.integrations_id = $stateParams.id;
    $scope.model.parent_id = $stateParams.parent_id;
    $scope.model.fields_in_use_from_childs = [];
    $scope.model.margin_can_be_deleted = true;

    $scope.onColorChange = function (newValue) {
        $scope.model.color = newValue;
    };

    $scope.authTypes = authTypes;

    $scope.isTapAPIGroupableFields = [];
    $scope.hideTapAPI = false;
    $scope.user_info = {
        id: '',
        email: ''
    };
    $scope.showLastUserConnected = false;
    $scope.authKeys = [];

    $scope.getTapapiUserLoading = false;
    $scope.data_source_loading = false;
    $scope.manage_connection_loading = false;
    $scope.data_fields_loading = false;
    $scope.refresh_google_tab = false;

    $scope.google_sheet_default = {
        id: '',
        tabs: [],
        sheet_id: null,
        title: '',
        type: '',
        use_tab_name_for_mapping: null,
        exact_match: true,
        is_case_sensitive: false,
        pattern: '',
        use_regex: false

    };

    $scope.model.use_stellar = false;
    $scope.model.stellar_content_key_config = {
        id: '',
        value: '',
        mapping: ''
    };

    $scope.model.google_sheet = $scope.google_sheet_default;
    $scope.google_sheet_activated = false;
    $scope.is_google_file_picker_in_use = false;
    $scope.test_tapdirect_connection_loading = false;
    $scope.formCallback = formCallback;

    // data_type supported for uniqueness key / stellar
    $scope.supported_data_types = [
        ColumnFormat.FORMAT_STRING,
        ColumnFormat.FORMAT_LINK,
        ColumnFormat.FORMAT_CLICK_TO_VIEW_LINK,
        ColumnFormat.FORMAT_PHONE_NUMBER,
        $scope.IMPORT_WIZARD.EXTRA_FORMAT_STRING_ID
    ];

    function formCallback(data, model) {
        if (!isSublevel) {
            AppFactory.refreshServices();
        }

        PubSub.emit("SegmentEvents", {
            event: "ImportWizardSaveEvent",
            payload: {
                model: model.plain ? model.plain() : $scope.model,
                isNew: !this.isEditing,
                isCloning: this.isCloning
            }
        });

        if (data.data.id) {
            this.isEditing = true;
            this.integrations_id = data.data.id;
            this.model.service_id = data.data.service_id;
            // If it was previously a clone, set it to false
            this.isCloning = false;
        }
    }
    $scope.parents = parents;
    $scope.parent_list_column_alias = [];

    angular.forEach(authTypes, function(value, key) {
        $scope.authKeys[value.id] = key;
        // If sublevel is available for at least one auth, it mean that the module sublevel is activated
        if (value.is_sublevel_available) {
            $scope.is_module_sublevel_active = true;
        }
    });

    $scope.parentFields = [];

    $scope.displayError = function(json) {
        if (json.data) {
            if (json.data[0]) {
                UIFactory.notify.showError(json.data[0]);
            } else if (json.data.data[0]) {
                UIFactory.notify.showError(json.data.data[0]);
            } else {
                UIFactory.notify.showError('API error [1]');
            }
        } else {
            UIFactory.notify.showError('API error [2]');
        }
    };

    $scope.updateUploadSampleFileURL = function() {
        let uploadUrl = '/server/api/importwizard/import_sample_data?is_date_field_required='+$scope.model.is_date_field_required+'&';
        if ($scope.model.json_file_config_status) {
            uploadUrl += 'json_file_config_data_path='+encodeURIComponent($scope.model.json_file_config_data_path)+'&';
            uploadUrl += 'json_file_config_flatten_paths='+encodeURIComponent($scope.model.json_file_config_flatten_paths)+'&';
            uploadUrl += 'json_file_config_data_paths_exclude='+encodeURIComponent($scope.model.json_file_config_data_paths_exclude)+'&';
            uploadUrl += 'json_file_config_custom_field_path='+encodeURIComponent($scope.model.json_file_config_custom_field_path)+'&';
            uploadUrl += 'json_file_config_custom_field_key='+encodeURIComponent($scope.model.json_file_config_custom_field_key)+'&';
            uploadUrl += 'json_file_config_custom_field_value='+encodeURIComponent($scope.model.json_file_config_custom_field_value)+'&';
        }
        uploadUrl += 'json_file_config_global_flatten='+$scope.model.json_file_config_global_flatten+'&';
        if ($scope.model.map_single_client) {
            uploadUrl += 'map_single_client='+$scope.model.map_single_client+'&';
        }
        if ($scope.model.use_tab_parsing) {
            uploadUrl += 'use_tab_parsing='+$scope.model.use_tab_parsing+'&tab_parsing_config='+encodeURIComponent(JSON.stringify($scope.model.tab_parsing_config))+'&';
        }
        if ($scope.model.use_preprocess_functions) {
            uploadUrl += 'use_preprocess_functions='+$scope.model.use_preprocess_functions+'&preprocess_functions='+encodeURIComponent(JSON.stringify($scope.model.preprocess_functions))+'&';
        }
        if ($scope.model.use_stellar) {
            uploadUrl += 'use_stellar='+$scope.model.use_stellar;
        }
        $('#integration_sample_data_file').fileinput('refresh', {uploadUrl: uploadUrl});
    };

    $scope.getHashParam = function(param) {
        let hash = window.location.toString().split(/[#?]/);
        if (!hash[2]) {
            return '';
        }
        hash = hash[2];
        let parts = hash.split("&"),
            i;
        for (i = 0; i < parts.length; i++) {
            let new_parts = parts[i];
            if (new_parts.split("=")[0] === param) {
                return new_parts.split("=")[1];
            }
        }
        return '';
    };

    window.addEventListener("hashchange", function() {
        // If the file picker has not been opened through this active window, we don't have to listen to the hash tag
        if (!$scope.is_google_file_picker_in_use) {
            return;
        }
        let google_sheet_id = $scope.getHashParam('google-sheet-id');

        // If we have a Google Sheets ID in the hash, move it in the active form
        if (!_.isEmpty(google_sheet_id)) {
            // Remove the google sheets id from the hash
            let previous_location = window.location.href,
                updated_location = previous_location.replace('google-sheet-id='+google_sheet_id, '');

            $scope.is_google_file_picker_in_use = false;
            window.location = updated_location;

            // Reinitiate possible previous errors:
            $scope.googleSheethasError = false;
            $scope.GoogleSheetFailMessage = '';

            if ($scope.model.google_sheet.id !== google_sheet_id) {
                $scope.model.google_sheet.id = google_sheet_id;
                AppFactory.refreshServices();
                $scope.getGoogleSheetTabs(true);
            } else {
                $scope.getGoogleSheetRefresh(false);
            }
            return;
        }

        let google_folder_id = $scope.getHashParam('google-folder-id');

        // If we have a Google Sheets ID in the hash, move it in the active form
        if (!_.isEmpty(google_folder_id)) {
            // Remove the google sheets id from the hash
            let previous_location = window.location.href,
                updated_location = previous_location.replace('google-folder-id='+google_folder_id, '');

            $scope.is_google_file_picker_in_use = false;
            window.location = updated_location;

            // Reinitiate possible previous errors:
            $scope.googleFolderhasError = false;
            $scope.GoogleFolderFailMessage = '';

            if ($scope.model.data_file_path !== google_folder_id) {
                $scope.model.data_file_path = google_folder_id;
                AppFactory.refreshServices();
                $scope.loadGoogleFolderName();
            }
        }
    });
    $scope.refresh_google_title = false;
    $scope.getGoogleSheetRefresh = function(parseId) {
        $.core.main.hideAllNotifies();
        let new_sheet_id = false;
        if (!$scope.model.google_sheet.id) {
            UIFactory.notify.showError('Specify the Google Sheets ID first.');
            return;
        }

        $scope.refresh_google_title = true;
        $http.post(
            '/server/api/importwizard/google_sheet/get_title',
            {
                pi_temp_id: $stateParams.pi_temp_id,
                check_code: $stateParams.check_code,
                service_id: $scope.model.service_id,
                google_sheet_id: $scope.model.google_sheet.id
            }
        ).then(function successCallback(json) {
            $scope.refresh_google_title = false;
            $scope.model.google_sheet.title = json.data.data.title;
            if (!$scope.google_sheet_activated && ($scope.model.google_sheet.sheet_id == null || new_sheet_id)) {
                $scope.getGoogleSheetTabs(false, true);
            }
            $scope.google_sheet_activated = true;
        }, function errorCallback(json) {
            $scope.refresh_google_title = false;
            $scope.displayError(json);
        });
    }

    $scope.folder_name = '';
    $scope.loadGoogleFolderName = function() {
        $scope.folder_name = '';
        if ($scope.model.auth_type !== IMPORT_AUTH_TYPE.GOOGLE_DRIVE || !$scope.testConnectionValidated) {
            return;
        }

        if (!$scope.model.data_file_path || $scope.model.data_file_path == '' || $scope.model.data_file_path.match("/")) {
            return;
        }
        $http.post(
            '/server/api/importwizard/google_drive/get_folder_name',
            {
                pi_temp_id: $stateParams.pi_temp_id,
                check_code: $stateParams.check_code,
                service_id: $scope.model.service_id,
                data_file_path: $scope.model.data_file_path
            }
        ).then(function successCallback(json) {
            if (json.data && json.data.data && json.data.data.folder_path) {
                $scope.folder_name = json.data.data.folder_path;
            }
        }, function errorCallback(json) {
        });
    }

    $scope.getGoogleSheetTabs = function(full_update, sample_update) {
        $.core.main.hideAllNotifies();
        $scope.refresh_google_tab = true;
        if (full_update) {
            $scope.refresh_google_title = true;
        }

        $http.post(
            '/server/api/importwizard/google_sheet/get_tabs/' + $scope.model.google_sheet.id,
            {
                pi_temp_id: $stateParams.pi_temp_id,
                check_code: $stateParams.check_code,
                service_id: $scope.model.service_id
            }
        ).then(function successCallback(json) {
            $scope.refresh_google_tab = false;
            if (full_update) {
                $scope.refresh_google_title = false;
                $scope.model.google_sheet.title = json.data.data.title;
            }

            if ($scope.model.google_sheet.sheet_id == null) {
                $scope.model.google_sheet.sheet_id = parseInt(json.data.data.tabs[0].sheet_id);
            }

            $scope.model.google_sheet.tabs = json.data.data.tabs;

            if (!$scope.model.has_data && $scope.model.google_sheet.tabs.length === 1 && (full_update || sample_update)) {
                $scope.loadGoogleSheetSample();
            }
        }, function errorCallback(json) {
            $scope.refresh_google_tab = false;
            if (full_update) {
                $scope.refresh_google_title = false;
            }

            $scope.displayError(json);
        });
    };

    $scope.load_goggle_sheet_access_token = false;
    $scope.getGoogleSheetIdFromFilePicker = function() {
        if (!$scope.testConnectionValidated) {
            UIFactory.notify.showError('Please authenticate your connection first.');
            return;
        }

        $scope.load_goggle_sheet_access_token = true;
        $http.post(
            '/server/api/importwizard/google_sheet/get_access_token',
            {
                pi_temp_id: $stateParams.pi_temp_id,
                check_code: $stateParams.check_code,
                service_id: $scope.model.service_id
            }
        ).then(function successCallback(json) {
            $scope.load_goggle_sheet_access_token = false;
            $scope.google_sheet_activated = true;
            $scope.is_google_file_picker_in_use = true;
            let new_window = window.open('https://im.tapclicks.com/file-picker.php?access_token='+encodeURIComponent(json.data.data.access_token)+'&from='+encodeURIComponent(window.location.href), 'filepicker', 'width=700,height=500,left=200,top=100,scrollbars=yes,status=yes');
            if (!new_window || new_window.closed || typeof new_window.closed=='undefined') {
                UIFactory.notify.showWarning('Please ensure that your popup blocker is disabled.');
            }
        }, function errorCallback(json) {
            $scope.load_goggle_sheet_access_token = false;
            $scope.displayError(json);
        });
    };

    $scope.load_goggle_drive_access_token = false;
    $scope.getGoogleDriveIdFromFilePicker = function() {
        if (!$scope.testConnectionValidated) {
            UIFactory.notify.showError('Please authenticate your connection first.');
            return;
        }

        $scope.load_goggle_drive_access_token = true;
        $http.post(
            '/server/api/importwizard/google_sheet/get_access_token',
            {
                pi_temp_id: $stateParams.pi_temp_id,
                check_code: $stateParams.check_code,
                service_id: $scope.model.service_id
            }
        ).then(function successCallback(json) {
            $scope.load_goggle_drive_access_token = false;
            $scope.google_drive_activated = true;
            $scope.is_google_file_picker_in_use = true;
            let new_window = window.open('https://im.tapclicks.com/file-picker.php?access_token='+encodeURIComponent(json.data.data.access_token)+'&is_folder_picker=1&from='+encodeURIComponent(window.location.href), 'filepicker', 'width=700,height=500,left=200,top=100,scrollbars=yes,status=yes');
            if (!new_window || new_window.closed || typeof new_window.closed=='undefined') {
                UIFactory.notify.showWarning('Please ensure that your popup blocker is disabled.');
            }

        }, function errorCallback(json) {
            $scope.load_goggle_drive_access_token = false;
            $scope.displayError(json);
        });


    };

    $scope.getGoogleSheetIdFromParent = function() {
        $scope.google_sheet_activated = true;
        let google_sheet = JSON.parse($scope.parents[0].google_sheet);
        $scope.model.google_sheet.id = google_sheet.id;
        $scope.getGoogleSheetTabs(true);
    };

    $scope.updateActiveGoogleSheetTab = function(sheet_id) {
        $scope.model.google_sheet.sheet_id = sheet_id;
    };

    $scope.load_google_sheet_sample = false;
    $scope.loadGoogleSheetSample = function() {
        $scope.model.file_sort_by = 'last_modified';
        $scope.load_google_sheet_sample = true;

        $scope.$evalAsync(function () {
            $scope.model.hasErrorData = false
        });

        $.post(
            '/server/api/importwizard/google_sheet/load_sample_data/'+$scope.model.google_sheet.id+'/'+$scope.model.google_sheet.sheet_id,
            {
                pi_temp_id: $scope.model.pi_temp_id,
                check_code: $scope.model.check_code,
                service_id: $scope.model.service_id,
                is_date_field_required: $scope.model.is_date_field_required,
                use_stellar: $scope.model.use_stellar,
                map_single_client: $scope.model.map_single_client,
                google_sheet: $scope.model.google_sheet
            }
        ).then(function successCallback(json) {
            $scope.load_google_sheet_sample = false;
            if (json.error) {
                $scope.errorSampleDataCallback(json.data);
            } else {
                $scope.uploadSampleDataCallback(json.data);
            }
        }, function errorCallback(json) {
            $scope.load_google_sheet_sample = false;
            if (json.error) {
                $scope.errorCallback(json);
            } else {
                UIFactory.notify.showError('API error [3]');
            }
        });
    };

    let isEditing = (!_.isEmpty($stateParams.id) && !isCloning) && $stateParams.id !== 'new';

    if (isEditing) {
        $scope.isNewSC = false;
        ImportWizardSublistListController($scope, subleveldata, sublevelmetadata);
        $scope.mapping_module_sql_auto_suggested = false;
    } else {
        $scope.dtOptionsSublevel = {};
        $scope.dtOptionsSublevel.itemName = 'smart connector sublevel';
    }

    $scope.reloadImportWizardSublistListController = function() {
        if ($scope.isNewSC) {
            return;
        }
        ImportWizardFactory.services.getList({all: true, summary: true, active: true, parent_id: $stateParams.id, validate_smart_connector: true}).then(function(response) {
            subleveldata = response;
            ImportWizardSublistListController($scope, subleveldata, sublevelmetadata, $timeout, UIFactory);
        });
    }

    if (isSublevel) {
        $scope.entity.nameKey = 'campaign_descriptor';
        $scope.model.auth_system.sublevel = true;

        $scope.getParentInfo = function(parent_id) {
            $scope.data_source_loading = true;
            $http.get(
                '/server/api/importwizard/get-parent-info/' + parent_id
            ).then(function successCallback(json) {
                $scope.data_source_loading = false;
                let info = json.data.data;
                $scope.parentFields = info.fields;
                $scope.model.auth_type = info.auth_type;
                $scope.auth_type_select2 = $scope.auth_type_mapping[$scope.model.auth_type];
                $scope.model.conn_type = $scope.authTypes[$scope.authKeys[$scope.model.auth_type]].type;
                $scope.model.oauth_project = info.oauth_project;
                $scope.model.oauth_bucket = info.oauth_bucket;
                $scope.model.service_id = info.service_id;
                $scope.model.map_single_client = info.map_single_client;

                if ($scope.model.conn_type === IMPORT_CONN_TYPE.MANUAL) {
                    $rootScope.redirector.link = '#/importwizard/manual-upload';
                    $rootScope.redirector.param.key = 'id';
                }

                if ($scope.model.auth_type === IMPORT_AUTH_TYPE.EMAIL) {
                    $scope.model.webhook_email_status = true;
                    if (!$scope.model.webhook_email) {
                        $scope.loadNewWebhookEmail();
                    }
                }

                $scope.entity.parentTitle = info.name;

                if (isEditing) {
                    $scope.entity.pageTitle = info.name + ': ' + $scope.model.campaign_descriptor;
                    $scope.updateParentFieldsUnique();
                } else {
                    $scope.entity.pageTitle = info.name + ': New sublevel view';
                }

                // Breadcrumb :
                if (info.breadcrumb) {
                    let breadcrumb = [];
                    Object.keys(info.breadcrumb).sort().forEach(function(key) {
                        let breadcrumbLink;
                        if (info.breadcrumb[key].parent_id) {
                            breadcrumbLink = '#/importwizard/detail/'+info.breadcrumb[key].parent_id+'/sub/'+info.breadcrumb[key].id;
                        } else {
                            breadcrumbLink = '#/importwizard/detail/'+info.breadcrumb[key].id;
                        }

                        info.breadcrumb[key].link = '<a href="'+breadcrumbLink+'">'+info.breadcrumb[key].view+'</a>';
                        breadcrumb[key] = info.breadcrumb[key];
                    });

                    angular.forEach(breadcrumb, function(info) {
                        $('#importwizard-view-breadcrumb ol.breadcrumb').prepend('<li>' + info.link + '</li>');
                    });
                }

                $scope.validateConnection();

                if ($scope.model.map_single_client) {
                    $scope.updateSingleClient();
                }
            }, function errorCallback(json) {
                $scope.data_source_loading = false;
                if (json.data) {
                    UIFactory.notify.showError(json.data[0]);
                } else {
                    UIFactory.notify.showError('API error [4]');
                }
            });
        };

        $scope.getParentInfo($stateParams.parent_id);

        $scope.changeParentName = function(index) {
            // Get the correct view
            angular.forEach($scope.parents, function(parent_data, parent_key) {
                if (parent_data.id === $scope.model.parent_mapping[index].private_integration_id) {
                    // Get the correct field
                    angular.forEach(parent_data.fields, function (field, key) {
                        // Return the name of the corresponding column_alias
                        if (field.column_alias === $scope.model.parent_mapping[index].column_alias) {
                            $scope.model.parent_mapping[index].name = field.name;
                            return;
                        }
                    });
                }
            });
        };

        $scope.changeParentView = function(index, isNewChange) {
            // Return the correct list of fields for the view selected
            angular.forEach($scope.parents, function(parent_data, parent_key) {
                let private_integration_id = parseInt(parent_data.id);
                if (private_integration_id === $scope.model.parent_mapping[index].private_integration_id) {
                    $scope.parent_list_column_alias[private_integration_id] = _.filter(parent_data.fields, function(field) {
                        return !($scope.parentDataTypes.indexOf(field.data_type) < 0) &&
                            !field.is_date_field &&
                            !$scope.isVirtualColumnHeaders.includes(field.heading);
                    });
                }
            });
            // Select first values as default value :
            if (isNewChange) {
                let selected_private_integration_id = $scope.model.parent_mapping[index].private_integration_id;
                $scope.model.parent_mapping[index].column_alias = $scope.parent_list_column_alias[selected_private_integration_id][0].column_alias;
                $scope.changeParentName(index);
            }
        };
    }

    if (isEditing || isCloning) {
        $scope.$root.contentLoaded = false;
        $scope.$root.contentLoaded = true;
        $scope.isEditing = true;

        if (isEditing) {
            $scope.entity.action = 'importwizard/' + $stateParams.id;
        }

        $.extend($scope.model, service);
        if (typeof $scope.model.fields_config != 'object') {
            $scope.model.fields_config = {};
        }
        // Prepare a warning message to display for oversized datatable
        if (($scope.model.last_data_row_count > 10000000 && !$scope.genericInfo.is_snowflake_enabled) || ($scope.model.last_data_row_count > 50000000 && $scope.genericInfo.is_snowflake_enabled)) {
            let oversize_limit;
            if ($scope.genericInfo.is_snowflake_enabled) {
                $scope.oversized_limit = '50,000,000';
            }
            $scope.oversized_reached = true;
        }

        // Select a default value for currency_iso, if it is empty:
        if (!$scope.isSublevel && !$scope.model.currency_iso) {
            $scope.model.currency_iso = 'USD';
        }

        // If we are editing from a sublevel, get parent data to set related currency setting
        if ($scope.isSublevel) {
            $scope.model.currency_type = parents[0].currency_type;
            $scope.currency_iso = parents[0].currency_iso;
            $scope.model.currency_iso = $scope.currency_iso;
            $scope.model.currency_field = parents[0].currency_field;
        }
        $scope.model.currency_type = parseInt($scope.model.currency_type);

        // Set currency type for select2 object
        $scope.currency_iso = $scope.model.currency_iso;

        // Select a default value for timezone_code, if it is empty:
        if (!$scope.isSublevel && !$scope.model.timezone_code) {
            $scope.model.timezone_code = '';
        }

        // Set timezone type for select2 object
        $scope.timezone_code = $scope.model.timezone_code;

        // Set correct value for is_date_field_required_reverse_value
        $scope.model.is_date_field_required_reverse_value = !$scope.model.is_date_field_required;
        if (!$scope.model.is_date_field_required) {
            $scope.model.date_field = $scope.IMPORT_WIZARD.VIRTUAL_SC_NODATE;
        }

        $scope.margin_field_first_version = $scope.model.margin_field;

        // Let's re-initialize tapdirect, in case the user wants to switch from non-tapdirect DT to tapdirect
        if ($scope.model.auth_type !== IMPORT_AUTH_TYPE.TAPDIRECT) {
            $scope.model.tapdirect = $scope.tapdirect_init;
        }

        $scope.loadGoogleFolderName();

        if ($scope.model.tapdirect.quick_mapping === undefined || $scope.model.tapdirect.quick_mapping[0] === undefined) {
            $scope.model.tapdirect.quick_mapping = [];
            $scope.model.tapdirect.quick_mapping[0] = $scope.tapdirect_quick_mapping_init;
        }

        if ($scope.model.has_child) {
            $scope.is_module_sublevel_active = true;
        }

        if (_.isEmpty($scope.model.preprocess_functions)) {
            $scope.model.preprocess_functions = [DEFAULT_PREPROCESS_FUNCTION];
        }

        // Smart Connector Marketplace:
        if (!_.isEmpty($scope.model.marketplace_service_id)) {
            $scope.is_from_marketplace = true;
        }

        if (!isSublevel) {
            $scope.entity.pageTitle = ((isCloning) ? 'Clone ' : 'Edit ') + $scope.model[$scope.entity.nameKey];
        } else {
            angular.forEach($scope.model.parent_mapping, function (field, index) {
                $scope.changeParentView(index);
            });
        }

        if (isCloning) {
            $scope.model.name += ' (clone)';
            $scope.model.has_data = false;
            $scope.model.has_mapping = false;
        }

        // model.date_field, and model.unique_field are stored at the model level and must therefore be extracted from
        // the data fields which holds the condition for which data field is a name_field or date_field respectively
        angular.forEach($scope.model.fields, function(field) {
            if (field['is_mapping_field']) {
                $scope.model.mapping_field = field['heading'];
            } else if (field['is_mapping_field_label']) {
                $scope.model.mapping_field_label = field['heading'];
            }

            if (field['heading'] === $scope.model.currency_field) {
                field['is_currency_field'] = true;
            } else if (field['heading'] === $scope.model.timezone_field) {
                field['is_timezone_field'] = true;
            }

            if (field['is_date_field']) {
                $scope.model.date_field = field['heading'];
            }

            // date fields can't be metrics
            if (field['data_type'] === ColumnFormat.FORMAT_DATE || field['data_type'] === ColumnFormat.FORMAT_DATETIME) {
                field['is_metric'] = false;
            }

            // metric fields can have their type edited even after data has been parsed
            field['is_editable'] = field['is_metric'];

            // Check if the field is used in a sublevel
            field['is_in_use_from_child'] = $scope.model.fields_in_use_from_childs.indexOf(field['column_alias']) !== -1;
        });

        $scope.original_auth_system = [];
        $scope.original_auth_type = $scope.model.auth_type;
        $scope.model.conn_type = $scope.authTypes[$scope.authKeys[$scope.model.auth_type]].type;

        // TapAPI
        if (!$scope.model.tapapi) {
            $scope.model.tapapi = {};
        } else {
            $scope.model.tapapi = JSON.parse($scope.model.tapapi);
        }
        // Google Sheets
        if (!$scope.model.google_sheet) {
            $scope.model.google_sheet = $scope.google_sheet_default;
        } else {
            $scope.model.google_sheet = JSON.parse($scope.model.google_sheet);
            if (_.isEmpty($scope.model.google_sheet)) {
                $scope.model.google_sheet = $scope.google_sheet_default;
            }
        }
        if ($scope.model.google_sheet.sheet_id) {
            $scope.model.google_sheet.sheet_id = parseInt($scope.model.google_sheet.sheet_id);
        }

        if ($scope.model.conn_type === IMPORT_CONN_TYPE.MANUAL) {
            $rootScope.redirector.link = '#/importwizard/manual-upload';
            $rootScope.redirector.param.key = 'id';
        } else if ($scope.model.conn_type === IMPORT_CONN_TYPE.FORM_FIELDS) {
            _.each(oauthUser, function(data) {
                $scope.model.auth_system[data.key] = data.value;
                $scope.original_auth_system[data.key] = data.value;
            });
        }

        // We cannot switch at the moment to TapAPI if we are editing from another delivery type
        if ($scope.model.auth_type !== IMPORT_AUTH_TYPE.TAPAPI) {
            $scope.hideTapAPI = true;
        }
    } else if (!_.isEmpty($stateParams.auth_type) && _.isEmpty($scope.model.auth_type)) {
        $scope.model.auth_type = $stateParams.auth_type;
        $scope.auth_type_select2 = $scope.auth_type_mapping[$scope.model.auth_type];
        $scope.model.conn_type = $scope.authTypes[$scope.authKeys[$scope.model.auth_type]].type;
    }

    AppFactory.setPageTitle($scope.entity.pageTitle);

    $scope.validateTempOauth = function() {
        let pi_temp_id,
            check_code;
        $scope.data_source_loading = true;
        $scope.manage_connection_loading = true;
        if ($scope.model.auth_type === IMPORT_AUTH_TYPE.TAPAPI) {
            $scope.getTapapiUserLoading = true;
        }
        if ($scope.model.auth_type === IMPORT_AUTH_TYPE.TAPDIRECT) {
            pi_temp_id = $scope.model.pi_temp_id;
            check_code = $scope.model.check_code;
        } else {
            pi_temp_id = $stateParams.pi_temp_id;
            check_code = $stateParams.check_code;
        }

        $http.post(
            '/server/api/importwizard/get-temp-oauth/',
            {
                pi_temp_id: pi_temp_id,
                check_code: check_code
            }
        ).then(function successCallback(json) {
            $scope.data_source_loading = false;
            $scope.manage_connection_loading = false;
            $scope.getTapapiUserLoading = false;
            $scope.test_tapdirect_connection_loading = false;
            if (json.data) {
                if ($scope.model.auth_type !== IMPORT_AUTH_TYPE.TAPDIRECT) {
                    $scope.model.name = json.data.data.name;
                    $scope.model.campaign_descriptor = json.data.data.campaign_descriptor;
                    $scope.model.origin_of_data = json.data.data.origin_of_data;
                    $scope.model.origin_of_data_third_party = json.data.data.origin_of_data_third_party;
                    $scope.model.auth_type = $scope.original_auth_type = json.data.data.auth_type;
                    $scope.auth_type_select2 = $scope.auth_type_mapping[$scope.model.auth_type];
                    $scope.model.conn_type = $scope.authTypes[$scope.authKeys[$scope.model.auth_type]].type;
                    $scope.model.color = json.data.data.color;
                    $scope.model.custom_icon = json.data.data.custom_icon;
                    $scope.model.pi_temp_id = $stateParams.pi_temp_id;
                    $scope.model.check_code = $stateParams.check_code;
                    // Let's re-initialize tapdirect if it's not a tapdirect DT
                    $scope.model.tapdirect = $scope.tapdirect_init;
                }

                if (json.data.data.auth_type === IMPORT_AUTH_TYPE.TAPAPI || $scope.model.auth_type === $scope.IMPORT_AUTH_TYPE.GOOGLE_SHEET) {
                    if (json.data.data.auth_type === IMPORT_AUTH_TYPE.TAPAPI) {
                        $scope.model.oauth_user_id = json.data.data.user_id;
                    }

                    $scope.displayLastUserConnected(json.data.data);
                }

                if (json.data.data.auth_type === IMPORT_AUTH_TYPE.BIGQUERY) {
                    $scope.model.oauth_project = json.data.data.project;
                }

                if (json.data.data.auth_type === IMPORT_AUTH_TYPE.GOOGLE_CLOUD_STORAGE) {
                    $scope.model.oauth_bucket = json.data.data.bucket;
                }

                $scope.testConnectionValidated = json.data.data.test_connection_validated;
                $scope.test_tapdirect_connection_validated = json.data.data.test_connection_validated;
                $scope.loadGoogleFolderName();
                if (!json.data.data.test_connection_validated) {
                    let json_error = json.data.data.error_msg;

                    UIFactory.notify.showError('The connection has failed' + ((typeof json_error == 'undefined') ? '. Please try again or contact your account manager if the problem persists.' : ': ' + json_error));
                }

                if ($scope.model.conn_type === IMPORT_CONN_TYPE.LIVE_CONNECTOR) {
                    $scope.model.liveconnector.connector_id = json.data.data.live_connector_id;
                    $scope.loadLiveConnectorMetadata($scope.model.liveconnector.connector_id);
                    $scope.liveConnectorAuthenticated = true;
                }
            }
        }, function errorCallback(json) {
            $scope.data_source_loading = false;
            $scope.manage_connection_loading = false;
            $scope.getTapapiUserLoading = false;
            $scope.test_tapdirect_connection_loading = false;
            $scope.errorCallback(json);
        });
    };

    if (!isEditing && !_.isEmpty($stateParams.pi_temp_id)) {
        $scope.validateTempOauth();
    }

    $scope.displayLastUserConnected = function(obj) {
        if (obj.hasOwnProperty('user_info') && obj.user_info.id) {
            $scope.user_info.id = obj.user_info.id;
            $scope.user_info.email = obj.user_info.email;
            $scope.showLastUserConnected = true;
        } else if (obj.hasOwnProperty('user_email')) {
            $scope.user_info.email = obj.user_email;
            $scope.showLastUserConnected = true;
        } else {
            $scope.showLastUserConnected = false;
        }
    }

    $scope.getCalcCategoriesWidgetsInfo = function(call_type)
    {
        const elementDetail = (call_type === 'service') ? 'sc_delete_details' : 'sc_margin_field_details';
        $('#'+elementDetail).html('<div class="loading-wheel"></div>');
        // TODO : Pass "'/'+$scope.model.id+'/'+call_type" to be able to get more details on margin field details
        $http.get(
            '/server/api/importwizard/usage/'+$scope.model.service_id
        ).then(function successCallback(json) {
            let data = json.data.data,
                categories = data.category.data,
                categories_total = data.category.metadata.total,
                calculations = data.calculation.data,
                calculations_total = data.calculation.metadata.total,
                service_client_mappings = data.serviceclientmapping.data,
                service_client_mappings_total = data.serviceclientmapping.metadata.total,
                lookups = data.servicelookup.data,
                lookups_total = data.servicelookup.metadata.total,
                diff_total = 0,
                htmlContent = '';

            // Categories :
            if (categories && categories_total > 0) {
                htmlContent += '<h6>This data source must be removed from the following channel data view(s):</h6><ul>';

                angular.forEach(categories, function (item, category_id) {
                    let dataviews = item.dataview;
                    let channelUrl = window.isNUI ? '#/categories/' + category_id : '#/categories/detail/' + category_id;
                    htmlContent += '<li class="mb5"><a target="_blank" href="'+channelUrl+'">' + item.name + '</a></li>';
                    if (dataviews && Object.keys(dataviews).length > 0) {
                        angular.forEach(dataviews, function (dataview, dataview_id) {
                            let dataviewUrl = window.isNUI ? '#/categories/'+category_id+'/dataviews/'+dataview_id : '#/categories/detail/'+category_id+'/dataviews/'+dataview_id;
                            htmlContent += '<li class="mb5"><a target="_blank" href="'+dataviewUrl+'">' + item.name + ' → ' + dataview.name + '</a></li>';
                        });
                    }
                });
                if (categories_total > 3) {
                    diff_total = categories_total - 3;
                    htmlContent += '<li class="total-diff mb5">And '+ diff_total + ' more...</li>';
                }
                htmlContent += '</ul>';
            }

            // Calculations :
            if (calculations && calculations_total > 0) {
                htmlContent += '<h6>This data source must be removed from the following calculation(s):</h6><ul>';

                angular.forEach(calculations, function (item, key) {
                    htmlContent += '<li class="mb5"><a target="_blank" href="#/calculations/'+ key +'">' + item.name + '</a></li>';
                });
                if (calculations_total > 3) {
                    diff_total = calculations_total - 3;
                    htmlContent += '<li class="total-diff mb5">And '+ diff_total + ' more...</li>';
                }
                htmlContent += '</ul>';
            }

            // Service mapping :
            if (service_client_mappings && service_client_mappings_total > 0) {
                htmlContent += '<h6>This data source must be removed from the following assignment rule(s):</h6><ul>';

                angular.forEach(service_client_mappings, function (item, key) {
                    htmlContent += '<li class="mb5"><a target="_blank" href="#/serviceclientmappings/'+ key +'">' + item.name + '</a></li>';
                });
                if (service_client_mappings_total > 3) {
                    diff_total = service_client_mappings_total - 3;
                    htmlContent += '<li class="total-diff mb5">And '+ diff_total + ' more...</li>';
                }
                htmlContent += '</ul>';
            }

            // Look ups :
            if (window.isNUI && lookups && lookups_total > 0) {
                htmlContent += '<h6>This data source must be removed from the following data lookup(s):</h6><ul>';

                angular.forEach(lookups, function (item, key) {
                    htmlContent += '<li class="mb5"><a target="_blank" href="#/servicelookups/' + key + '">' + item.name + '</a></li>';
                });
                if (lookups_total > 3) {
                    diff_total = lookups_total - 3;
                    htmlContent += '<li class="total-diff mb5">And '+ diff_total + ' more...</li>';
                }
                htmlContent += '</ul>';
            }

            // Content key :
            if ($scope.model.has_content_key_in_use) {
                htmlContent += '<h6>This data source is used as a content key and must be removed from all content key configurations.</h6>';
            }

            if (htmlContent === '') {
                htmlContent = '<p>Please contact your account manager if you can\'t remove this Smart Connector (msg: 1)</p>';
            }
            $('#'+elementDetail).html(htmlContent);

        }, function errorCallback(json) {
            $('#'+elementDetail).html('<p>Please contact your account manager if you can\'t remove this Smart Connector (msg: 2)</p>');
        });
    };

    $scope.testConnectionLoading = false;

    $scope.loadTapAPIInstanceName = function() {
        if (!_.isEmpty($scope.model.oauth_user_id)) {
            return;
        }

        $scope.getTapapiUserLoading = true;
        $http.get(
            '/server/api/importwizard/get-oauth-user-id/' + $scope.model.service_id
        ).then(function successCallback(json) {
            $scope.getTapapiUserLoading = false;
            $scope.model.oauth_user_id = json.data.data.user_id;
        });
    };

    $scope.validateConnection = function() {
        if ($scope.model.auth_type === $scope.IMPORT_AUTH_TYPE.TAPAPI) {
            $scope.loadTapAPIInstanceName();
        }

        if ($scope.model.conn_type !== IMPORT_CONN_TYPE.MANUAL) {
            $scope.testConnectionLoading = true;
            if (isSublevel) {
                $scope.manage_connection_loading = true;
            }
            $http.post(
                '/server/api/importwizard/test-connection/',
                {
                    service_id: $scope.model.service_id,
                    is_editing: isEditing
                }
            ).then(function successCallback(json) {
                $scope.testConnectionLoading = false;
                $scope.testConnectionValidated = true;
                $scope.manage_connection_loading = false;
                $scope.test_tapdirect_connection_validated = true;
                $scope.test_tapdirect_connection_loading = false;

                if ($scope.model.auth_type === $scope.IMPORT_AUTH_TYPE.TAPAPI || $scope.model.auth_type === $scope.IMPORT_AUTH_TYPE.GOOGLE_SHEET) {
                    $scope.displayLastUserConnected(json.data.data);
                }

                if ($scope.model.auth_type === $scope.IMPORT_AUTH_TYPE.TAPAPI && $scope.model.fields && $scope.model.fields.length > 0) {
                    $scope.loadTapAPISample(true);
                }

                if ($scope.model.conn_type === $scope.IMPORT_CONN_TYPE.LIVE_CONNECTOR) {
                    $scope.liveConnectorAuthenticated = true;
                }

                if (isEditing && $scope.model.auth_type === $scope.IMPORT_AUTH_TYPE.GOOGLE_SHEET) {
                    $scope.validateConnectionGoogleSheetFile();
                }
                $scope.loadGoogleFolderName();
            }, function errorCallback(json) {
                $scope.testConnectionLoading = false;
                $scope.testConnectionValidated = false;
                $scope.manage_connection_loading = false;
                $scope.test_tapdirect_connection_validated = false;
                $scope.test_tapdirect_connection_loading = false;
            });
        } else {
            $scope.testConnectionValidated = true;
        }
    };

    $scope.googleSheethasError = false;
    $scope.validateConnectionGoogleSheetFile = function() {
        $scope.google_sheets_setting = true;
        $http.post(
            '/server/api/importwizard/test-connection-google-sheet/',
            {
                service_id: $scope.model.service_id,
                google_sheet_id: $scope.model.google_sheet.id
            }
        ).then(function successCallback(json) {
            $scope.google_sheets_setting = false;
            if (json.data.data.google_sheet_error) {
                $scope.$evalAsync(function () {
                    $scope.googleSheethasError = true;
                    $scope.GoogleSheetFailMessage = json.data.data.message;
                });
                UIFactory.notify.showError('We are unable to load the Google Sheet.');
            }
        }, function errorCallback(json) {
            $scope.google_sheets_setting = false;
        });
    };

    // validate connection
    if (isEditing && !isSublevel) {
        if ($scope.import && $scope.model.conn_type !== IMPORT_CONN_TYPE.MANUAL) {
            $scope.import = false;

            // If the delivery type is TapAPI, we have to load the instance url
            if ($scope.model.auth_type === $scope.IMPORT_AUTH_TYPE.TAPAPI) {
                $scope.loadTapAPIInstanceName();
            }

            swal({
                title: 'Almost there!',
                text: "To activate your new Smart Connector,\n" + ($scope.model.conn_type === IMPORT_CONN_TYPE.OAUTH) ? "authenticate your connection." : "enter your credentials.",
                type: "success",
                showCancelButton: false,
                confirmButtonColor: "#d9534f",
                confirmButtonText: "Continue",
                closeOnConfirm: true
            }, function() {
                $('#smartconnector-connection').delay(700).fadeIn(200).fadeOut(200).fadeIn(200).fadeOut(200).fadeIn(200);
            });
        } else {
            $scope.validateConnection();
        }
    }

    $scope.dateFormats = dateFormats;

    $scope.updateUseTabName = function() {
        let is_tab_name_use = false;
        if ($scope.model.auth_type === $scope.IMPORT_AUTH_TYPE.GOOGLE_SHEET) {
            is_tab_name_use = $scope.model.google_sheet.use_tab_name_for_mapping;
        } else if ($scope.model.use_tab_parsing) {
            is_tab_name_use = $scope.model.tab_parsing_config.use_tab_name_for_mapping;
        }

        $scope.updateUploadSampleFileURL();
        if (!$scope.model.fields || !$scope.model.fields.length) {
            return;
        }

        let found_tab_mapping = false;
        let index_found = null;
        angular.forEach($scope.model.fields, function(field, index) {
            if (field.heading === $scope.IMPORT_WIZARD.SC_TAB_MAPPING) {
                found_tab_mapping = true;
                if (!is_tab_name_use) {
                    index_found = index;
                    return;
                }
            }
        });

        if (!is_tab_name_use && found_tab_mapping) {
            $scope.removeDataRow(index_found);
        } else if (is_tab_name_use && !found_tab_mapping) {
            let new_field = {
                field: $scope.IMPORT_WIZARD.SC_TAB_MAPPING,
                sample: $scope.IMPORT_WIZARD.SC_TAB_MAPPING_LABEL
            };
            $timeout(function() {
                $scope.parseUploadField(new_field);
            });
        }

        $scope.updateUploadSampleFileURL();
    };

    $scope.isTapDirectFieldSecure = function(source) {
        let source_value = '';
        if (source === 'bearer_token') {
            source_value = $scope.model.tapdirect.authorization_type['bearer_token'].token;
        } else if (source === 'basic_password') {
            source_value = $scope.model.tapdirect.authorization_type['basic'].password;
        } else if (source === 'oauth2_client_secret') {
            source_value = $scope.model.tapdirect.authorization_type['oauth2'].client_secret;
        }

        // If the value is not empty or if the string does not start with "{{"" or finish with ""}}",
        // then display a warning
        return !(source_value == '' || /\{\{.*\}\}/i.test(source_value));
    };

    $scope.isTapDirectOauth2HeaderDoesNotContainsSpace = function() {
        let source_value = $scope.model.tapdirect.authorization_type['oauth2'].header;
        return !(source_value.length == 0 || source_value.charAt(source_value.length-1) == ' ');
    }

    $scope.updateSingleClient = function(isFromMappingFieldChange = false) {
        $scope.updateUploadSampleFileURL();

        // If we already have fields loaded, check if we have SC_VIRTUAL_MAPPING in it
        // If model.map_single_client is set to true and it is not there, add it,
        // if model.map_single_client is set to false and it is there, remove it
        if (!$scope.model.fields || !$scope.model.fields.length) {
            return;
        }

        let found_virtual_mapping = false;
        let index_found = null;
        angular.forEach($scope.model.fields, function(field, index) {
            if (field.heading === $scope.IMPORT_WIZARD.SC_VIRTUAL_MAPPING) {
                found_virtual_mapping = true;
                if (!$scope.model.map_single_client) {
                    index_found = index;
                    return;
                }
            }
            else if ($scope.model.map_single_client && field.is_mapping_field) {
                $scope.model.fields[index].is_mapping_field = false;
            }
        });

        if (!$scope.model.map_single_client && found_virtual_mapping) {
            $scope.removeDataRow(index_found);
            if (!isFromMappingFieldChange) {
                $scope.model.mapping_field = '';
                $scope.updateMappingField();
            }
        } else if ($scope.model.map_single_client && !found_virtual_mapping) {
            let new_field = {
                field: $scope.IMPORT_WIZARD.SC_VIRTUAL_MAPPING,
                sample: $scope.IMPORT_WIZARD.SC_VIRTUAL_MAPPING_LABEL
            };
            $timeout(function() {
                $scope.parseUploadField(new_field);
            });
        }

        $scope.updateUploadSampleFileURL();
    };

    $scope.getAvailableParentUniqueFields = function() {
        return $scope.parentFields.filter(
            myObject => myObject.is_unique_field === true
        );
    };

    $scope.getUniqueFields = function() {
        if (!$scope.model.fields || !$scope.model.fields.length) {
            return [];
        }
        return $scope.model.fields.filter(
            myObject => myObject.is_unique_field === true
        );
    };

    $scope.getStellarFields = function() {
        if (!$scope.model.fields || !$scope.model.fields.length) {
            return [];
        }

        return $scope.unique_fields.filter(
            field => field.id !== $scope.IMPORT_WIZARD.SC_VIRTUAL_MAPPING
        );
    };

    $scope.inheritanceParentField = [];
    $scope.isInheritanceSelected = function(parent_heading, current_heading) {
        for (let i = 0, len = $scope.model.fields.length; i < len; i++) {
            if ($scope.model.fields[i].heading !== current_heading) {
                continue;
            }
            return $scope.model.fields[i].inheritance === parent_heading;
        }
    };

    $scope.updateInheritances = function() {
        // Reset value :
        angular.forEach($scope.model.fields, function(field, key) {
            $scope.model.fields[key].inheritance = null;
        });

        angular.forEach($scope.parentFieldsUnique, function (parent_field) {
            angular.forEach($scope.model.fields, function(field) {
                if (parent_field.child_heading === field.heading) {
                    field.inheritance = parent_field.heading;
                    return;
                }
            });
        });
    }

    $scope.getCurrencyColumn = function(for_margin = false) {
        if (!$scope.model.fields || !$scope.model.fields.length || (for_margin && $scope.model.date_field == IMPORT_WIZARD.VIRTUAL_SC_NODATE)) {
            return [];
        }

        return $scope.model.fields.filter(
            myObject => myObject.data_type === ColumnFormat.FORMAT_CURRENCY
        );
    };

    $scope.getTextColumn = function() {
        if (!$scope.model.fields || !$scope.model.fields.length) {
            return [];
        }

        return $scope.model.fields.filter(
            myObject => (
                !$scope.isVirtualColumnHeaders.includes(myObject.heading) &&
                (myObject.data_type === ColumnFormat.FORMAT_STRING ||
                myObject.data_type === $scope.IMPORT_WIZARD.EXTRA_FORMAT_STRING_ID)
            )
        );
    };

    $scope.hasCurrencyField = function() {
        if (!$scope.model.fields || !$scope.model.fields.length) {
            return false;
        }

        let hasCurrencyField = false;
        angular.forEach($scope.model.fields, function(item) {
            if (item.data_type === ColumnFormat.FORMAT_CURRENCY) {
                hasCurrencyField = true;
                return;
            }
        });
        return hasCurrencyField;
    };

    $scope.getAvailaibleMappingColumns = function() {
        if (!$scope.model.fields || !$scope.model.fields.length) {
            return [];
        }

        return $scope.model.fields.filter(
            myObject => (myObject.data_type === ColumnFormat.FORMAT_STRING || myObject.data_type === $scope.IMPORT_WIZARD.EXTRA_FORMAT_STRING_ID) && !myObject.include_in_charts && !myObject.is_virtual_mapping_field && myObject.heading != $scope.IMPORT_WIZARD.SC_TAB_MAPPING
        );
    };

    $scope.isDatetimeSupported = function() {
        // Check if the related module is activated
        if (!$scope.sc_modules.smartconnector_field_type_datetime) {
            return false;
        }

        // Check if it is supported by the delivery type selected
        return !(!$scope.model.auth_type || !$scope.authTypes[$scope.authKeys[$scope.model.auth_type]].support_datetime_field_type);
    }

    $scope.hasDatetimeField = function() {
        let hasDatetimeField = false;

        if (!$scope.isDatetimeSupported()) {
            $scope.model.timezone_type = null;
            return hasDatetimeField;
        }

        angular.forEach($scope.model.fields, function(item) {
            if (item.data_type === ColumnFormat.FORMAT_DATETIME) {
                hasDatetimeField = true;
                return;
            }
        });
        // If there is no datetime field selected after an update, ensure to set back to none the timezone value
        if (!hasDatetimeField && $scope.model.timezone_type !== null) {
            $scope.model.timezone_type = null;
        } else if (hasDatetimeField && !$scope.model.timezone_type) {
            $scope.model.timezone_type = $scope.IMPORT_WIZARD.TIMEZONE_TYPE_CODE;
        }

        return hasDatetimeField;
    };

    $scope.hasDateField = function() {
        let hasDateField = false;
        angular.forEach($scope.model.fields, function(item) {
            if (item.is_date_field) {
                hasDateField = true;
                return;
            }
        });
        return hasDateField;
    };

    $scope.getDateColumn = function() {
        if (!$scope.model.fields || !$scope.model.fields.length) {
            return [];
        }
        return $scope.model.fields.filter(
            myObject => myObject.data_type === ColumnFormat.FORMAT_DATE || myObject.data_type === ColumnFormat.FORMAT_DATETIME
        );
    };

    $scope.getGeoColumn = function() {
        if (!$scope.model.fields || !$scope.model.fields.length) {
            return;
        }

        return $scope.model.fields.filter(
            myObject => myObject.data_type === ColumnFormat.FORMAT_STRING || myObject.data_type === $scope.IMPORT_WIZARD.EXTRA_FORMAT_STRING_ID
        );
    };

    $scope.getGeoPin = function() {
        if (!$scope.model.fields || !$scope.model.fields.length) {
            return;
        }

        return $scope.model.fields.filter(
            myObject => myObject.data_type === ColumnFormat.FORMAT_DECIMAL && !myObject.is_metric
        );
    };

    //
    // Click event to remove parent data field row from parent data fields table
    //
    $scope.removeGeoDataRow = function (index) {
        $scope.model.mapping_geo_fields.splice(index, 1);
    };

    $scope.selectableDataType = [];
    $scope.getAvailableDataTypes = function(field) {
        if ($scope.selectableDataType.length === 0) {
            let dataType = AppFactory.getDataTypes();
            angular.forEach(dataType, function (item) {
                if (item.is_selectable || (item.key === ColumnFormat.FORMAT_DATETIME && $scope.isDatetimeSupported())) {
                    if (item.key === ColumnFormat.FORMAT_DATETIME) {
                        item.value = $scope.IMPORT_WIZARD.DATETIME;
                    }
                    $scope.selectableDataType = [ ...$scope.selectableDataType, ...[item]];
                }
            });
            let extra_data_types = [],
                has_large_number_enabled = $scope.has_large_number_enabled;

            // If large number is unabled, check if we are editing a SC containing already a large number
            if (!has_large_number_enabled && $scope.isEditing) {
                for (let i = 0, len = $scope.model.fields.length; i < len; i++) {
                    if ($scope.model.fields[i].data_type === $scope.IMPORT_WIZARD.EXTRA_FORMAT_INTEGER_BIG) {
                        has_large_number_enabled = true;
                        break;
                    }
                }
            }

            if (has_large_number_enabled) {
                extra_data_types.push({
                    key: $scope.IMPORT_WIZARD.EXTRA_FORMAT_INTEGER_BIG,
                    icon: "#",
                    is_numeric: true,
                    is_selectable: true,
                    value: "Number (large)",
                    precision: 0
                });
            }
            extra_data_types.push({
                key: $scope.IMPORT_WIZARD.EXTRA_FORMAT_STRING_ID,
                icon: "ID",
                is_numeric: false,
                is_selectable: true,
                value: "ID",
                precision: 0
            });
            $scope.selectableDataType = [ ...$scope.selectableDataType, ...extra_data_types];
            // Uncomment if we decide to sort the data types by value
            /*
            $scope.selectableDataType.sort(function(a, b) {
                return a.value.localeCompare(b.value)
            });
            */
        }

        if ($scope.model.has_data && field.is_metric) {
            return _.filter($scope.selectableDataType, function(data_type) {
                return !($scope.dataTypes.indexOf(data_type.key) < 0) && data_type.is_numeric;
            });
        }

        return _.filter($scope.selectableDataType, function(data_type) {
            return !($scope.dataTypes.indexOf(data_type.key) < 0);
        });
    };

    $scope.json_list_available_paths_waiting = false;
    $scope.getJsonListPaths = function() {
        $('#json_list').html('');
        $scope.json_list_available_paths_waiting = true;
        $http.post(
            '/server/api/importwizard/json_get_list_paths',
            {
                json_test: $scope.json_test
            }
        ).then(function successCallback(json) {
            angular.forEach(json.data.data, function (item) {
                $('#json_list').append('<li>'+item.name+'</li>');
            });
            $scope.json_list_available_paths_waiting = false;
        }, function errorCallback(json) {
            $scope.json_list_available_paths_waiting = false;
            $scope.errorCallback(json);
        });
    }

    $scope.json_sample_data = '';
    $scope.json_extract_sample_waiting = false;
    $scope.show_sample_no_data = false;
    $scope.getJsonSampleExtract = function() {
        $scope.json_sample_data = [];
        $scope.json_extract_sample_waiting = true;
        $scope.show_sample_no_data = false;

        $http.post(
            '/server/api/importwizard/json_get_sample_extract_data',
            {
                json_test: $scope.json_test,
                json_file_config_data_path: $scope.model.json_file_config_data_path,
                json_file_config_data_paths_exclude: $scope.model.json_file_config_data_paths_exclude,
                json_file_config_flatten_paths: $scope.model.json_file_config_flatten_paths,
                json_file_config_global_flatten: $scope.model.json_file_config_global_flatten,
                json_file_config_custom_field_path: $scope.model.json_file_config_custom_field_path,
                json_file_config_custom_field_key: $scope.model.json_file_config_custom_field_key,
                json_file_config_custom_field_value: $scope.model.json_file_config_custom_field_value
            }
        ).then(function successCallback(json) {
            if (json.data && json.data.data && json.data.data.header.length > 0) {
                $scope.json_sample_data = json.data.data;
            } else {
                $scope.show_sample_no_data = true;
            }
            $scope.json_extract_sample_waiting = false;
        }, function errorCallback(json) {
            $scope.json_extract_sample_waiting = false;
            $scope.errorCallback(json);
        });
    };

    /**
     * @param json
     */
    $scope.errorCallback = function(json) {
        let error_msg = '';

        // show the error message returned from the server, if provided
        if (json.responseJSON && json.responseJSON.data) {
            error_msg = json.responseJSON.data[0];
        } else if (json.response && json.response.error) {
            error_msg = json.response.error;
        } else if (json.error) {
            error_msg = json.error;
        } else if (json.data && json.data.data && json.data.data[0]) {
            error_msg = json.data.data[0];
        } else {
            error_msg = 'API error [10]';
        }

        UIFactory.notify.showError(error_msg);
    };

    /**
     * Callback from the uploadFileInput directive when it fails.
     *
     * @param json
     */
    $scope.errorSampleDataCallback = function(json) {
        // show the error message returned from the server, if provided
        if (json.responseJSON && json.responseJSON.data) {
            $scope.sampleFileUploadFailMessage = json.responseJSON.data[0];
        } else if (json.response && json.response.error) {
            $scope.sampleFileUploadFailMessage = json.response.error;
        } else if (json.error) {
            $scope.sampleFileUploadFailMessage = json.error;
        } else {
            $scope.sampleFileUploadFailMessage = 'Uh-oh! Looks like your sample data failed to load.';
        }

        $scope.$evalAsync(function () {
            $scope.model.hasErrorData = true;
        });
    };

    $scope.showHideSection = function (key) {
        let target = $scope.wizardContainer.find('[sckey="'+key+'"]'),
            heading = $(target).find('.panel-heading'),
            body = $(target).find('.panel-body');

        if (!body.hasClass('hide')) {
            heading.find('.dynamic-caret').removeClass('icomoon-caret-down').addClass('icomoon-caret-up');
            body.addClass('hide');
        }
        else {
            heading.find('.dynamic-caret').removeClass('icomoon-caret-up').addClass('icomoon-caret-down');
            body.removeClass('hide');
        }
    }

    $scope.setGroupableFields = function (data) {
        // Reset the array
        $scope.isTapAPIGroupableFields = [];

        angular.forEach(data, function (item) {
            $scope.isTapAPIGroupableFields[item.field] = item.can_be_uniqueness_key;
        });
    };

    $scope.useStellarUpdate = function(use_stellar) {
        if (use_stellar) {
            $scope.model.date_field = $scope.IMPORT_WIZARD.VIRTUAL_SC_NODATE;
            $scope.model.mapping_field = $scope.IMPORT_WIZARD.SC_VIRTUAL_MAPPING;
            $scope.switchDataContainsDate();
            $scope.updateMappingField();
        }
    }

    $scope.isMapToSingleClientDisabled = function() {
        return $scope.model.has_data ||
            $scope.model.mapping_module_active;
    }

    //
    // Click event to add new data field row in data fields table
    //
    $scope.addGeoDataRow = function () {
        $scope.model.mapping_geo_fields.push({
            geo_heading: null,
            geo_type: null,
            geo_type_field: null,
            geo_type_pin_longitude: null,
            geo_type_pin_latitude: null
        });
    };

    $scope.addGeoDataRowIfEmpty = function() {
        if ($scope.model.is_geo_data_active && !$scope.model.mapping_geo_fields.length) {
            $scope.addGeoDataRow();
        }
    };

    $scope.switchDataContainsDate = function() {
        if ($scope.model.date_field === $scope.IMPORT_WIZARD.VIRTUAL_SC_NODATE) {
            $scope.model.is_date_field_required_reverse_value = true;
            $scope.model.is_date_field_required = false;
            $scope.model.margin_field = '';
        } else {
            $scope.model.is_date_field_required_reverse_value = false;
            $scope.model.is_date_field_required = true;
        }
        $scope.updateUploadSampleFileURL();
    }

    //
    // Click event to add new data field row in data fields table
    //
    $scope.addParentDataRow = function () {
        $scope.model.parent_mapping.push({
            private_integration_id: null,
            column_alias: '',
            name: null
        });

        // Select first value by default
        let last_index = $scope.model.parent_mapping.length - 1;
        $scope.model.parent_mapping[last_index].private_integration_id = parseInt($scope.parents[0].id);
        $scope.changeParentView(last_index, true);
    };

    //
    // Click event to remove parent data field row from parent data fields table
    //
    $scope.removeParentDataRow = function (index) {
        $scope.model.parent_mapping.splice(index, 1);
    };

    /**
     * Callback from the uploadFileInput directive when it succeeds.
     *
     * @param data
     */
    $scope.uploadSampleDataCallback = function(data) {
        var is_first_load = !$scope.model.fields || !$scope.model.fields.length;
        if ($scope.isFromExternalSample !== true) {
            $('#load_external_link').html('');
        }
        $scope.isFromExternalSample = false;
        if (!$scope.isEditing) {
            $scope.model.hasErrorData = false;
            $scope.hasSampleData = true;
            $scope.model.mapping_field = null;
            $scope.model.date_field = null;
            $scope.model.unique_field = null;
            $scope.model.fields = [];
        }

        $scope.$evalAsync(function() {
            angular.forEach(data, function(item) {
                let columnExists = false;

                // if no field can be found but upload was successful, set an empty field so that user can simply add rows
                if (item.field == null) {
                    item.field = '';
                }

                // if we are editing, do not replace/remove what is already in place
                if ($scope.isEditing) {
                    let heading = item.field;

                    columnExists = $scope.model.fields.some(function(columns) {
                        if (columns.heading === heading) {
                            return true;
                        }
                    });
                }

                if (!columnExists) {
                    $scope.parseUploadField(item);
                }
            });
            $scope.autoMapping();

            if ($scope.isNewSC && is_first_load && $scope.authTypes[$scope.authKeys[$scope.model.auth_type]].load_from === IMPORT_WIZARD.SQL) {
                // Turn on Quick Assignment by default
                $scope.model.mapping_module_active = true;
            }
        });
    };

    $scope.autoMapping = function() {
        // Process only new SC, main level
        if ($scope.isEditing || $scope.isSublevel) {
            return;
        }

        // If we already have a assignment field selected, stop here :
        if ($scope.model.mapping_field !== null) {
            return;
        }

        // Select the first field already set as an unique field, which
        // does not contain neither "id" or "key" in the label. If no field is found without it,
        // then select the first one.
        let first_field_pos_found = -1;

        for (let i = 0, len = $scope.model.fields.length; i < len; i++) {
            if (!$scope.model.fields[i].is_unique_field) {
                continue;
            }

            // /\b\w[id+\s]\b/ - look for the word "id" or "key", without any bytes before/after, else space
            let contains_id = /\b(id|key)\b/i.test($scope.model.fields[i].name);
            if (contains_id) {
                if (first_field_pos_found === -1) {
                    first_field_pos_found = i;
                }
                continue;
            }

            $scope.model.mapping_field = $scope.model.fields[i].heading;
            $scope.model.fields[i].is_mapping_field = true;

            return;
        }

        // If we are still here, then select the first field having "id" or "key", if any
        if (first_field_pos_found !== -1) {
            $scope.model.mapping_field = $scope.model.fields[first_field_pos_found].heading;
            $scope.model.fields[first_field_pos_found].is_mapping_field = true;
        }
    }

    $scope.google_drive_share_loading = false;
    $scope.loadGoogleDriveShare = function() {
        $scope.google_drive_share_loading = true;
        $.post(
            '/server/api/importwizard/google_drive/load_share_drives',
            {
                pi_temp_id: $scope.model.pi_temp_id,
                check_code: $scope.model.check_code,
                service_id: $scope.model.service_id,
                google_drive_share_id: $scope.model.google_drive_share_id
            }
        ).then(function successCallback(json) {
                $scope.google_drive_share_loading = false;
                if (json.error) {
                    $scope.errorSampleDataCallback(json);
                } else {
                    $scope.model.google_drive_share_last_response = json.data;
                }
                $scope.$apply();
            }, function errorCallback(json) {
                $scope.google_drive_share_loading = false;
                UIFactory.notify.showError('An unexpected error has occurred');
                $scope.$apply();
            }
        );
    }

    /**
     * Prepare new field to be added to the mapping fields
     *
     * @param item
     */
    $scope.parseUploadField = function(item) {
        // try and infer data type using sample data
        let dataType = null,
            isMetric = false,
            operation = null,
            isMappingField = false, // first text field available will be made the assignment field
            isDateField = false, // first date field available will be made the date field
            isUniqueField = false,
            can_be_deleted = true,
            item_label = '',
            inheritance = null,
            isVirtualMappingField = false,
            isFromSourceCalculatedField = false,
            sourceFormula = '',
            isDisplayCampaignName = false,
            isTimezoneField = false,
            isCurrencyField = false;

        // zero values are returned as null by backend so give them back a default value
        if (item.sample == null) {
            item.sample = '0';
        }

        item_label = ((item.label == null || item.label === '') ? item.field.normalize().capitalize() : item.label);

        if (item.source_formula) {
            isFromSourceCalculatedField = true;
            sourceFormula = item.source_formula;
        }

        let lowerCaseFieldLabel = item_label.toLowerCase();

        // If we have the format, we assume having all data from the BE (only TapAPI delivery type for now)
        if (item.format != null && item.format !== '')
        {
            if (item.format === 'id') {
                dataType = $scope.IMPORT_WIZARD.EXTRA_FORMAT_STRING_ID;
            } else {
                dataType = item.format;
            }
            if (item.is_primary_key) {
                isUniqueField = true;
                if ($scope.model.tapapi.unique_keys !== '') {
                    $scope.model.tapapi.unique_keys += ',';
                }
                $scope.model.tapapi.unique_keys += item.field;
            }

            isMetric = item.is_metric;
            operation = item.operation;
            if (item.is_primary_date_field) {
                $scope.model.date_field = item.field;
                isDateField = true;
            }
            if (!$scope.model.mapping_field && item.field === 'client_name') {
                $scope.model.mapping_field = item.field;
                isMappingField = true;
            }
            if (dataType === 'string' || dataType === $scope.IMPORT_WIZARD.EXTRA_FORMAT_STRING_ID) {
                can_be_deleted = true;
            }
        }
        // if a field name contains "id", "key", "phone", "zip", "code" or "tracking", it needs to default to dataType string even if their sample value is a number
        else if (/\b(id|key|zip|code|tracking)\b/.test(lowerCaseFieldLabel))
        {
            dataType = 'string';
        }
        else if (/\b(phone)\b/.test(lowerCaseFieldLabel) || /^\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(item.sample))
        {
            dataType = 'phonenumber';
        }
        else if (item.sample.isInt())
        {
            dataType = 'integer';
            isMetric = true;
            operation = 'sum';
        }
        else if (item.sample.isFloat())
        {
            dataType = 'decimal';
            isMetric = true;
            operation = 'sum';
        }
        else if (item.sample.isCurrency())
        {
            dataType = 'currency';
            isMetric = true;
            operation = 'sum';
        }
        else if (item.sample.isPercentage())
        {
            dataType = 'percent';
            isMetric = true;
            operation = 'average';
        }
        else if (item.sample.isTime())
        {
            dataType = 'time';
        }
        // Date.parse return true for a string like "m" and it does not detect millisecond
        else if (item.sample.length > 3 && item.sample.replace('.000', '').isDate())
        {
            // Let's check if we have time (at least one character ":") in the string - if yes, then set it as datetime
            dataType = (item.sample.includes(':') && $scope.isDatetimeSupported()) ? ColumnFormat.FORMAT_DATETIME : ColumnFormat.FORMAT_DATE;
            if ($scope.model.date_field === '' || $scope.model.date_field === null) {
                $scope.model.date_field = item.field;
                isDateField = true;
            }
        } else {
            if ($scope.model.mapping_field === '') {
                $scope.model.mapping_field = item.field;
                isMappingField = true;
            }
        }
        if (!$scope.model.mapping_field && item.field === IMPORT_WIZARD.SC_TAB_MAPPING) {
            $scope.model.mapping_field = item.field;
            isMappingField = true;
        }
        if (item.field === IMPORT_WIZARD.SC_VIRTUAL_MAPPING) {
            isVirtualMappingField = true;
            $scope.model.mapping_field = item.field;
            isUniqueField = true;
            isMappingField = true;
        }
        // if no dataType was detected, set it to string
        if (dataType == null) {
            dataType = 'string';
            if (!item.sample) {
                can_be_deleted = true;
            }
        }

        if (!$scope.isEditing && dataType === 'string' || dataType === $scope.IMPORT_WIZARD.EXTRA_FORMAT_STRING_ID) {
            if ($scope.isSublevel) {
                let $parentFields = $scope.getAvailableParentUniqueFields();

                for (let key in $parentFields) {
                    if ($parentFields[key].heading && $parentFields[key].heading.toLowerCase() === item.field.toLowerCase()) {
                        dataType = $scope.IMPORT_WIZARD.EXTRA_FORMAT_STRING_ID;
                        isUniqueField = true;
                        inheritance = $parentFields[key].heading;
                        break;
                    }
                }
            } else if (/\b(id|key)\b/.test(lowerCaseFieldLabel)) {
                // If it's TapAccess, make sure that we can set it as a unique key
                if ((!$scope.model.auth_type === IMPORT_AUTH_TYPE.TAPAPI) || ($scope.model.auth_type === IMPORT_AUTH_TYPE.TAPAPI && $scope.isTapAPIGroupableFields[item.field])) {
                    dataType = $scope.IMPORT_WIZARD.EXTRA_FORMAT_STRING_ID;
                    isUniqueField = true;
                }
            }
        }

        let warningMessage = $scope.getWarningLargeNumberMessage(isMetric, dataType, isFromSourceCalculatedField, item.sample);

        $scope.model.fields.push({
            data_type: dataType,
            is_metric: isMetric,
            inheritance: inheritance,
            include_in_charts: isMetric,
            operation: operation,
            is_mapping_field: isMappingField,
            is_date_field: isDateField,
            is_unique_field: isUniqueField,
            heading: item.field,
            name: item_label,
            sample: item.sample,
            can_be_deleted: can_be_deleted,
            is_virtual_mapping_field: isVirtualMappingField,
            isFromSourceCalculatedField: isFromSourceCalculatedField,
            sourceFormula: sourceFormula,
            warningMessage: warningMessage,
            is_display_campaign_name: isDisplayCampaignName,
            is_timezone_field: isTimezoneField,
            is_currency_field: isCurrencyField
        });
        $scope.updateUniqueFields();
    };

    //
    // Change event to update the model mapping_field * date_field when updating the heading value (this ensures it doesn't get unchecked from a value bound change)
    //
    $scope.updateIsNameDateFields = function(index) {
        let dataField = $scope.model.fields[index];

        if (dataField.is_mapping_field) {
            $scope.model.mapping_field = dataField.heading;
        }

        if (dataField.is_date_field) {
            $scope.model.date_field = dataField.heading;
        }

        if (dataField.is_unique_field) {
            $scope.model.is_unique_field = dataField.heading;
        }

        if (dataField.is_timezone_field) {
            $scope.model.timezone_field = dataField.heading;
        }

        if (dataField.is_currency_field) {
            $scope.model.currency_field = dataField.heading;
        }

        $scope.updateUniqueFields();
    };

    $scope.updateTimezoneField = function() {
        angular.forEach($scope.model.fields, function(item, ndx) {
            $scope.model.fields[ndx].is_timezone_field = (item.heading === $scope.model.timezone_field && $scope.model.timezone_type === $scope.IMPORT_WIZARD.TIMEZONE_TYPE_FIELD);
        });
    }

    //
    // Change event to enable/disable is metric checkbox
    //
    $scope.showAuthenticationType = function(auth_type) {
        if ($scope.isSublevel) {
            return true;
        }

        if (auth_type.id === $scope.IMPORT_AUTH_TYPE.TAPAPI) {
            return !$scope.hideTapAPI;
        } else if (auth_type.id === $scope.IMPORT_AUTH_TYPE.TAPDIRECT || auth_type.id === $scope.IMPORT_AUTH_TYPE.BRIGHT_EDGE_QL || auth_type.id === $scope.IMPORT_AUTH_TYPE.GOOGLE_ANALYTICS || auth_type.id === $scope.IMPORT_AUTH_TYPE.BIGQUERY_LIVE || auth_type.id === $scope.IMPORT_AUTH_TYPE.SNOWFLAKE_LIVE) {
            return (auth_type.module_activated || $scope.original_auth_type === auth_type.id);
        }

        return true;
    }

    // Prepare the auth_type listing
    $scope.auth_type_categories = {};
    $scope.auth_type_categories[IMPORT_WIZARD.AUTH_TYPE_CATEGORY_BASIC] = [];
    $scope.auth_type_categories[IMPORT_WIZARD.AUTH_TYPE_CATEGORY_HOSTED_FILE] = [];
    $scope.auth_type_categories[IMPORT_WIZARD.AUTH_TYPE_CATEGORY_DATABASE] = [];
    $scope.auth_type_categories[IMPORT_WIZARD.AUTH_TYPE_CATEGORY_DIRECT_TO_SOURCE] = [];
    $scope.auth_type_categories[IMPORT_WIZARD.AUTH_TYPE_CATEGORY_OTHER] = [];

    angular.forEach(authTypes, function(value, key) {
        // Should we show the delivery type?
        if (!$scope.showAuthenticationType(value)) return;

        // Can the user have the permission to create a new SC based on this delivery type?
        let is_enabled = value.has_write_access || ($scope.original_auth_type === value.id);

        let category = value.category;
        if (!$scope.auth_type_categories.hasOwnProperty(category)) {
            category = IMPORT_WIZARD.AUTH_TYPE_CATEGORY_OTHER;
        }
        let myObject = {
            id: value.id,
            text: value.label,
            disabled: !is_enabled,
            color: value.service_color_default,
            icon: value.service_icon_default
        };
        $scope.auth_type_mapping[value.id] = myObject;
        $scope.auth_type_categories[category].push(myObject);
        if ($scope.model.auth_type === value.id) {
            $scope.auth_type_select2 = myObject;
        }
    });
    let delivery_type_by_categories = [];
    angular.forEach($scope.auth_type_categories, function(delivery_types, category) {
        if ($scope.auth_type_categories[category].length > 0) {
            delivery_type_by_categories.push({
                id: '',
                text: IMPORT_WIZARD.AUTH_TYPE_CATEGORY[category],
                disabled: true,
                children: $scope.auth_type_categories[category],
            });
        }
    });

    $scope.currency_iso_options = {
        data: $scope.genericInfo.currencies,
        multiple: false,
        dropdownCssClass: 'smartconnector-select2',
        width: '50%',
        placeholder: 'Select a currency type'
    };

    $scope.$watch('currency_iso', function(nV, oV) {
        if (nV) {
            if (nV.id && $scope.model.currency_iso !== nV.id) {
                $scope.model.currency_iso = nV.id;
            } else if ((typeof nV) == 'string') {
                $scope.model.currency_iso = nV;
            }
        }
    });

    $scope.timezone_code_options = {
        data: $scope.genericInfo.timezones,
        multiple: false,
        dropdownCssClass: 'smartconnector-select2',
        width: '50%',
        placeholder: 'Select a timezone'
    };

    $scope.$watch('timezone_code', function(nV, oV) {
        if (nV) {
            if (nV.id && $scope.model.timezone_code !== nV.id) {
                $scope.model.timezone_code = nV.id;
            } else if ((typeof nV) == 'string') {
                $scope.model.timezone_code = nV;
            }
        }
    });

    $scope.delivery_type_options = {
        data: delivery_type_by_categories,
        multiple: false,
        dropdownCssClass: 'smartconnector-select2',
        width: '50%',
        placeholder: 'Select a delivery type',
        formatResult: function(item) {
            if (!item.id) { return item.text; }
            return $('<div><div class="service-square service-square-24" style="background-color:' + item.color + '"><div class="icon ' + item.icon + '"></div></div> ' + item.text + '</div>');
        },
        formatSelection: function(item) {
            if (!item.id) { return item.text; }
            return $('<div><div class="service-square service-square-24" style="background-color:' + item.color + '"><div class="icon ' + item.icon + '"></div></div> ' + item.text + '</div>');
        }
    };

    $scope.$watch('auth_type_select2', function(nV, oV) {
        if (nV) {
            if (nV.id && $scope.model.auth_type !== nV.id) {
                $scope.model.auth_type = nV.id;
                $scope.changeAuthType();
            } else if ((typeof nV) == 'string') {
                $scope.model.auth_type = nV;
                $scope.changeAuthType();
            }
        }
    });


    /* Unique Fields */
    $scope.unique_fields = [];
    $scope.unique_fields_preselected = [];
    $scope.model.select2_unique_fields = [];

    $scope.setupUniqueFieldsSelect2 = function() {
        $scope.unique_fields = [];
        $scope.unique_fields_preselected = [];
        $scope.model.select2_unique_fields = [];
        angular.forEach($scope.model.fields, function(field, index) {
            if (!$scope.supported_data_types.includes(field.data_type)) {
                return;
            }
            // Exception for TapAccess - some string fields cannot be selected :
            if ($scope.model.auth_type === IMPORT_AUTH_TYPE.TAPAPI && $scope.isTapAPIGroupableFields.length > 0 && !$scope.isTapAPIGroupableFields[field.heading]) {
                return;
            }
            let can_be_unique = {
                id: field.heading,
                text: field.name,
                details: field
            };
            $scope.unique_fields.push(can_be_unique);
            if (field.is_unique_field) {
                $scope.unique_fields_preselected.push(can_be_unique);
            }
        });
    };
    $scope.setupUniqueFieldsSelect2();

    $scope.select2_unique_fields_config = {
        data: $scope.unique_fields,
        multiple: true,
        dropdownCssClass: 'smartconnector-select2',
        width: '100%',
        placeholder: 'Select the unique field(s)',
        formatResult: function(item, container) {
            if (item.id === $scope.IMPORT_WIZARD.SC_VIRTUAL_MAPPING) {
                $(container).parent().find('.select2-search-choice-close').addClass('hide');
                item.text = IMPORT_WIZARD.SMART_CONNECTOR_VIRTUAL_ASSIGMENT_TEXT;
            }
            return $('<div class="smart_connector" title="'+item.text+'"><label class="label"><span class="icon">T</span></label><span class="ml5">' + item.text + '</span></div>');
        },
        formatSelection: function(item, container) {
            if (item.id === $scope.IMPORT_WIZARD.SC_VIRTUAL_MAPPING) {
                $(container).parent().find('.select2-search-choice-close').addClass('hide');
                item.text = IMPORT_WIZARD.SMART_CONNECTOR_VIRTUAL_ASSIGMENT_TEXT;
            }
            return $('<div class="select-data-column" title="'+item.text+'"><span class="icon">T</span>' + item.text + '</div>');
        }
    };
    // This is needed for when a user click "cancel"
    $scope.original_unique_fields_preselected = $scope.unique_fields_preselected;
    $scope.original_select2_unique_fields_config = $scope.select2_unique_fields_config;

    $scope.$watch('unique_fields_preselected', function(nV, oV) {
        if (nV) {
            let headings = [];
            angular.forEach(nV, function(field_selected) {
                headings.push(field_selected.id);
            });

            // Review all fields unique key values
            angular.forEach($scope.model.fields, function(field, index) {
                $scope.model.fields[index].is_unique_field = headings.includes(field.heading);
            });
        }
    });

    $scope.reinitUniqueValues = function() {
        $scope.select2_unique_fields_config = $scope.original_select2_unique_fields_config;
        $scope.unique_fields_preselected = $scope.original_unique_fields_preselected;
    };

    $scope.updateUniqueFields = function() {
        $scope.setupUniqueFieldsSelect2();

        $scope.select2_unique_fields_config = {
            data: $scope.unique_fields,
            multiple: true,
            dropdownCssClass: 'smartconnector-select2',
            width: '100%',
            placeholder: 'Select the unique field(s)',
            formatResult: function(item, container) {
                if (item.id === $scope.IMPORT_WIZARD.SC_VIRTUAL_MAPPING) {
                    $(container).parent().find('.select2-search-choice-close').addClass('hide');
                    item.text = IMPORT_WIZARD.SMART_CONNECTOR_VIRTUAL_ASSIGMENT_TEXT;
                }
                return $('<div class="smart_connector" title="' + item.text + '"><label class="label"><span class="icon">T</span></label><span class="ml5">' + item.text + '</span></div>');
            },
            formatSelection: function(item, container) {
                if (item.id === $scope.IMPORT_WIZARD.SC_VIRTUAL_MAPPING) {
                    $(container).parent().find('.select2-search-choice-close').addClass('hide');
                    item.text = IMPORT_WIZARD.SMART_CONNECTOR_VIRTUAL_ASSIGMENT_TEXT;
                }
                return $('<div class="select-data-column" title="' + item.text + '"><span class="icon">T</span>' + item.text + '</div>');
            }
        };

        if ($scope.isSublevel) {
            $scope.updateParentFieldsUnique();
        }

        $scope.updateDisplayCampaignName();
    }

    /* Display Name Setting */
    $scope.display_campaign_name = [];
    $scope.display_campaign_name_preselected = [];
    $scope.model.select2_display_campaign_name = [];

    $scope.setupDisplayCampaignNameSelect2 = function() {
        $scope.display_campaign_name = [];
        $scope.model.select2_display_campaign_name = [];
        $scope.display_campaign_name_preselected = [];
        angular.forEach($scope.model.fields, function(field, index) {
            if (!$scope.supported_data_types.includes(field.data_type)) {
                return;
            }
            // Exception for TapAccess - some string fields cannot be selected :
            if ($scope.model.auth_type === IMPORT_AUTH_TYPE.TAPAPI && $scope.isTapAPIGroupableFields.length > 0 && !$scope.isTapAPIGroupableFields[field.heading]) {
                return;
            }
            let can_be_unique = {
                id: field.heading,
                text: field.name,
                position: field.display_campaign_name_index,
                details: field
            };
            $scope.display_campaign_name.push(can_be_unique);
            if (field.is_display_campaign_name) {
                $scope.display_campaign_name_preselected.push(can_be_unique);
            }
        });
        $scope.display_campaign_name_preselected.sort(function(a, b) {
            return a.position - b.position;
        });


    };
    if (!$scope.isSublevel) {
        $scope.setupDisplayCampaignNameSelect2();
    }

    $scope.select2_display_campaign_name_config = {
        data: $scope.display_campaign_name,
        multiple: true,
        dropdownCssClass: 'smartconnector-select2',
        width: '100%',
        placeholder: 'Select the unique field(s)',
        formatResult: function(item, container) {
            if (item.id === $scope.IMPORT_WIZARD.SC_VIRTUAL_MAPPING) {
                $(container).parent().find('.select2-search-choice-close').addClass('hide');
                item.text = IMPORT_WIZARD.SMART_CONNECTOR_VIRTUAL_ASSIGMENT_TEXT;
            }
            return $('<div class="smart_connector" title="'+item.text+'"><label class="label"><span class="icon">T</span></label><span class="ml5">' + item.text + '</span></div>');
        },
        formatSelection: function(item, container) {
            if (item.id === $scope.IMPORT_WIZARD.SC_VIRTUAL_MAPPING) {
                $(container).parent().find('.select2-search-choice-close').addClass('hide');
                item.text = IMPORT_WIZARD.SMART_CONNECTOR_VIRTUAL_ASSIGMENT_TEXT;
            }
            return $('<div class="select-data-column" title="'+item.text+'"><span class="icon">T</span>' + item.text + '</div>');
        }
    };
    // This is needed for when a user click "cancel"
    $scope.original_display_campaign_name_preselected = $scope.display_campaign_name_preselected;
    $scope.original_select2_display_campaign_name_config = $scope.select2_display_campaign_name_config;

    $scope.$watch('display_campaign_name_preselected', function(nV, oV) {
        if ($scope.isSublevel) {
            return;
        }
        if (nV) {
            let headings = [];
            angular.forEach(nV, function(field_selected) {
                headings.push(field_selected.id);
            });

            // Review all fields, add/remove fields from display_campaign_name_preselected and keep their positions if any selected
            angular.forEach($scope.model.fields, function(field, index) {
                if (!headings.includes(field.heading)) {
                    $scope.model.fields[index].is_display_campaign_name = false;
                    $scope.model.fields[index].display_campaign_name_index = null;
                }
                else {
                    let heading = field.heading;
                    $scope.model.fields[index].is_display_campaign_name = true;
                    $scope.model.fields[index].display_campaign_name_index = headings.findIndex(function(item, i){
                        return item === heading
                    });
                }
            });
        }
    });

    $scope.reinitDisplayCampaignName = function() {
        $scope.select2_display_campaign_name_config = $scope.original_select2_display_campaign_name_config;
        $scope.display_campaign_name_preselected = $scope.original_display_campaign_name_preselected;
    };

    $scope.updateDisplayCampaignName = function() {
        $scope.setupDisplayCampaignNameSelect2();

        $scope.select2_display_campaign_name_config = {
            data: $scope.display_campaign_name,
            multiple: true,
            dropdownCssClass: 'smartconnector-select2',
            width: '100%',
            placeholder: 'Select field(s)',
            formatResult: function(item, container) {
                return $('<div class="smart_connector" title="' + item.text + '"><label class="label"><span class="icon">T</span></label><span class="ml5">' + item.text + '</span></div>');
            },
            formatSelection: function(item, container) {
                return $('<div class="select-data-column" title="' + item.text + '"><span class="icon">T</span>' + item.text + '</div>');
            }
        };
    }

    $scope.parentFieldsUnique = [];
    $scope.updateParentFieldsUnique = function() {
        $scope.parentFieldsUnique = [];

        angular.forEach($scope.getAvailableParentUniqueFields(), function (item) {
            let child_heading = null;
            angular.forEach($scope.model.fields, function(item2) {
                if (item.heading === item2.inheritance) {
                    child_heading = item2.heading;
                    return;
                }
            });

            $scope.parentFieldsUnique.push({
                name: item.name,
                heading: item.heading,
                child_heading: child_heading
            });
        });
    }


    $scope.showSqlWarning = function() {
        // If model.sql_statement is not empty and do not contains "order by", let display a warning:
        return $scope.model.sql_statement.trim() != '' && !$scope.model.sql_statement.match(/order by/i);
    };

    $scope.getWarningLargeNumberMessage = function(is_metric, data_type, is_from_source_calculated_field, sample) {
        let warningMessage = false; // Let's change it to a string if we have any msg to display
        if (is_metric && data_type != $scope.IMPORT_WIZARD.EXTRA_FORMAT_INTEGER_BIG && !is_from_source_calculated_field && (parseInt(sample) >= 2147483647 || parseInt(sample) <= -2147483648)) {
            warningMessage = 'The sample value number is too large for the field type selected. Should this field be set as a text field (ex. is it a phone number or an ID)? ';
            warningMessage += 'If you want to keep this field as a number, ';
            if ($scope.has_large_number_enabled) {
                warningMessage += 'select the field type "Number (large)".';
            } else {
                warningMessage += 'ask your account manager to add the support for the field type "Number (large)"on your instance.';
            }
        }
        return warningMessage;
    }

    $scope.showJsonConfigModule = function() {
        if (!$scope.model.auth_type)
            return false;
        let result = $scope.model.auth_type != $scope.IMPORT_AUTH_TYPE.BRIGHT_EDGE_QL &&
            $scope.model.auth_type != $scope.IMPORT_AUTH_TYPE.GOOGLE_SHEET && (
                $scope.authTypes[$scope.authKeys[$scope.model.auth_type]].load_from == $scope.IMPORT_WIZARD.FILE ||
                $scope.authTypes[$scope.authKeys[$scope.model.auth_type]].load_from == $scope.IMPORT_WIZARD.MANUAL ||
                $scope.authTypes[$scope.authKeys[$scope.model.auth_type]].load_from == $scope.IMPORT_WIZARD.DYNAMIC);
        return result;
    };

    //
    // Change event to enable/disable is metric checkbox
    //
    $scope.changeDataType = function(index) {
        let dataField = $scope.model.fields[index];

        // If the row being data type changed contains the main mapping/date/unique_field, then set it to blank
        // in case it was checked on a field that could now be disabled
        if (dataField.heading === $scope.model.mapping_field) {
            $scope.model.mapping_field = null;
        }

        if (dataField.heading === $scope.model.date_field &&
            $scope.model.fields[index].data_type !== ColumnFormat.FORMAT_DATE &&
            $scope.model.fields[index].data_type !== ColumnFormat.FORMAT_DATETIME) {
            $scope.model.date_field = null;
        }

        let selectedOption = dataField.data_type;

        // making sure the field isn't use as a unique field if it's not a string
        if (selectedOption !== 'string' && selectedOption !== $scope.IMPORT_WIZARD.EXTRA_FORMAT_STRING_ID) {
            dataField.is_unique_field = false;
            dataField.is_display_campaign_name = false;
        }

        // set is_metric and include_in_charts to false if field type is string, date, datetime, time, link, audio, thumbnail
        dataField.is_metric = selectedOption !== ColumnFormat.FORMAT_STRING &&
            selectedOption !== $scope.IMPORT_WIZARD.EXTRA_FORMAT_STRING_ID &&
            selectedOption !== ColumnFormat.FORMAT_DATE &&
            selectedOption !== ColumnFormat.FORMAT_DATETIME &&
            selectedOption !== ColumnFormat.FORMAT_LINK &&
            selectedOption !== ColumnFormat.FORMAT_AUDIO &&
            selectedOption !== ColumnFormat.FORMAT_CLICK_TO_VIEW_LINK &&
            selectedOption !== ColumnFormat.FORMAT_PHONE_NUMBER &&
            selectedOption !== ColumnFormat.FORMAT_THUMBNAIL;

        dataField.include_in_charts = dataField.is_metric;

        if (dataField.is_metric) {
            if (selectedOption === 'percent') {
                dataField.operation = 'average';
            } else if (dataField.is_metric) {
                dataField.operation = 'sum';
            }
        } else {
            dataField.operation = null;
        }

        // If we don't have any currency field type, make sure that margin_field and currency_field are empty
        if (!$scope.hasCurrencyField()) {
            $scope.model.margin_field = '';
        }

        dataField.warningMessage = $scope.getWarningLargeNumberMessage(dataField.is_metric, dataField.data_type, dataField.isFromSourceCalculatedField, dataField.sample);

        $scope.updateUniqueFields();
    };

    $scope.changeMarginField = function() {
        if ($scope.model.margin_field === '') {
            return;
        }
        // Make sure that the field selected to be a margin has metric set to true and
        // the operation type is set to "sum"
        angular.forEach($scope.model.fields, function(item) {
            if ($scope.model.margin_field === item.heading) {
                item.is_metric = true;
                item.operation = 'sum';
            }
        });
    }

    $scope.loadTapAPIGeoSample = function(data) {
        // Skip this operation when we are editing
        if ($scope.isEditing) {
            return;
        }
        $scope.model.mapping_geo_fields = [];
        $scope.$evalAsync(function() {
            angular.forEach(data, function (item) {
                if (item.is_geo) {
                    $scope.model.is_geo_data_active = true;
                    let geo_field = null,
                        geo_longitude = null,
                        geo_latitude = null;
                    if (item.geo_config.type !== 'pin') {
                        geo_field = item.geo_config.column;
                    } else {
                        geo_longitude = item.geo_config.column_longitude;
                        geo_latitude = item.geo_config.column_latitude;
                    }
                    $scope.model.mapping_geo_fields.push({
                        geo_heading: item.field,
                        geo_type: item.geo_config.type,
                        geo_type_field: geo_field,
                        geo_type_pin_longitude: geo_longitude,
                        geo_type_pin_latitude: geo_latitude
                    });
                }
            });
        });

    };

    $scope.loadTapAPISample = function(loadGroupableFieldsOnly) {
        $scope.loadTapAPISampleLoading = true;
        $scope.$evalAsync(function() {
            $scope.model.hasErrorData = false;
        });

        $.post(
            '/server/api/importwizard/load_tapapi_sample_data',
            {
                auth_type: $scope.model.auth_type,
                pi_temp_id: $stateParams.pi_temp_id,
                check_code: $stateParams.check_code,
                service_id: $scope.model.service_id,
                is_date_field_required: $scope.model.is_date_field_required,
                use_stellar: $scope.model.use_stellar,
                tapapi: $scope.model.tapapi,
            }
        ).then(function successCallback(json) {
            $scope.loadTapAPISampleLoading = false;
            if (json.error) {
                $scope.errorSampleDataCallback(json);
            } else {
                // Set a variable to know which TapAPI fields are groupable
                $scope.setGroupableFields(json.data);

                // Upload sample data
                if (!loadGroupableFieldsOnly) {
                    $scope.uploadSampleDataCallback(json.data);
                }

                // Support Geo Config
                $scope.loadTapAPIGeoSample(json.data);
            }
            $scope.$apply();
            $scope.updateUniqueFields();
        }, function errorCallback(json) {
            $scope.loadTapAPISampleLoading = false;
            UIFactory.notify.showError('API error [5]');
        });
    };

    $scope.loadDBSampleLoading = false;
    $scope.model.hasMappingErrorData = false;
    $scope.loadDBSample = function(isMapping) {
        var only_mapping = isMapping ?? false,
            sql_statement = '';

        if (!only_mapping) {
            sql_statement = $scope.model.sql_statement;
            $scope.loadDBSampleLoading = true;
            $scope.sampleFileUploadFailMessage = '';
            $scope.$evalAsync(function() {
                $scope.model.hasErrorData = false;
            });
        } else {
            sql_statement = $scope.model.mapping_module_sql;
            $scope.SQLAutoSuggestionLoading = true;
            $scope.sampleDBMappingFailMessage = '';
            $scope.$evalAsync(function() {
                $scope.model.hasMappingErrorData = false;
            });
        }

        $.post(
            '/server/api/importwizard/load_db_sample_data',
            {
                auth_type : $scope.model.auth_type,
                auth_system : $scope.model.auth_system,
                sql_statement : sql_statement,
                soql_dateformat : $scope.model.soql_dateformat,
                pi_temp_id: $scope.model.pi_temp_id,
                check_code: $scope.model.check_code,
                service_id: $scope.model.service_id,
                map_single_client: $scope.model.map_single_client,
                is_date_field_required: false, // Now that this option come after the sample loading, hardcode it to false
                use_stellar: $scope.model.use_stellar,
                only_mapping: only_mapping,
                advanced_assignment: $scope.model.advanced_assignment
            }
        ).then(function successCallback(json) {
            if (!only_mapping) {
                $scope.loadDBSampleLoading = false;
                $scope.sample_sql_statement = json.sql_statement ?? '';
                $scope.full_sample = json.full_sample ?? '';
                if (json.error) {
                    $scope.errorSampleDataCallback(json);
                } else {
                    $scope.uploadSampleDataCallback(json.data);
                }
            }
            else {
                $scope.SQLAutoSuggestionLoading = false;
                $scope.sample_mapping_sql_statement = json.sql_statement ?? '';
                $scope.full_mapping_sample = json.full_sample ?? '';
                if (json.error) {
                    if (json.responseJSON && json.responseJSON.data) {
                        $scope.sampleDBMappingFailMessage = json.responseJSON.data[0];
                    } else if (json.response && json.response.error) {
                        $scope.sampleDBMappingFailMessage = json.response.error;
                    } else if (json.error) {
                        $scope.sampleDBMappingFailMessage = json.error;
                    } else {
                        $scope.sampleDBMappingFailMessage = 'Uh-oh! Looks like your sample data failed to load.';
                    }

                    $scope.$evalAsync(function () {
                        $scope.model.hasMappingErrorData = true;
                    });
                }
                $scope.$apply();
            }
        }, function errorCallback(json) {
            if (!only_mapping) {
                $scope.loadDBSampleLoading = false;
                $scope.sample_sql_statement = '';
                if (json.responseJSON && json.responseJSON.sql_statement) {
                    $scope.sample_sql_statement = json.responseJSON.sql_statement;
                }
                $scope.full_sample = '';
                if (json.error) {
                    $scope.errorSampleDataCallback(json);
                } else {
                    UIFactory.notify.showError('API error [6]');
                }
            }
            else {
                $scope.SQLAutoSuggestionLoading = false;
                $scope.sample_mapping_sql_statement = '';
                $scope.full_mapping_sample = '';

                if (json.responseJSON && json.responseJSON.sql_statement) {
                    $scope.sample_mapping_sql_statement = json.responseJSON.sql_statement;
                }
                if (json.responseJSON && json.responseJSON.data) {
                    $scope.sampleDBMappingFailMessage = json.responseJSON.data[0];
                } else if (json.response && json.response.error) {
                    $scope.sampleDBMappingFailMessage = json.response.error;
                } else if (json.error) {
                    $scope.sampleDBMappingFailMessage = json.error;
                } else {
                    $scope.sampleDBMappingFailMessage = 'Uh-oh! Looks like your sample data failed to load.';
                }
                $scope.$evalAsync(function () {
                    $scope.model.hasMappingErrorData = true;
                });
            }
            $scope.$apply();
        });
    };

    // If we are editing a TapAPI sublevel, load the groupable fields
    if (isEditing && isSublevel && $scope.model.auth_type === $scope.IMPORT_AUTH_TYPE.TAPAPI) {
        $scope.loadTapAPISample(true);
    }

    $scope.wait_oauth_confirmation = false;

    $scope.getNewOauthRedirect = function() {
        $scope.testConnectionValidated = false;
        $scope.testConnectionLoading = true;

        if ($scope.model.auth_type === IMPORT_AUTH_TYPE.TAPDIRECT) {
            $('.tapdirect_oauth').focus();
        }

        $http.post(
            '/server/api/importwizard/get-new-oauth/',
            {
                auth_type: $scope.model.auth_type,
                conn_type: $scope.model.conn_type,
                oauth_user_id: $scope.model.oauth_user_id,
                oauth_project: $scope.model.oauth_project,
                oauth_bucket: $scope.model.oauth_bucket,
                service_id: $scope.model.service_id,
                name: $scope.model.name,
                campaign_descriptor: $scope.model.campaign_descriptor,
                origin_of_data: $scope.model.origin_of_data,
                origin_of_data_third_party: $scope.model.origin_of_data_third_party,
                color: $scope.model.color,
                custom_icon: $scope.model.custom_icon,
                auth_system: $scope.model.auth_system,
                is_cloning: isCloning,
                tapdirect: $scope.model.tapdirect,
                liveconnector: $scope.model.liveconnector
            }
        ).then(function successCallback(json) {
            $scope.testConnectionLoading = false;
            if ($scope.model.auth_type !== IMPORT_AUTH_TYPE.TAPDIRECT) {
                window.location = json.data.data.url;
            } else {
                $scope.wait_oauth_confirmation = true;
                let new_window = window.open(json.data.data.url+'&window_source='+encodeURIComponent(window.location.href), 'oauth', 'width=700,height=500,left=200,top=100,scrollbars=yes,status=yes');
                if (!new_window || new_window.closed || typeof new_window.closed=='undefined') {
                    UIFactory.notify.showWarning('Please ensure that your popup blocker is disabled.');
                }
            }
        }, function errorCallback(json) {
            $scope.testConnectionLoading = false;
            $scope.testConnectionValidated = false;
            if (json.data) {
                UIFactory.notify.showError(json.data[0]);
            } else {
                UIFactory.notify.showError('API error [8]');
            }
        });
    };

    window.addEventListener("hashchange", function(e) {
        if (!$scope.wait_oauth_confirmation) {
            return;
        }

        let testconnection = $scope.getHashParam('testconnection');
        if (testconnection != 1) {
            return;
        }
        e.preventDefault();
        e.stopPropagation();
        $scope.test_tapdirect_connection_validated = false;
        $scope.test_tapdirect_connection_loading = true;
        if (!$scope.model.service_id) {
            $scope.model.pi_temp_id = $scope.getHashParam('new_pi_temp_id');
            $scope.model.check_code = $scope.getHashParam('new_check_code');
        }
        location.replace($scope.origin_url);
        if ($scope.model.service_id) {
            $scope.validateConnection();
        } else {
            $scope.validateTempOauth();
        }
        return false;
    });

    $scope.authenticate = function() {
        $scope.getNewOauth();
    };

    $scope.setDisplayFieldsConfig = function(key, new_value) {
        if (!new_value) {
            new_value = 0;
        }
        $scope.model.fields_config[key] = new_value;
    };

    $scope.getDisplayFieldsConfig = function(key) {
        if (!(key in $scope.model.fields_config)) {
            $scope.model.fields_config[key] = 0;
        }
        return $scope.model.fields_config[key];
    }

    $scope.showDisplayFieldsConfig = function() {
        return $scope.sc_modules.smartconnector_fields_config && $scope.model.display_fields_config;
    };

    $scope.wizardContainer = $('.import-wizard-container');
    $scope.isFieldConfigShow = function(key) {
        let key_value = $scope.getDisplayFieldsConfig(key);

        // If the module is not activated and key value is 0, no need to run into more logic
        if (!$scope.sc_modules.smartconnector_fields_config && key_value == 0) {
            return true;
        }

        let target = $scope.wizardContainer.find('[sckey="'+key+'"]'),
            button = $(target).find('[buttonkey="'+key+'"]');


        $(target).removeClass('bgconfigfieldstate0 bgconfigfieldstate1 bgconfigfieldstate2 bgconfigfieldstate3');
        $(button).removeClass('badge0 badge1 badge2 badge3');

        if ($scope.model.display_fields_config) {
            $(button).addClass('badge' + key_value);
            if (key_value === 2) {
                $(target).addClass('bgconfigfieldstate2');
            } else if (key_value === 3) {
                $(target).addClass('bgconfigfieldstate1');
            } else if (key_value === 1) {
                $(target).addClass('bgconfigfieldstate1');
            } else {
                $(target).addClass('bgconfigfieldstate0');
            }
            return true;
        } else {
            return key_value != 2;
        }
    };

    $scope.isFieldConfigReadOnly = function(key, name) {
        let key_value = $scope.getDisplayFieldsConfig(key);

        if (!name) {
            name = '';
        }

        if (!$scope.model.display_fields_config && key_value === 1) {
            return true;
        } else return !$scope.model.display_fields_config && key_value === 3 && name === 'key';
    };

    $scope.setFieldConfigFor = function(key, possible_states) {
        if (!$scope.sc_modules.smartconnector_fields_config) {
            return void(0);
        }

        let target = $scope.wizardContainer.find('[sckey="'+key+'"]'),
            key_value = $scope.getDisplayFieldsConfig(key),
            next_key_value = 0;

        let button = $(target).find('[buttonkey="'+key+'"]');

        // Set default states
        if (!possible_states) {
            possible_states = [0,1,2];
        }

        let position = possible_states.indexOf(key_value);
        if (position >= 0 && position < possible_states.length - 1) {
            next_key_value = possible_states[position+1];
        }

        $scope.setDisplayFieldsConfig(key, next_key_value);

        $(target).removeClass('bgconfigfieldstate0 bgconfigfieldstate1 bgconfigfieldstate2 bgconfigfieldstate3');
        $(target).addClass('bgconfigfieldstate'+next_key_value);

        if ($(button).length > 0) {
            $(button).removeClass('badge0 badge1 badge2 badge3');
            $(button).addClass('badge'+next_key_value);
        }
    };

    $scope.getFieldConfigStateTextFor = function(key) {
        if (!$scope.sc_modules.smartconnector_fields_config) {
            return '';
        }

        let key_value = $scope.getDisplayFieldsConfig(key);

        if (key_value === 1) {
            return IMPORT_WIZARD.READONLY;
        } else if (key_value === 2) {
            return IMPORT_WIZARD.HIDDEN;
        } else if (key_value === 3) {
            return IMPORT_WIZARD.READONLY_KEY;
        } else {
            return IMPORT_WIZARD.EDITABLE;
        }
    }

    $scope.getNewOauth = function() {
        if (!isEditing && !isCloning) {
            $scope.getNewOauthRedirect();
        } else {
            let redirectText = 'Any unsaved modifications will be lost. Do you want to continue?';
            swal({
                title: "You will be redirected to a third-party website.",
                text: redirectText,
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#d9534f",
                confirmButtonText: "Yes, continue",
                closeOnConfirm: true
            }, function(isConfirm) {
                if (isConfirm) {
                    $scope.getNewOauthRedirect();
                }
            });
        }
    };

    $scope.testConnection = function() {
        $scope.testConnectionValidated = false;
        $scope.testConnectionLoading = true;

        // Close previous errors, if there is any
        $('.notifyjs-error-base').click();

        $http.post(
            '/server/api/importwizard/test-connection/',
            {
                auth_type : $scope.model.auth_type,
                auth_system : $scope.model.auth_system
            }
        ).then(function successCallback(json) {
            $scope.testConnectionLoading = false;

            if ($scope.model.conn_type !== IMPORT_CONN_TYPE.MANUAL) {
                $scope.testConnectionValidated = true;
            }
            $scope.loadGoogleFolderName();

            $.core.main.notify('Test connection successful', $.globals.notify.success);
        }, function errorCallback(json) {
            $scope.testConnectionLoading = false;
            $scope.testConnectionValidated = false;
            if (json.data) {
                if (json.data.data[0]) {
                    UIFactory.notify.showError(json.data.data[0]);
                } else {
                    UIFactory.notify.showError(json.data[0]);
                }
            } else {
                UIFactory.notify.showError('API error [9]');
            }
        });
    };

    $scope.loadLiveConnectorParameters = function() {
        $http.get(
            '/server/api/importwizard/liveconnector_parameters/' + $scope.model.liveconnector.connector_id
        ).then(function successCallback(json) {
            if (json.error) {
                $scope.errorCallback(json);
            } else {
                $.each(json.data.data, function(key, value) {
                    $scope.model.liveconnector.parameters.push({key: key, value: value});
                })
            }
        }, function errorCallback(json) {
            $scope.errorCallback(json);
        });
    };

    $scope.selectPreProcessFunction = function(index) {
        $scope.preprocessFunctionsUrlValidated[index] = false;

        $scope.model.preprocess_functions[index].custom_fields = [];

        if ($scope.model.preprocess_functions[index].selected === PREPROCESS_FUNCTION_CUSTOM_FUNCTION_VALUE) {
            $scope.model.preprocess_functions[index].url = '';
            $scope.model.preprocess_functions[index].is_custom = true;
        } else {
            $scope.model.preprocess_functions[index].url = $scope.model.preprocess_functions[index].selected;
            $scope.model.preprocess_functions[index].is_custom = false;

            if ($scope.model.preprocess_functions[index].selected !== '') {
                $scope.validatePreprocessFunctionUrl(index);
            }
        }
    }

    $scope.loadPreProcessFunctionsLibrary = function() {
        if (!_.isEmpty($scope.model.preprocess_functions_library)) {
            // library functions are already loaded
            return;
        }

        $http.get(
            '/server/api/importwizard/preprocess_functions_library'
        ).then(function successCallback(json) {
            if (json.error) {
                $scope.errorCallback(json);
            } else {
                $.each(json.data.data, function(key, value) {
                    $scope.model.preprocess_functions_library.push(value);
                });
            }
        }, function errorCallback(json) {
            $scope.errorCallback(json);
        });
    }

    if ($scope.model.use_preprocess_functions) {
        $scope.loadPreProcessFunctionsLibrary();
    }

    $scope.validatePreprocessFunctionUrl = function(function_index) {
        $scope.preprocessFunctionsUrlValidating[function_index] = true;

        $http.post(
            '/server/api/importwizard/validate_preprocess_function_url', {
                url: $scope.model.preprocess_functions[function_index].url
            }
        ).then(function successCallback(json) {
            $scope.preprocessFunctionsUrlValidating[function_index] = false;

            if (json.error) {
                $scope.errorCallback(json);
            } else {
                $scope.preprocessFunctionsUrlValidating[function_index] = false;
                $scope.preprocessFunctionsUrlValidated[function_index] = true;

                $scope.model.preprocess_functions[function_index].custom_fields = [];

                $.each(json.data.data.custom_fields, function(name, key) {
                    $scope.model.preprocess_functions[function_index].custom_fields.push({key: key, name: name});
                });
            }
        }, function errorCallback(json) {
            $scope.preprocessFunctionsUrlValidating[function_index] = false;
            $scope.errorCallback(json);
        });
    }

    $scope.loadLiveConnectorMetadata = function(connector_id) {
        $scope.loadLiveConnectorLoading = true;

        $http.get(
            '/server/api/importwizard/liveconnector_metadata/' + connector_id
        ).then(function successCallback(json) {
            $scope.loadLiveConnectorLoading = false;

            if (json.error) {
                $scope.errorCallback(json);
            } else {
                $scope.model.liveconnector.dataviews = json.data.data.dataviews;
                $scope.model.liveconnector.attributes = json.data.data.groupbys;
                $scope.model.liveconnector.metrics = json.data.data.metrics;

                if ($scope.model.liveconnector.parameters.length === 0) {
                    $.each(json.data.data.parameters, function(key, value) {
                        $scope.model.liveconnector.parameters.push({key: key, value: value});
                    });
                }

                $scope.liveConnectorMetadataLoaded = true;
            }
        }, function errorCallback(json) {
            $scope.loadLiveConnectorLoading = false;
            $scope.errorCallback(json);
        });
    };

    $scope.loadLiveConnectorSampleData = function() {
        $scope.loadDataFieldsLoading = true;
        $scope.liveConnectorDataFieldsLoaded = false;

        $.each($scope.model.liveconnector.attributes, function(key, attribute) {
            if (attribute.selected) {
                $scope.model.liveconnector.selected_attributes.push(attribute.field);
            }
        });

        $.each($scope.model.liveconnector.metrics, function(key, metric) {
            if (metric.selected) {
                $scope.model.liveconnector.selected_metrics.push(metric.field);
            }
        });

        let parameters = $scope.model.liveconnector.parameters;

        $http.post(
            '/server/api/importwizard/liveconnector_sample_data/' + $scope.model.liveconnector.connector_id,
            {
                service_id: $scope.model.service_id,
                pi_temp_id: $scope.model.pi_temp_id,
                groupbys : $scope.model.liveconnector.selected_attributes,
                metrics: $scope.model.liveconnector.selected_metrics,
                parameters: parameters
            }
        ).then(function successCallback(json) {
            if (json.error) {
                $scope.loadDataFieldsLoading = false;
                $scope.errorCallback(json);
            } else {
                $scope.liveConnectorDataFieldsLoaded = true;

                $scope.model.fields = [];

                $scope.uploadSampleDataCallback(json.data.data);
                $scope.loadDataFieldsLoading = false;
            }
        }, function errorCallback(json) {
            $scope.loadDataFieldsLoading = false;
            $scope.errorCallback(json);
        })
    };

    if ($scope.model.conn_type === IMPORT_CONN_TYPE.LIVE_CONNECTOR) {
        $scope.loadLiveConnectorMetadata($scope.model.liveconnector.connector_id);
    }

    $scope.loadBrightEdgeQLSample = function() {
        $scope.loadBrightEdgeQLSampleLoading = true;
        $scope.$evalAsync(function () {
            $scope.model.hasErrorData = false;
        });

        $.post(
            '/server/api/importwizard/load_brightedgeql_sample_data',
            {
                service_id: $scope.model.service_id,
                brightedgeql: $scope.model.brightedgeql,
                is_sublevel: $scope.isSublevel,
                auth_system: $scope.model.auth_system
            }
        ).then(function successCallback(json) {
            $scope.loadBrightEdgeQLSampleLoading = false;

            if (json.error) {
                $scope.errorSampleDataCallback(json);
            } else {
                $scope.uploadSampleDataCallback(json.data);
            }
            $scope.$apply();
        }, function errorCallback(json) {
            $scope.loadBrightEdgeQLSampleLoading = false;
            if (json.responseJSON && json.responseJSON.data) {
                UIFactory.notify.showError(json.responseJSON.data[0]);
            } else {
                UIFactory.notify.showError('An unexpected error has occurred (malformed json response). Please contact your account manager if the problem persists. [13]');
            }
            $scope.$apply();
        });
    };

    $scope.showExtractData = false;
    $scope.json_sample_loading = false;
    $scope.loadTapDirectSample = function(loading_type) {
        if (loading_type === 'json_sample') {
            $scope.json_sample_loading = true;
        } else {
            $scope.tapdirect_simple_loading = true;
            $scope.extractData = '';
        }
        $scope.$evalAsync(function () {
            $scope.model.hasErrorData = false;
        });

        $.post(
            '/server/api/importwizard/load_tapdirect_sample_data',
            {
                pi_temp_id: $scope.model.pi_temp_id,
                check_code: $scope.model.check_code,
                service_id: $scope.model.service_id,
                tapdirect: $scope.model.tapdirect,
                is_sublevel: $scope.isSublevel,
                json_file_config_status: $scope.model.json_file_config_status,
                json_file_config_data_path: $scope.model.json_file_config_data_path,
                json_file_config_data_paths_exclude: $scope.model.json_file_config_data_paths_exclude,
                json_file_config_flatten_paths: $scope.model.json_file_config_flatten_paths,
                json_file_config_global_flatten: $scope.model.json_file_config_global_flatten,
                json_file_config_custom_field_path: $scope.model.json_file_config_custom_field_path,
                json_file_config_custom_field_key: $scope.model.json_file_config_custom_field_key,
                json_file_config_custom_field_value: $scope.model.json_file_config_custom_field_value,
                is_date_field_required: $scope.model.is_date_field_required,
                use_stellar: $scope.model.use_stellar,
                loading_type: loading_type
            }
        ).then(function successCallback(json) {
            $timeout(function() {
                $scope.tapdirect_simple_loading = false;
                $scope.json_sample_loading = false;
                if (json.error) {
                    $scope.errorSampleDataCallback(json);
                } else {
                    if (loading_type === 'json_sample') {
                        $scope.json_test = json.raw;
                    } else {
                        $scope.uploadSampleDataCallback(json.data);
                        if (json.raw) {
                            $scope.extractData = json.raw;
                        }
                    }
                }
            });
        }, function errorCallback(json) {
            $timeout(function() {
                $scope.tapdirect_simple_loading = false;
                $scope.json_sample_loading = false;
                if (json.responseJSON && json.responseJSON.data) {
                    UIFactory.notify.showError(json.responseJSON.data[0]);
                    if (json.responseJSON.warnings) {
                        $scope.extractData = json.responseJSON.warnings[0];
                    }
                } else {
                    UIFactory.notify.showError('An unexpected error has occurred (malformed json response). Please contact your account manager if the problem persists. [14]');
                }
            });
        });
    };

    $scope.getNewWebhookEmail = function() {
        if (!isEditing || (isEditing && $scope.webhook_email_has_been_changed)) {
            $scope.loadNewWebhookEmail();
        } else {
            let redirectText = 'Your previous email will become obsolete. Do you want to continue?';
            swal({
                title: "Your existing email will become obsolete.",
                text: redirectText,
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#d9534f",
                confirmButtonText: "Yes, continue",
                closeOnConfirm: true
            }, function(isConfirm) {
                if (isConfirm) {
                    $scope.loadNewWebhookEmail();
                }
            });
        }
    };

    $scope.copyFormElementContent = function(elementId, elementTitle) {
        try {
            document.getElementById(elementId).select();
            document.execCommand('copy');
            UIFactory.notify.showSuccess('The '+elementTitle+' has been copied');
        } catch (ex) {
            UIFactory.notify.showError('The '+elementTitle+' has not been copied');
        }
    };

    $scope.copyHtmlElementContent = function(elementId, elementTitle) {
        try {
            let copyText = document.getElementById(elementId),
                textArea = document.createElement('textarea');
            textArea.value = copyText.textContent;
            document.body.appendChild(textArea);
            textArea.select();
            document.execCommand("Copy");
            textArea.remove();
            UIFactory.notify.showSuccess('The '+elementTitle+' has been copied');
        } catch (ex) {
            UIFactory.notify.showError('The '+elementTitle+' has not been copied');
        }
    };

    $scope.loadNewWebhookEmail = function() {
        $scope.webhook_email_loading = true;
        $http.get(
            '/server/api/importwizard/get-new-webhook-email'
        ).then(function successCallback(json) {
            $scope.webhook_email_loading = false;
            $scope.model.webhook_email = json.data.data;
            $scope.webhook_email_has_been_changed = true;
        }, function errorCallback(json) {
            $scope.webhook_email_loading = false;
            UIFactory.notify.showError('API error [11]');
        });
    };

    $scope.changeWebhookEmailStatus = function() {
        // When activating the webhook email status, load a new webhook email address
        if ($scope.model.webhook_email_status === true && (!$scope.model.webhook_email || $scope.model.webhook_email === '')) {
            $scope.loadNewWebhookEmail();
        }
    };

    //
    // Change event to set the proper redirect URL
    //
    $scope.previous_email = null;
    $scope.changeAuthType = function() {
        if (!$scope.model.auth_type) {
            return;
        }
        $scope.model.conn_type = $scope.authTypes[$scope.authKeys[$scope.model.auth_type]].type;

        if ($scope.model.conn_type === IMPORT_CONN_TYPE.MANUAL) {
            $rootScope.redirector.link = '#/importwizard/manual-upload';
            $rootScope.redirector.param.key = 'id';
            $scope.model.webhook_email_status = false; //TA-44707 - Setting email delivery toggle off by default on manual delivery type
            // email alert should never be set when selecting a manual delivery type
            if ($scope.model.email !== null && $scope.model.email !== '') {
                $scope.previous_email = $scope.model.email;
                $scope.model.email = null;
            }
        } else {
            $rootScope.redirector.link = AppFactory.getMdsRoute();
            $rootScope.redirector.param.key = 'service_id';
            if ($scope.model.email === null || $scope.model.email === '' && $scope.previous_email !== null) {
                $scope.model.email = $scope.previous_email;
            }
        }

        if ($scope.model.auth_type === $scope.IMPORT_AUTH_TYPE.EMAIL) {
            $scope.model.webhook_email_status = true;
            $scope.changeWebhookEmailStatus();
        }

        if ($scope.model.auth_type === $scope.IMPORT_AUTH_TYPE.GOOGLE_SHEET && _.isEmpty($scope.model.google_sheet)) {
            $scope.model.google_sheet = $scope.google_sheet_default;
        }

        // Be sure to keep at false mapping_module_active if it cannot be used
        if ($scope.model.auth_type && !$scope.authTypes[$scope.authKeys[$scope.model.auth_type]].is_mapping_module_available) {
            $scope.model.mapping_module_active = false;
        }

        $scope.testConnectionValidated = false;

        angular.forEach($scope.model.auth_system, function(data, key) {
            $scope.model.auth_system[key] = '';
        });

        if ($scope.model.auth_type === $scope.original_auth_type) {
            angular.forEach(oauthUser, function(data) {
                $scope.model.auth_system[data.key] = data.value;
            });
            $scope.testConnectionValidated = true;
        }

        // To avoid empty value pushed by Angular...
        if (
            $scope.model.auth_type === IMPORT_AUTH_TYPE.MYSQL ||
            $scope.model.auth_type === IMPORT_AUTH_TYPE.AMAZON_AURORA_MYSQL ||
            $scope.model.auth_type === IMPORT_AUTH_TYPE.PGSQL ||
            $scope.model.auth_type === IMPORT_AUTH_TYPE.ALLOYDB ||
            $scope.model.auth_type === IMPORT_AUTH_TYPE.AMAZON_AURORA_PGSQL ||
            $scope.model.auth_type === IMPORT_AUTH_TYPE.REDSHIFT ||
            $scope.model.auth_type === IMPORT_AUTH_TYPE.MICROSOFTSQLSERVER ||
            $scope.model.auth_type === IMPORT_AUTH_TYPE.CLICKHOUSE
        ) {
            $scope.model.auth_system['protocol'] = 'TCPIP';
            $scope.model.auth_system['character_set'] = 'utf8';

            if (!$scope.model.auth_system['port'] ) {
                if ($scope.model.auth_type === IMPORT_AUTH_TYPE.MYSQL ||
                    $scope.model.auth_type === IMPORT_AUTH_TYPE.AMAZON_AURORA_MYSQL) {
                    $scope.model.auth_system['port'] = 3306;
                }
                else if ($scope.model.auth_type === IMPORT_AUTH_TYPE.PGSQL ||
                        $scope.model.auth_type === IMPORT_AUTH_TYPE.AMAZON_AURORA_PGSQL ||
                        $scope.model.auth_type === IMPORT_AUTH_TYPE.ALLOYDB) {
                    $scope.model.auth_system['port'] = 5432;
                }
                else if ($scope.model.auth_type === IMPORT_AUTH_TYPE.REDSHIFT) {
                    $scope.model.auth_system['port'] = 5439;
                }
                else if ($scope.model.auth_type === IMPORT_AUTH_TYPE.MICROSOFTSQLSERVER) {
                    $scope.model.auth_system['port'] = 1433;
                }
                else if ($scope.model.auth_type === IMPORT_AUTH_TYPE.CLICKHOUSE) {
                    $scope.model.auth_system['port'] = 8443;
                }
            }
        }
    };

    $scope.changeAuth = function() {
        $scope.testConnectionValidated = false;

        // Try to autofill the project id from the service account key file data.
        if ($scope.model.auth_type === IMPORT_AUTH_TYPE.BIGQUERY_SERVICE_ACCOUNT && !$scope.model.auth_system.project) {
            try {
                const serviceAccount = JSON.parse($scope.model.auth_system.service_account);
                $scope.model.auth_system.project = serviceAccount.project_id;
            } catch(err) {
                // Do nothing
            }
        }
        if ($scope.model.auth_type === $scope.original_auth_type) {
            for (let key in $scope.original_auth_system) {
                if (!$scope.original_auth_system.hasOwnProperty(key))
                    continue;

                if ($scope.model.auth_system[key] !== $scope.original_auth_system[key]) {
                    return;
                }
            }

            if (!_.isEmpty($scope.model.auth_system['password']) || !_.isEmpty($scope.model.auth_system['secret_access_key'])) {
                return;
            }

            if ($scope.import &&
                $scope.model.conn_type === IMPORT_CONN_TYPE.FORM_FIELDS &&
                _.isEmpty($scope.model.auth_system['password']) &&
                _.isEmpty($scope.model.auth_system['secret_access_key'])) {
                return;
            }

            $scope.testConnectionValidated = true;
        }
    };

    $scope.updateTapAPICategory = function() {
        if ($scope.isEditing) {
            return;
        }

        let $tapapi = $('#tapiapi-data-selection');

        // Reset actual options
        $tapapi.find('.tapapi select').children('option').remove();
        $tapapi.find('.tapapi-dataview select').children('option').remove();
        $scope.model.tapapi.data_source_id = '';
        $scope.model.tapapi.data_channel_id = '';
        $scope.model.tapapi.data_view_id = '';
        $scope.model.tapapi.data_name = '';
        $scope.model.tapapi.data_view_name = '';
        $scope.model.tapapi.unique_keys = '';

        if (!$scope.model.tapapi.category) {
            return;
        }

        // Display "loading..." option for the selected category
        $tapapi.find('.tapapi-' + $scope.model.tapapi.category + ' select')
            .append($("<option value=''>Loading...</option>"));

        $http.post(
            '/server/api/importwizard/get-tapapi-data/',
            {
                auth_type : $scope.model.auth_type,
                pi_temp_id: $stateParams.pi_temp_id,
                check_code: $stateParams.check_code,
                service_id: $scope.model.service_id,
                category: $scope.model.tapapi.category // do not confused with channel
            }
        ).then(function successCallback(json) {
            // Push it in a cache variable, we don't have to reload it, if already loaded...
            $tapapi.find('.tapapi-' + $scope.model.tapapi.category + ' select')
                .children('option').remove();

            if (json.data.data && json.data.data.length === 0) {
                $tapapi.find('.tapapi-' + $scope.model.tapapi.category + ' select')
                    .append($("<option value=''>No data available</option>"));
            } else {
                $tapapi.find('.tapapi-' + $scope.model.tapapi.category + ' select')
                    .append($("<option value=''>Select...</option>"));

                // Sort it right
                let optionData = [];
                $.each(json.data.data, function(key, value) {
                    optionData.push( {key: key, value: value } );
                });

                optionData.sort(
                    function(a,b) {
                        return (a.value.toLowerCase() > b.value.toLowerCase()) ? 1 : ((b.value.toLowerCase() > a.value.toLowerCase()) ? -1 : 0);
                    }
                );

                $.each(optionData, function(key, value) {
                    $tapapi.find('.tapapi-' + $scope.model.tapapi.category + ' select')
                        .append($("<option></option>")
                            .attr("value",value.key)
                            .text(value.value));
                });
            }
        });
    };

    $scope.updateTapAPISource = function() {
        if ($scope.isEditing) {
            return;
        }

        // data-source | channel

        let $tapapi = $('#tapiapi-data-selection');

        $scope.model.tapapi.data_view_id = '';
        $scope.model.tapapi.data_view_name = '';

        // Reset actual options
        $tapapi.find('.tapapi-dataview select').children('option').remove();

        $scope.model.tapapi.data_name = '';

        if ($scope.model.tapapi.data_source_id === '' && $scope.model.tapapi.data_channel_id === '') {
            return;
        }

        if ($scope.model.tapapi.category === 'data-source') {
            $scope.model.tapapi.data_name = $('#tapapi-data-source option:selected').text();
        } else {
            $scope.model.tapapi.data_name = $('#tapapi-data-channel option:selected').text();
        }

        // Display "loading..." option for the dataview select element
        $tapapi.find('.tapapi-dataview select')
            .append($("<option value=''>Loading...</option>"));

        $http.post(
            '/server/api/importwizard/get-tapapi-data/',
            {
                auth_type : $scope.model.auth_type,
                pi_temp_id: $stateParams.pi_temp_id,
                check_code: $stateParams.check_code,
                service_id: $scope.model.service_id,
                category: $scope.model.tapapi.category,
                data_source_id: $scope.model.tapapi.data_source_id,
                data_channel_id: $scope.model.tapapi.data_channel_id
            }
        ).then(function successCallback(json) {
            // Push it in a cache variable, we don't have to reload it, if already loaded...
            $tapapi.find('.tapapi-dataview select')
                .children('option').remove();

            if (json.data.data && json.data.data.length === 0) {
                $tapapi.find('.tapapi-dataview select')
                    .append($("<option value=''>No data available</option>"));
            } else {
                $tapapi.find('.tapapi-dataview select')
                    .append($("<option value=''>Select...</option>"));

                $.each(json.data.data, function(key, value) {
                    $tapapi.find('.tapapi-dataview select')
                        .append($("<option></option>")
                            .attr("value",key)
                            .text(value));
                });
            }
        });
    };

    $scope.getTapDirectLastAvailablePath = function(i) {
        if (!$scope.model.tapdirect.quick_mapping[i].last_available_paths || !$scope.model.tapdirect.quick_mapping[i].last_available_paths.length) {
            return;
        }

        return $scope.model.tapdirect.quick_mapping[i].last_available_paths;
    };

    $scope.tapdirect_quick_mapping_loading = false;
    $scope.showMappingExtractData = false;
    $scope.loadTapDirectQuickMappingExternalPath = function() {
        $scope.tapdirect_quick_mapping_loading = true;
        $scope.mappingExtractData = '';

        $.post(
            '/server/api/importwizard/load_tapdirect_paths',
            {
                pi_temp_id: $scope.model.pi_temp_id,
                check_code: $scope.model.check_code,
                service_id: $scope.model.service_id,
                tapdirect: $scope.model.tapdirect
            }
        ).then(function successCallback(json) {
            $scope.tapdirect_quick_mapping_loading = false;
            if (json.error) {
                $scope.errorSampleDataCallback(json);
            } else {
                $scope.model.tapdirect.quick_mapping[0].last_available_paths = json.data;
                if (json.raw) {
                    $scope.mappingExtractData = json.raw;
                }
            }
            $scope.$apply();
        }, function errorCallback(json) {
            $scope.tapdirect_quick_mapping_loading = false;
            if (json.responseJSON && json.responseJSON.data) {
                UIFactory.notify.showError(json.responseJSON.data[0]);
                if (json.responseJSON.warnings) {
                    $scope.mappingExtractData = json.responseJSON.warnings[0];
                }
            } else {
                UIFactory.notify.showError('An unexpected error has occurred (malformed json response). Please contact your account manager if the problem persists. [15]');
            }
            $scope.$apply();
        });
    }

    $scope.updateTapAPIDataView = function() {
        $scope.model.tapapi.data_view_name =  $('#tapapi-data-view option:selected').text();
    };

    $scope.addTapDirectKeyPairRow = function(obj) {
        obj.push({
            key: '',
            value: ''
        });
    };

    $scope.addPreprocessFunction = function() {
        $scope.model.preprocess_functions.push({
            selected: '',
            url: '',
            custom_fields: [],
            is_custom: false
        });
    };

    $scope.removePreprocessFunction = function(index) {
        $scope.model.preprocess_functions.splice(index, 1);
    };

    $scope.switchTapDirectLock = function(obj, index) {
        if (!obj[index].lock) {
            obj[index].lock = 1;
        } else {
            obj[index].lock = 0;
        }
    };

    $scope.removeTapDirectKeyPairRow = function(obj, index) {
        obj.splice(index, 1);
    };

    $scope.isFromExternalSample = false;
    $scope.loadExternalSample = function() {
        $scope.isFromExternalSample = true;
        $('#load_external_link').html('');
        $scope.load_external_sample_file = true;

        let model = $scope.model;
        $http.post(
            '/server/api/importwizard/load_external_sample_file',
            {
                model : model
            }
        ).then(function successCallback(json) {
            $scope.load_external_sample_file = false;
            if (json.data.extra) {
                let output = '';
                if (json.data.extra.external_reachable_link !== '') {
                    output += '<a href="'+json.data.extra.external_reachable_link+'" target="_blank">';
                }
                if (json.data.extra.external_virtual_link !== '') {
                    output += json.data.extra.external_virtual_link;
                } else if (json.data.extra.external_reachable_link !== '') {
                    output += json.data.extra.external_reachable_link;
                }
                if (json.data.extra.external_reachable_link !== '') {
                    output += '</a>';
                }
                if (output !== '') {
                    output = '<strong>Sample file loaded: ' + output + '</strong>';
                }
                $('#load_external_link').html(output);
            }
            $scope.uploadSampleDataCallback(json.data.data);
        }, function errorCallback(json) {
            $scope.load_external_sample_file = false;
            $scope.errorCallback(json);
        });
    };

    //
    // Click event to add new data field row in data fields table
    //
    $scope.addDataRow = function() {
        let currentLetterRowIndex = $scope.convertToNumberingScheme($scope.model.fields.length + 1);

        $scope.model.fields.push({
            data_type: 'string',
            is_metric: false,
            include_in_charts: false,
            operation: 'sum',
            is_mapping_field: false,
            is_date_field: false,
            is_unique_field: false,
            is_editable: true,
            heading: currentLetterRowIndex,
            name: 'Column ' + currentLetterRowIndex.toUpperCase(),
            sample: null,
            can_be_deleted: true,
            is_new_field: true,
            is_virtual_mapping_field: false,
            is_display_campaign_name: false,
            is_timezone_field: false,
            is_currency_field: false
        });

        $scope.updateUniqueFields();
    };

    //
    // Increment letters, A, B, ..., X, Y, Z, AA, AB...
    //
    $scope.convertToNumberingScheme = function(number) {
        let baseChar = ("A").charCodeAt(0),
            letters  = "";

        do {
            number -= 1;
            letters = String.fromCharCode(baseChar + (number % 26)) + letters;
            number = (number / 26) >> 0; // quick 'floor'
        } while (number > 0);

        return letters;
    };

    //
    // Click event to remove data field row from data fields table
    //
    $scope.removeDataRow = function(index) {
        if ($scope.model.fields[index].heading === $scope.model.margin_field) {
            $scope.model.margin_field = '';
        }
        if ($scope.model.fields[index].heading === $scope.model.currency_field) {
            $scope.model.currency_field = '';
        }
        if ($scope.model.fields[index].heading === $scope.model.timezone_field) {
            $scope.model.timezone_field = '';
        }
        if (!$scope.model.fields[index].is_in_use_from_child) {
            $scope.model.fields.splice(index, 1);
            $scope.updateUniqueFields();
            return;
        }

        let entityName = $scope.model.fields[index].name;

        swal({
                title: 'Are you sure?',
                text: '<b>' + entityName + '</b> is used in at least one sublevel. Removing this field will also remove it from any sublevel.',
                html: true,
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it',
                cancelButtonText: 'No',
                closeOnConfirm: true,
                closeOnCancel: true
            },
            function(isConfirm) {
                if (isConfirm) {
                    $scope.model.fields.splice(index, 1);
                    $scope.$apply();
                    $scope.updateUniqueFields();
                }
            });
    };

    $scope.updateMappingField = function() {
        let previous_map_single_client = $scope.model.map_single_client;
        $scope.model.map_single_client = ($scope.model.mapping_field === $scope.IMPORT_WIZARD.SC_VIRTUAL_MAPPING);

        // Ensure that Quick Assignment is disabled if SC_VIRTUAL_MAPPING is selected
        if ($scope.model.map_single_client) {
            $scope.model.mapping_module_active = false;
        }

        angular.forEach($scope.model.fields, function(item, ndx) {
            $scope.model.fields[ndx].is_mapping_field = (item.heading === $scope.model.mapping_field);
        });
        if (previous_map_single_client !== $scope.model.map_single_client) {
            $scope.updateSingleClient(true);
        }

        if ($scope.model.auth_type == $scope.IMPORT_AUTH_TYPE.GOOGLE_SHEET) {
            let previous_google_sheet_use_tab_name_for_mapping = $scope.model.google_sheet.use_tab_name_for_mapping;
            $scope.model.google_sheet.use_tab_name_for_mapping = ($scope.model.mapping_field === $scope.IMPORT_WIZARD.SC_TAB_MAPPING);
            if (previous_google_sheet_use_tab_name_for_mapping !== $scope.model.google_sheet.use_tab_name_for_mapping) {
                $scope.updateUseTabName();
            }
        }
        else if ($scope.model.use_tab_parsing) {
            let previous_use_tab_name_for_mapping = $scope.model.tab_parsing_config.use_tab_name_for_mapping;
            $scope.model.tab_parsing_config.use_tab_name_for_mapping = ($scope.model.mapping_field === $scope.IMPORT_WIZARD.SC_TAB_MAPPING);
            if (previous_use_tab_name_for_mapping !== $scope.model.tab_parsing_config.use_tab_name_for_mapping) {
                $scope.updateUseTabName();
            }
        }
    };

    $scope.SQLAutoSuggestionLoading = false;
    $scope.previous_mapping_module_sql = '';
    $scope.doSQLAutoSuggestion = function() {
        $scope.SQLAutoSuggestionLoading = true;
        $scope.previous_mapping_module_sql = $scope.model.mapping_module_sql;
        let model = $scope.model;
        $http.post(
            '/server/api/importwizard/load_sql_auto_suggestion',
            {
                model : model
            }
        ).then(function successCallback(json) {
            $scope.SQLAutoSuggestionLoading = false;
            $scope.model.mapping_module_sql = json.data.data ?? '';
        }, function errorCallback(json) {
            $scope.SQLAutoSuggestionLoading = false;
            // Ignore any error
        });
    }

    /**
     * Any change to the is_metric field affects the include_in_charts field
     */
    $scope.updateIsMetricField = function(index) {
        $scope.model.fields[index].include_in_charts = $scope.model.fields[index].is_metric;
    };

    $scope.switchManual = function() {
        $scope.model.campaign_descriptor_disable_pluralized = !$scope.model.campaign_descriptor_disable_pluralized;
    };

    /**
     * If the cancel button is hit from the footer, call $scope.changeAuthType to show/hide right section
     */
    $('footer #form-cancel').click(function() {
        if (!isEditing) {
            $scope.model.auth_type = null;
            $scope.auth_type_select2 = {};
            $scope.model.conn_type = null;
        } else {
            $scope.model.auth_type = $scope.original_auth_type;
            $scope.auth_type_select2 = $scope.auth_type_mapping[$scope.model.auth_type];
        }
        $scope.changeAuthType();
        $scope.reinitUniqueValues();
        $scope.reinitDisplayCampaignName();
    });

    $('footer #form-submit').click(function(e) {
        if (
            $scope.model.auth_type &&
            !isEditing &&
            !$scope.testConnectionValidated &&
            $scope.model.conn_type !== IMPORT_CONN_TYPE.MANUAL &&
            $scope.model.conn_type !== IMPORT_CONN_TYPE.DYNAMIC
        ) {
            e.stopImmediatePropagation();
            $('.notifyjs-error-base').click();
            if ($scope.model.conn_type === IMPORT_CONN_TYPE.FORM_FIELDS) {
                UIFactory.notify.showError('Please validate your connection by clicking "TEST CONNECTION".');
                return void(0);
            } else if ($scope.model.conn_type === IMPORT_CONN_TYPE.OAUTH) {
                UIFactory.notify.showError('Please "AUTHENTICATE" your delivery type.');
                return void(0);
            }
        } else if ($scope.isSublevel) {
            // Validate that we don't have the same unique field for different inheritance
            let child_headings = $scope.parentFieldsUnique.map(function(item){ return item.child_heading });
            let has_duplicate = child_headings.some(function(item, idx){
                return child_headings.indexOf(item) != idx
            });
            if (has_duplicate) {
                e.stopImmediatePropagation();
                $('.notifyjs-error-base').click();
                UIFactory.notify.showError('Please ensure to have different "Unique Fields" for each "Parent-level Unique Fields".');
                return void(0);
            }
        }

    });

    $scope.has_duplicate_message = false;
    $scope.duplicate_message = '';
    $scope.show_no_duplicate_found = false;
    $scope.show_no_duplicate_found_box = false;
    $scope.test_unique_fields_loading = false;
    $scope.testUniqueFields = function() {
        $scope.has_duplicate_message = false;
        $scope.duplicate_message = '';
        $scope.show_no_duplicate_found = false;
        $scope.show_no_duplicate_found_box = false;
        $scope.test_unique_fields_loading = true;
        $http.post(
            '/server/api/importwizard/test_unique_fields',
            {
                model: $scope.model
            }
        ).then(function successCallback(json) {
            $scope.test_unique_fields_loading = false;
            if (!json.data.data.duplicate_error) {
                $scope.show_no_duplicate_found = true;
                $scope.show_no_duplicate_found_box = true;
            } else {
                $scope.show_no_duplicate_found = false;
                $scope.has_duplicate_message = true;
                $scope.show_no_duplicate_found_box = false;
                $scope.duplicate_message = json.data.data.message;
            }
        }, function errorCallback(json) {
            $scope.test_unique_fields_loading = false;
            $scope.displayError(json);
        });
    };

    /**
     * If the back button is hit, remove error messages which are persistent, if there is any
     */
    $('.main-content-title a').click(function() {
        $('.notifyjs-error-base').click();
    });
}

/**
 * Import Wizard Manual Upload controller
 * @ngInject
 * @param service
 * @param awsBackupFiles
 * @param views
 * @param AppFactory
 */
function ImportWizardManualUploadController(
    $rootScope,
    $scope,
    $stateParams,
    service,
    awsBackupFiles,
    views,
    AppFactory
) {
    $rootScope.redirector.link = AppFactory.getMdsRoute();
    $rootScope.redirector.param.key = 'service_id';

    $scope.dataViewsSelectOptions = {
        minimumResultsForSearch: -1,
        data: views,
        width: '50%',
        placeholder: 'Select a view',
    };

    $scope.tooltip = {};
    $scope.placeholder = {};
    $scope.model = $.extend({}, service);
    $scope.model.select2_id = $stateParams.id;
    $scope.views = views;

    $scope.$watch('model.select2_id', function(nV, oV) {
        $scope.model.hasSuccessData = false;
        if (nV && nV.id) {
            $scope.model.id = nV.id;
            let uploadUrl = '/server/api/importwizard/import_manual_upload_data/' + $scope.model.id;
            $('#manual_upload_data_file').fileinput('refresh', {uploadUrl: uploadUrl});

            for (let i = 0; i < $scope.views.length; i++) {
                if ($scope.views[i].id === $scope.model.id) {
                    $scope.entity.isMainLevel = ($scope.views[i].parent_id == null);
                }
            }
        }
    });

    $scope.entity = {
        nameKey: 'name',
        idKey: 'id',
        pageTitle: 'Manual File Upload for ' + $scope.model.name,
        action: 'importwizard.manualupload',
        redrawServiceNavOnSave: true,
        isMainLevel: ($scope.model.parent_id == null)
    };

    $scope.errorManualUploadCallback = errorManualUploadCallback;
    $scope.successManualUploadCallback = successManualUploadCallback;

    new ImportWizardManuallyUploadedFilesController($scope, awsBackupFiles);

    /**
     * Callback from the uploadFileInput directive when it fails.
     *
     * @param json
     */
    function errorManualUploadCallback(json) {
        // populate the error message returned from the server, if provided
        if (json.jqXHR && json.jqXHR.responseJSON.data) {
            // The error is displayed in a popup,
            $scope.$evalAsync(function() {
                $scope.model.hasSuccessData = false;
                $scope.model.hasWarningData = false;
                $scope.model.hasErrorData = false;
            });
        } else if (json.response && json.response.error) {
            // To stay back compatible, could be obsolete
            $scope.manualUploadFailMessage = json.response.error;
            // trigger showing/hiding of messaging
            $scope.$evalAsync(function() {
                $scope.model.hasSuccessData = false;
                $scope.model.hasWarningData = false;
                $scope.model.hasErrorData = true;
            });
        } else {
            // could happen with a server error, ie type 500
            $scope.manualUploadFailMessage = 'Uh-oh! Looks like your manual upload failed to load because of an error on our side. Please contact your account manager if the problem persists.';
            // trigger showing/hiding of messaging
            $scope.$evalAsync(function() {
                $scope.model.hasSuccessData = false;
                $scope.model.hasWarningData = false;
                $scope.model.hasErrorData = true;
            });
        }
    }

    /**
     * Callback from the uploadFileInput directive when it succeeds.
     *
     * @param json
     */
    function successManualUploadCallback(json) {
        let hasWarning = false;

        if (json.warning) {
            $('#manualUploadWarningMessage').html(json.warning);
            hasWarning = true;
        } else {
            // Refresh the manually uploaded files section
            $.core.datatable.oTables.smart_connector_files.oTable.fnAddData(json, true);
            $scope.hasAtLeastOneFile = true;
        }

        // trigger showing/hiding of messaging
        $scope.$evalAsync(function() {
            $scope.model.hasErrorData = false;
            $scope.model.hasSuccessData = true;
            $scope.model.hasWarningData = hasWarning;
        });
    }
}

/**
 * Import Wizard Delete Data controller
 * @ngInject
 * @param service
 * @param awsBackupFiles
 * @param AppFactory
 * @constructor
 */
function ImportWizardDeleteDataController(
    $rootScope,
    $scope,
    $stateParams,
    service,
    awsBackupFiles,
    AppFactory
) {
    $rootScope.redirector.link = AppFactory.getMdsRoute();
    $rootScope.redirector.param.key = 'service_id';

    $scope.tooltip = {};
    $scope.placeholder = {};

    $scope.model = {};
    $scope.entity = {
        nameKey: 'name',
        idKey: 'id',
        pageTitle: 'Delete Data',
        action: 'importwizard.deletedata',
        redrawServiceNavOnSave: true
    };

    // populate model data
    $.extend($scope.model, service);

    new ImportWizardManuallyUploadedFilesController($scope, awsBackupFiles);

    let brandMappings = AppFactory.getBrandMappings();

    // update page title
    $scope.entity.pageTitle = 'Delete All ' + brandMappings.campaign + ' Data for ' + $scope.model.name;

    $scope.delete_files = true;

    $scope.deleteDataDisabled = !$scope.model.has_data;
    $scope.deleteAssignedMappingDisabled = !$scope.model.has_mapping;

    $scope.deleteAllData = deleteAllData;
    $scope.deleteAllAssignedMappings = deleteAllAssignedMappings;

    function deleteAllData() {
        $scope.deleteDataDisabled = true;
        if ($scope.model.delete_mappings) {
            $scope.deleteAssignedMappingDisabled = true;
        }
        $scope.model.keep_manually_uploaded_file = !$scope.delete_files;

        $.post('/server/api/importwizard/remove_data', {
            private_integration_id : service.id,
            delete_mappings : $scope.model.delete_mappings,
            keep_manually_uploaded_file: $scope.model.keep_manually_uploaded_file
        }).success(function(json) {
            if (json.status === 200) {
                if ($scope.model.delete_mappings) {
                    $scope.model.has_mapping = false;
                }

                $scope.$evalAsync(function() {
                    $scope.model.dataDeleteSuccess = true;
                    $scope.model.dataDeleteError = false;
                });

                if (!$scope.model.keep_manually_uploaded_file) {
                    $scope.has_manual_uploads = false;
                }

                // removing the mapping cache so we don't have to reload them
                amplify.removeFromCache('account_list_' + service.id);
            } else {
                $scope.$evalAsync(function() {
                    $scope.deleteDataDisabled = false;
                    $scope.model.dataDeleteSuccess = false;
                    $scope.model.dataDeleteError = true;
                });
            }
        });
    }

    function deleteAllAssignedMappings() {
        $scope.deleteAssignedMappingsDisabled = true;

        $.post('/server/api/importwizard/delete_mappings', {
            service_id : service.service_id
        }).success(function (json) {
            if (json.status === 200) {
                $scope.$evalAsync(function() {
                    $scope.model.mappingsDeleteSuccess = true;
                    $scope.model.mappingsDeleteError = false;
                });

                // removing the mapping cache so we don't have to reload them
                amplify.removeFromCache('account_list_' + service.service_id);
            } else {
                $scope.$evalAsync(function() {
                    $scope.deleteAssignedMappingsDisabled = false;
                    $scope.model.mappingsDeleteSuccess = false;
                    $scope.model.mappingsDeleteError = true;
                });
            }
        });
    }
}

/**
 * Import Wizard Manually Uploaded Files grid controller
 * @param awsBackupFiles
 * @constructor
 */
function ImportWizardManuallyUploadedFilesController(
    $scope,
    awsBackupFiles
) {
    $scope.has_manual_uploads = true;

    $scope.smartConnectorFiles = {};
    $scope.smartConnectorFiles.data = awsBackupFiles;
    $scope.hasAtLeastOneFile = $scope.smartConnectorFiles.data.length >= 1;

    $scope.smartConnectorFiles.columns = [
        {
            field: 'original_name',
            label: 'Original File Name',
            sortable: true
        },
        {
            field: 'campaign_descriptor',
            label: 'Dataview',
            sortable: true
        },
        {
            field: 'uploaded_date',
            label: 'Date Uploaded',
            sortable: true,
        },
        {
            field: 'file_size',
            label: 'File Size (bytes)',
            sortable: true,
        },
        {
            field: 'id',
            label: 'Action',
            sortable: false,
            is_primary_key: true
        },
    ];
    $scope.smartConnectorFiles.entityName = 'uploaded file';
    $scope.smartConnectorFiles.itemName = 'uploaded file';
    $scope.smartConnectorFiles.iActions = 1;

    $scope.smartConnectorFiles.customRenders = {};
    $scope.smartConnectorFiles.customRenders['id'] = function(data, type, full) {
        let btnDelete,
            btnDownload;

        btnDelete = isNUI ? '' : '<a data-url="/server/api/importwizard/delete_import_manual_file/' + full.id +
            '" data-is-angular="false" data-name="' +
            full.original_name + ' (' + full.uploaded_date + ')' +
            '" class="action action-red delete-action has-tooltip" data-original-title="Delete">' +
            '<span class="icon icon-trash"></span></a>';

        btnDownload = '<a href="/server/api/importwizard/download/'+full.id+'" class="action has-tooltip" data-original-title="Download the CSV file"><span class="icon icon-download"></span></a>';

        return btnDownload + btnDelete;
    };
}
