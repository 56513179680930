import { getters } from '@/modules/core/app/helpers/store';
import { color, GrainPattern, percent, Tooltip } from '@amcharts/amcharts5';
import { FunnelSeries, PyramidSeries, PictorialStackedSeries } from '@amcharts/amcharts5/percent';
import { useGradient } from '@/modules/core/charts/am5/base/composables/fills/useGradient';
import { colorGenerator } from '@/modules/core/charts/am5/base/composables/fills/colorGenerator';
import { useDrillDownEvents } from '@/modules/core/charts/am5/base/composables/events/useDrillDownEvents.js';
import { useLabels } from '@/modules/core/charts/am5/base/composables/series/useLabels';
import { WidgetBackgroundGradientColorOptions } from '@/modules/ta/widget/widget.constants';
import { SortOrder } from '@/modules/core/app/constants/app.constants';
import {
  Constant,
  FUNNEL_TOO_MANY_LABELS,
  MAX_LABEL_COUNT,
  PictorialSvg,
  PieChartConstant,
} from '@/modules/core/charts/am5/charts.constants';
import { SeriesType } from '@/modules/core/charts/chart.constants';
import { isEmpty } from 'lodash';
import { useTooltipAdjustments } from '@/modules/core/charts/am5/funnel/composables/useTooltipAdjustments';
import { useNoticeIcon } from '@/modules/core/charts/am5/base/composables/useNoticeIcon';
import { useToolTips } from '@/modules/core/charts/am5/pie/composables/useTooltips';

export function useSliceSeries(context) {
  const { root, chart, config, isDarkTheme } = context();
  const { createLinearGradient, createPictorialGradient } = useGradient(context);
  const { generateColorWithPaletteOverflow, darken } = colorGenerator();
  const { changeChartDrillDown } = useDrillDownEvents();
  const { handleLabels, truncateLabel } = useLabels(context);
  const { checkForTooNarrowSlices } = useTooltipAdjustments(context);
  const { createNoticeIcon } = useNoticeIcon(context);
  const { labelHover, assignThemeColor } = useToolTips(context);
  let sortedData = [];

  /**
   * Grab a sort function that depends on the direction we're facing
   * @param sortOrder
   * @param sortValue
   * @returns {{(*, *): *, (*, *): *}}
   */
  function getSortFn(sortOrder = SortOrder.DESC, sortValue = Constant.VALUE) {
    return sortOrder === SortOrder.DESC
      ? (a, b) => b[sortValue] - a[sortValue]
      : (a, b) => a[sortValue] - b[sortValue];
  }

  /**
   * Depending on whether the pyramid / funnel is rotated or not means sort asc/desc
   * @param values
   * @returns {*}
   */
  function sortSlices(values) {
    if (config.value.isPyramidSeries() && !config.value.orderMetrics) {
      return !config.value.invertMetrics ? [...values] : values.reverse();
    }

    const sortValue = config.value.isNormalized ? Constant.RAW_VALUE : Constant.VALUE;
    let sortDirection = SortOrder.DESC;

    // funnels need to have the values low->high to appear re-ordered
    // pyramids will just build the height of each slice based on what we give it
    if (config.value.isFunnelSeries()) {
      sortDirection = config.value.isRotated ? SortOrder.ASC : SortOrder.DESC;
    } else if (config.value.invertMetrics) {
      // pyramids have the option to invert the metrics from the default "large on top"
      sortDirection = SortOrder.ASC;
    }

    values.sort(getSortFn(sortDirection, sortValue));

    return values;
  }

  /**
   * Generate a series based on the plot type
   * @param settings
   * @returns {InstanceType<typeof Entity>}
   */
  function seriesFactory(settings) {
    const seriesType = getSeriesType();
    return seriesType ? seriesType.new(root.value, settings) : null;
  }

  function getSeriesType() {
    if (config.value.isPyramidSeries()) {
      return PyramidSeries;
    }
    if (config.value.isFunnelSeries()) {
      return FunnelSeries;
    }
    if (config.value.isPictorialSeries()) {
      return PictorialStackedSeries;
    }

    return null;
  }

  /**
   * Generate the series required.  If it's a comparison we'll have two
   * @returns {*[]}
   */
  function createSliceSeries(isComparison = false) {
    // bottomRatio = 1 means that the bottom of each slice joins the top of the next
    const bottomRatio = config.value.smoothLines ? 1 : 0;
    const { isNormalized, showLabelPercent, showLabelNames, showLabelValues } = config.value;
    const fontColorPicker = assignThemeColor(isDarkTheme, config.value);
    const legendLabelText = `[${fontColorPicker}]${PieChartConstant.LEGEND_LABEL_TEXT}`;

    const defaultSettings = {
      name: Constant.SERIES_U,
      categoryField: Constant.CATEGORY,
      valueField: Constant.VALUE,
      orientation: Constant.VERTICAL,
      alignLabels: true,
      bottomRatio,
      ignoreZeroValues: true,
      fillField: Constant.FILL,
      legendLabelText,
    };

    // add additional settings here as required due to options
    const additionalSettings = {};

    // we need a base tooltip item to then be able to format it later
    if (config.value.hasTooltip) {
      additionalSettings.tooltip = Tooltip.new(root.value, {
        autoTextColor: false,
        getFillFromSprite: true,
        getLabelFillFromSprite: true,
      });
    }

    if (config.value.isRotated) {
      additionalSettings.topWidth = percent(100);
      additionalSettings.bottomWidth = 0;
    }

    if (isNormalized) {
      additionalSettings.valueIs = Constant.HEIGHT;
    }

    if (config.value.plotType === SeriesType.PICTORIAL) {
      const pictorialOptions = config.value.pictorialOptions.toUpperCase();
      additionalSettings.svgPath = PictorialSvg[pictorialOptions];
    }

    const settings = {
      ...defaultSettings,
      ...additionalSettings,
    };

    const series = chart.value.series.push(seriesFactory(settings));
    series.slices.template.set(Constant.TEMPLATE_FIELD, Constant.COLUMN_SETTINGS);

    if (config.value.plotType === SeriesType.PICTORIAL) {
      series.slices.template.set(Constant.TOOLTIP_TEXT, '');
    }

    applyStates(series, isComparison);

    // links are the parts of a funnel that connect each slice
    const linkHeight = config.value.isFunnelSeries() ? 5 : 0;
    const linkSettings = {
      height: linkHeight,
    };

    // smooth lines removes the links
    if (config.value.smoothLines) {
      linkSettings.height = 0;
      linkSettings.forceHidden = true;
    }
    if (isComparison) {
      linkSettings.opacity = 0.3;
    }
    const shouldHideTicks =
      config.value.plotType === SeriesType.PICTORIAL &&
      isNormalized &&
      showLabelPercent &&
      !showLabelValues &&
      !showLabelNames;

    if (shouldHideTicks) {
      series.ticks.template.set(Constant.FORCE_HIDDEN, true);
    }
    series.links.template.setAll(linkSettings);

    // neck is only computed if both are selected
    if (config.value.neckWidth && config.value.neckHeight) {
      applyNeck(series);
    }

    if (config.value.isFunnelSeries() && config.value.hasTooltip) {
      checkForTooNarrowSlices(series);
    }

    // rp-2915 funnels can't really handle labels so if there's more than 20 slices disable them
    if (config.value.isFunnelSeries()) {
      const dataLength = isComparison
        ? config.value.comparisonData.length
        : config.value.data.length;
      if (dataLength > 20) {
        config.value.showLabels = false;
        createLabelHelpTooltip();
      }
    }

    handleLabels(series);
    // implement label hover tooltip
    labelHover(series, config.value.series[0]);
    return series;
  }

  /**
   * We've removed all the labels so we need to leave something behind to tell people what's happened
   */
  function createLabelHelpTooltip() {
    const userData = chart.value.get(Constant.USER_DATA, {});
    if (userData.TOOLTIP_ADDED || config.value.isExporting) {
      return;
    }

    const label = createNoticeIcon(FUNNEL_TOO_MANY_LABELS, config.value.canIncludeInteractivity());

    if (config.value.isRotated) {
      chart.value.children.unshift(label);
    } else {
      chart.value.children.push(label);
    }

    // only one tooltip as we're just pushing children
    userData.TOOLTIP_ADDED = true;
    chart.value.set(Constant.USER_DATA, userData);
  }

  /**
   * Define our default states so we can handle what happens after mouse over
   * @param series
   * @param isComparison
   */
  function applyStates(series, isComparison = false) {
    series.slices.template.states.create(Constant.DEFAULT, {
      opacity: isComparison ? 0.3 : 1,
    });
    series.slices.template.states.create(Constant.HOVER, {
      opacity: 1,
    });
    series.slices.template.states.create(Constant.DISABLED, {
      opacity: 0.3,
    });
  }

  /**
   * Adjust the bottom of the funnel to an assigned width up to the assigned height
   * If rotated - do the same for the top of the funnel
   * @param series
   */
  function applyNeck(series) {
    const { neckWidth, neckHeight } = config.value;
    const targets = {};
    const neckTargetWidth = config.value.isRotated ? Constant.TOP_WIDTH : Constant.BOTTOM_WIDTH;
    const neckOtherTargetWidth = config.value.isRotated
      ? Constant.BOTTOM_WIDTH
      : Constant.TOP_WIDTH;
    let cutOff = 0;
    let chartHeight = 0;

    // can only get the height once the data has been validated and the slices created
    series.events.on(Constant.DATA_VALIDATED, () => {
      series.dataItems.forEach((dataItem) => {
        const slice = dataItem.get(Constant.SLICE);
        chartHeight += slice.get(Constant.HEIGHT);
      });
    });

    // when we're not smoothing lines then the "links" also need their widths re-calculating
    if (!config.value.smoothLines) {
      series.links.template?.adapters.add(Constant.HEIGHT, (height, target) => {
        if (!target || !chartHeight) {
          return height;
        }

        if (cutOff === 0) {
          cutOff = calculateCutOff(chartHeight, neckHeight);
        }

        let shouldShrinkNeck = config.value.isRotated
          ? target.get(Constant.Y) <= cutOff
          : target.get(Constant.Y) >= cutOff;

        // also adjust the neck if it's below the neck value so we don't get hourglass shapes
        if (target.get(Constant.TOP_WIDTH) < neckWidth) {
          shouldShrinkNeck = true;
        }

        if (shouldShrinkNeck) {
          target.set(Constant.TOP_WIDTH, neckWidth);
          target.set(Constant.BOTTOM_WIDTH, neckWidth);
        }

        return height;
      });
    }

    // once attached the adapter will run whenever the slices are interacted with
    // so this runs during creation, setting templates and adding data.  once data
    // is added and we have a height is when we can then start messing with the width
    series.slices.template?.adapters.add(neckTargetWidth, (widthElement, target) => {
      // we can only start working things out once we've got a target and the
      // slices have been drawn (post datavalidated, etc)
      if (!target || !chartHeight) {
        return widthElement;
      }

      // build quick map of each slice so we can target them later
      const uid = `${target.uid}`;
      if (!targets[uid]) {
        targets[uid] = target;
      }

      if (cutOff === 0) {
        cutOff = calculateCutOff(chartHeight, neckHeight);
      }

      const sliceYValue = target.get(Constant.Y);

      let shouldShrinkNeck = config.value.isRotated ? sliceYValue <= cutOff : sliceYValue >= cutOff;

      // also adjust the neck if it's below the neck value so we don't get hourglass shapes
      const aboveCutOff = config.value.isRotated ? sliceYValue >= cutOff : sliceYValue <= cutOff;

      if (aboveCutOff && widthElement < neckWidth) {
        shouldShrinkNeck = true;
      }

      if (shouldShrinkNeck) {
        if (config.value.smoothLines) {
          // also get the previous element and make the width to the neck height
          const otherTarget = getOtherTarget(uid, targets);
          otherTarget.set(neckTargetWidth, neckWidth);
        }

        target.set(neckOtherTargetWidth, neckWidth);
        return neckWidth;
      }

      return widthElement;
    });
  }

  /**
   * The neck height runs from 0-500
   * The chart y values where each slice is drawn run from 0-~311
   * We need to work out what percentage up the max scale the neck height is
   * and then apply that to the chart height to get our cut-off value
   * If the chart has been rotated then we need to work our way down instead
   * @param chartHeight
   * @param neckHeight
   * @param maxNeckHeight
   * @returns {number}
   */
  function calculateCutOff(chartHeight, neckHeight, maxNeckHeight = 500) {
    const neckHeightPercent = neckHeight / maxNeckHeight;
    const neckAmount = chartHeight * neckHeightPercent;

    if (config.value.isRotated) {
      return neckAmount;
    }
    return chartHeight - neckAmount;
  }

  /**
   * Targets are keyed based on their UID.  So grab the next / previous (dependent on rotation)
   * to the one we supply using the keys and their indexOf
   * @param uid
   * @param targets
   * @returns {*}
   */
  function getOtherTarget(uid, targets) {
    const keys = Object.keys(targets);
    const currentIndex = keys.indexOf(uid);

    if (config.value.isRotated) {
      const testIndex = currentIndex + 1;
      if (targets[keys[testIndex]]) {
        return targets[keys[testIndex]];
      }

      return targets[keys[keys.length - 1]];
    }

    if (currentIndex > 0) {
      return targets[keys[currentIndex - 1]];
    }

    return targets[keys[0]];
  }

  /**
   * Get data and save the sort
   * @param isComparison
   * @returns {*|(function(): *)}
   */
  function getData(isComparison = false) {
    const data = isComparison ? config.value.comparisonData : config.value.data;
    const sortDirection =
      config.value.isFunnelSeries() && config.value.isRotated ? SortOrder.ASC : SortOrder.DESC;
    // could use toSorted here but unsure about coverage for older browsers
    const originalData = [...data];
    sortedData = data.sort(getSortFn(sortDirection));

    return originalData;
  }

  /**
   * Takes the array of series data and feeds it into the generated series
   */
  function insertSeriesData(series, isComparison = false) {
    config.value.data.forEach((datum, index) => {
      datum.dataFormat = getDataItemFormat(index);
    });
    if (isComparison) {
      config.value.comparisonData.forEach((datum, index) => {
        datum.dataFormat = getDataItemFormat(index, isComparison);
      });
    }

    const isComparisonEnabled = !!config.value.comparisonData;
    const data = getData(isComparison);
    const seriesData = sortSlices(data);
    const formattedSeries = [];
    const { id } = config.value.get(Constant.WIDGET);
    const widgetMetadata = config.value.get(Constant.WIDGET).metadata;
    const hasGroupBy = widgetMetadata.data_columns.grouped.length;
    const hasSingleMetric = widgetMetadata.data_columns.selected.length === 1;
    let groupByField = hasGroupBy ? widgetMetadata.data_columns.grouped[0].groupby_id_field : null;
    const singleMetricNoGroupBy =
      hasSingleMetric && !hasGroupBy ? widgetMetadata.data_columns.selected[0].label : null;

    const metricNames = [];
    const widgetConfig = getters.dashboardDrilldown.getWidgetConfig(id);
    if (!isEmpty(widgetConfig) && widgetConfig.drilldownConfigStack.length !== 0) {
      const lastConfig =
        widgetConfig.drilldownConfigStack[widgetConfig.drilldownConfigStack.length - 1];
      groupByField = hasGroupBy
        ? widgetMetadata.data_columns.grouped[lastConfig.groupByIndex].groupby_id_field
        : null;
      widgetConfig.drilldownConfigStack.forEach((drillConfig) => {
        if (
          drillConfig.isSubSlice === false &&
          lastConfig.groupByIndex === drillConfig.groupByIndex
        ) {
          const [value] = Object.values(drillConfig.queryParams);
          metricNames.push(value);
        }
      });
    }

    seriesData.forEach((slice, index) => {
      if (!slice.value) {
        return;
      }

      // colours are in the chart palette assuming default sorting
      // if we've overwritten the sort order then we can't go off the slice order but instead
      // need to use the original index which is part of the slice data
      if (metricNames.includes(slice.metric)) {
        if (!isComparisonEnabled) {
          slice.subs.forEach((subSlice, subSliceIndex) => {
            formattedSeries.push(
              generateSeriesInfo(
                subSlice,
                subSliceIndex,
                seriesData.length,
                singleMetricNoGroupBy,
                groupByField,
                isComparison
              )
            );
          });
        }
      } else {
        formattedSeries.push(
          generateSeriesInfo(
            slice,
            index,
            seriesData.length,
            singleMetricNoGroupBy,
            groupByField,
            isComparison
          )
        );
      }
    });

    series.data.setAll(formattedSeries);
    series.set(Constant.USER_DATA, formattedSeries);
  }

  /**
   * Generate the series container from either the subslice or the slice
   * @param slice
   * @param index
   * @param seriesLength
   * @param singleMetricNoGroupBy
   * @param groupByField
   * @param isComparison
   * @returns {{dataItemFormat: *, "[Constant.CATEGORY_FIELD]", rawMetric, rawValue, tooltipText: *, index, isSubSlice: boolean, fill: Color, category, value: (number|*), columnSettings: {stroke: Color}}}
   */
  function generateSeriesInfo(
    slice,
    index,
    seriesLength,
    singleMetricNoGroupBy,
    groupByField,
    isComparison
  ) {
    const { fillColor, fillElement } = getFillColorForElement(slice.index, isComparison);
    const columnSettings = {
      ...fillElement,
    };

    // we need to preserve the tooltip text so it appears in full and is only truncated in labels
    const labelText = singleMetricNoGroupBy || slice.metric;
    const category = truncateLabel(labelText);

    const seriesInfo = {
      fill: color(fillColor),
      rawValue: slice.rawValue,
      value: config.value.isNormalized
        ? calculateNormalizedSlice(index, seriesLength)
        : slice.value,
      isSubSlice: slice.subs && slice.subs.length === 0,
      dataItemFormat: slice.dataFormat,
      rawMetric: slice.rawMetric,
      [Constant.CATEGORY_FIELD]: groupByField,
      tooltipText: labelText,
      category,
      index: slice.index,
      columnSettings,
    };

    // when there's way too many labels to show we need to make sure that the ones we do show
    // are relevant -- highest values, etc
    if (seriesLength > MAX_LABEL_COUNT) {
      seriesInfo.overrideLabelShow = shouldShowLabel(seriesInfo);
    }

    return seriesInfo;
  }

  /**
   * Pyramid is easy - the intended effect is all the heights being the same
   * so we just divide the length by 100 so each slice is an equal percentage
   *
   * For funnel, we'll need to step down (or up if it's rotated) in equal chunks
   * @param position
   * @param length
   * @returns {number}
   */
  function calculateNormalizedSlice(position, length) {
    if (config.value.isPyramidSeries() || config.value.isPictorialSeries()) {
      return length / 100;
    }

    const stepAmount = length / 100;
    if (!config.value.isRotated) {
      position = length - 1 - position;
    }

    if (position === 0) {
      position = 0.2;
    }

    return position * stepAmount;
  }

  /**
   * Whether we override the standard label display with something that's been pre-worked out
   * @param seriesInfo
   */
  function shouldShowLabel(seriesInfo) {
    const { index } = seriesInfo;
    const sliceFromSortedIndex = sortedData.findIndex((slice) => slice.index === index);

    if (
      (config.value.isFunnelSeries() && config.value.isRotated) ||
      (config.value.isPyramidSeries() && config.value.invertMetrics)
    ) {
      return sliceFromSortedIndex >= sortedData.length - MAX_LABEL_COUNT;
    }

    return sliceFromSortedIndex < MAX_LABEL_COUNT;
  }

  /**
   * Pulls the data format from the list for the slice
   * @param index
   * @param isComparison
   * @returns {*}
   */
  function getDataItemFormat(index, isComparison = false) {
    const target = isComparison ? config.value.comparisonDataFormats : config.value.dataFormats;
    if (target.length === 1) {
      return target[0];
    }

    return target[index];
  }

  /**
   * Pulls a color from the palette and either returns it whole
   * or if the plot type is gradient returns a preformatted gradient
   * @param index
   * @param isComparison
   * @returns {{fillElement: {stroke: Color}, color: *}}
   */
  function getFillColorForElement(index, isComparison = false) {
    const fillColor = getFillColor(index);
    const base = {
      stroke: color(darken(fillColor, 10)),
      strokeWidth: 0.1,
    };

    if (isComparison) {
      base.opacity = 0.3;
    }

    let fillElement;

    if (config.value.fillType !== WidgetBackgroundGradientColorOptions.SOLID) {
      const gradientStops = [fillColor, config.value.gradientColor];

      // repeated fillColor for horizontal gradient so the chosen color is in the middle
      if (config.value.fillType === WidgetBackgroundGradientColorOptions.LINEAR) {
        gradientStops.push(fillColor);
      }

      let fillGradient;
      if (config.value.plotType === SeriesType.PICTORIAL) {
        fillGradient = createPictorialGradient(
          config.value.gradientColor,
          config.value.fillType === WidgetBackgroundGradientColorOptions.LINEAR_Y ? 0 : 90
        );
      } else {
        fillGradient = createLinearGradient(
          gradientStops,
          config.value.fillType === WidgetBackgroundGradientColorOptions.LINEAR_Y ? 90 : 0
        );
      }

      fillElement = {
        fillGradient,
      };
    } else {
      fillElement = {
        fill: color(fillColor),
      };
    }

    if (config.value.grainDensity) {
      fillElement.fillPattern = GrainPattern.new(root.value, {
        density: config.value.grainDensity / 100,
      });
    }

    return {
      fillColor,
      fillElement: {
        ...base,
        ...fillElement,
      },
    };
  }

  /**
   * On click, we perform the drill-down
   * If we're here we've pre-decided that we can drill down so no need for ifs here
   */
  function handleClickEvents(id) {
    chart.value.series.each((series) => {
      series.slices.template.setAll({
        cursorOverStyle: Constant.POINTER,
      });

      // slice charts need the click event on a per-slice case instead of per-series
      series.slices.each((slice) => {
        slice.events.on(Constant.CLICK, (ev) => {
          changeChartDrillDown(ev, id);
        });
      });
    });
  }

  /**
   * Trampoline through to the color generator
   * @param index
   * @returns {*}
   */
  function getFillColor(index) {
    return generateColorWithPaletteOverflow(config.value.chartPalette, index);
  }

  return {
    createSliceSeries,
    handleClickEvents,
    insertSeriesData,
  };
}
