import { Label, percent, array } from '@amcharts/amcharts5';
import {
  BackgroundGradientType,
  Constant,
  PieChartConstant,
} from '@/modules/core/charts/am5/charts.constants';
import { omitNils } from '@/modules/core/app/utils/ObjectUtil';
import { PieSeries } from '@amcharts/amcharts5/percent';
import { useSliceLegendEvents } from '@/modules/core/charts/am5/pie/composables/useSliceLegendEvents';
import { usePieSliceHover } from '@/modules/core/charts/am5/pie/composables/usePieSliceHover';
import { useLabels } from '@/modules/core/charts/am5/base/composables/series/useLabels';
import { getPieChartConfig } from '@/modules/core/charts/am5/pie/config/pieConfig';
import { useGradient } from '@/modules/core/charts/am5/base/composables/fills/useGradient';
import { color } from '@/modules/core/charts/am5/charts.helper';
import { useDrillDown } from '@/modules/core/charts/am5/pie/composables/useDrillDown';
import { useToolTips } from '@/modules/core/charts/am5/pie/composables/useTooltips';
import { useLegend } from '@/modules/core/charts/am5/base/composables/useLegend';
import { isNil } from 'lodash';

export function usePieSeries(context) {
  const { root, chart, config, isDarkTheme } = context();
  const { addEvents, normalizedSum } = useSliceLegendEvents(context);
  const { hideLabels } = useLabels(context);
  const { addSliceEvents } = usePieSliceHover();
  const { callFromSeries, setActualData } = useDrillDown(context);
  const { applyLabelFormatWithCurrency, labelHover, assignThemeColor } = useToolTips(context);
  const { formatLegendItems, generateInitialLegend } = useLegend(context);

  function createAllSeries() {
    config.value.series.forEach((props) => {
      createSeries(props);
    });
  }

  function createSeries(props) {
    const { labelComparisonProps } = getPieChartConfig(config.value);
    const { pieRoundedCorners, shadowOptions } = getPieChartConfig(config.value);
    const {
      gradientType,
      showBorders,
      showDonutSum,
      isNormalized,
      pieGradientOptions,
      plotType,
      pieVariableRadius,
    } = config.value;
    const { createPieRadialGradient, createGrainPattern, createPieLinearGradient } =
      useGradient(context);
    let pieGlobalGradientType = gradientType;
    if (plotType === PieChartConstant.PLOT_V2_PIE) pieGlobalGradientType = pieGradientOptions;
    const fontColorPicker = assignThemeColor(isDarkTheme, config.value);
    const legendLabelText = `[${fontColorPicker}]${PieChartConstant.LEGEND_LABEL_TEXT}`;
    const legendValueText = isNormalized
      ? `[${fontColorPicker}]${PieChartConstant.LEGEND_RAW_VALUE_TEXT}`
      : `[${fontColorPicker}]${PieChartConstant.LEGEND_VALUE_TEXT}`;

    /* Instantiating pie series  */
    const series = chart.value.series.push(
      PieSeries.new(root.value, {
        name: PieChartConstant.SERIES,
        valueField: props.value,
        categoryField: props.category,
        startAngle: config.value.startAngle,
        endAngle: config.value.endAngle,
        ...getAlignLabelsProps(props.alignLabels),
        legendLabelText,
        legendValueText,
      })
    );
    /* Gradients logics */
    let fillGradient = {};
    if (
      pieGlobalGradientType === BackgroundGradientType.LINEAR_GRADIENT ||
      pieGlobalGradientType === BackgroundGradientType.RADIAL_GRADIENT
    ) {
      fillGradient =
        pieGlobalGradientType === BackgroundGradientType.LINEAR_GRADIENT
          ? createPieLinearGradient()
          : createPieRadialGradient();
      series.slices.template.setAll({
        fillGradient,
      });
    }
    if (pieVariableRadius) setVariableRadiusForSlices(series);
    /* Setting Up various options */
    series.slices.template.setAll({
      templateField: PieChartConstant.LEGEND_TEMPLATE,
      strokeWidth: 3,
      stroke: showBorders ? color(0xffffff) : '',
      fillPattern: config.value.showGrainyPattern ? createGrainPattern() : {},
      ...getToolTipTextProps(props),
      ...getComparisonOpacityProps(),
      ...pieRoundedCorners,
      ...shadowOptions,
    });
    /* Setting up Series Labels */
    series.labels.template.setAll({
      ...getShowLabelProps(props.alignLabels),
      text: props.labelText,
      oversizedBehavior: Constant.TRUNCATE,
      ...calculateLabelMaxWidth(config.value.widgetData.width, config.value.hasComparisonStatus),
      ellipsis: '...',
    });
    // remove label ticks that small line while its disabled
    if (!props.alignLabels) series.ticks.template.set(Constant.VISIBLE, false);
    /* Creating Title using label if it is comparison enabled */
    if (config.value.hasComparisonStatus) createLabel(labelComparisonProps);
    /* Setting up appropriate data set whether it is current or prior period */
    const dataToSet = setActualData();
    /* Sum Overall count to show in the pie */
    if (showDonutSum && !isNormalized) donutSum(series);
    /* Hover label to show tooltip */
    labelHover(series, props);
    /* This function is used for Hide label percentage */
    hideLabels(true);
    // apply label format to the series
    applyLabelFormatWithCurrency(series);
    series.data.setAll(dataToSet);
    const legend = createLegend(props);
    // donut sum for normalized data
    if (showDonutSum && isNormalized) normalizedSum(series, legend);
    // drill down functionality
    callFromSeries(legend);
    // hide zero values in pie chart and create legend
    hideZeroValues(series);

    if (config.value.legend.active) {
      /* Creating Legend and filtering zero values */
      array.each(series.dataItems, (dataItem) => {
        if (dataItem.get(Constant.VALUE) !== 0) {
          legend.data.push(dataItem);
        }
      });
      // apply formatting to legend
      formatLegendItems(legend);
      /* hover reduce opacity on legend */
      addSliceEvents(series, legend);
      /* hover reduce opacity on slices */
      addEvents(legend);
    }
    return series;
  }

  // hide zero values in pie chart and create legend
  function hideZeroValues(series) {
    series.events.on(Constant.DATA_VALIDATED, () => {
      array.each(series.dataItems, (dataItem) => {
        if (
          dataItem.get(Constant.VALUE) === 0 ||
          dataItem.get(Constant.CATEGORY).indexOf(Constant.UNDEFINED) === 0
        ) {
          dataItem.hide();
        }
      });
    });
  }
  function getShowLabelProps(labelVal) {
    return omitNils({
      forceHidden: !labelVal,
    });
  }
  function getAlignLabelsProps(labelVal) {
    const { alignLabels } = config.value;
    const labelAlignment = !isNil(alignLabels) ? !alignLabels : labelVal;
    return omitNils({
      alignLabels: labelAlignment,
    });
  }
  function getToolTipTextProps({ tooltipDisabled, tooltipText }) {
    return omitNils({
      tooltipText: !tooltipDisabled ? tooltipText : '',
    });
  }
  function getComparisonOpacityProps() {
    return omitNils({
      opacity: config.value.comparisonEnabled
        ? config.value.fillOpacityPrior
        : config.value.fillOpacityCurrent,
    });
  }
  function createLegend(props) {
    return generateInitialLegend(
      {
        nameField: PieChartConstant.CATEGORY,
        fillField: PieChartConstant.FILL,
        ...getComparisonOpacityProps(props),
      },
      true
    );
  }
  function createLabel(settingProps) {
    return chart.value.children.unshift(Label.new(root.value, settingProps));
  }
  function donutSum(series) {
    const { data_columns } = config.value.widgetData.metadata;
    if (
      config.value.plotType === PieChartConstant.PLOT_DONUT &&
      data_columns.selected.length === 1
    ) {
      const centeryPercent = config.value.pieSemiCircleAngle ? 75 : 50;
      const label = series.children.push(
        Label.new(root.value, {
          text: PieChartConstant.DONUT_SUM,
          fontSize: 22,
          centerX: percent(50),
          centerY: percent(centeryPercent),
          populateText: true,
          oversizedBehavior: PieChartConstant.OVERSIZED_DONUT_SUM,
        })
      );
      series.onPrivate(Constant.WIDTH, (width) => {
        label.set(PieChartConstant.DONUT_MAXWIDTH, width * 0.7);
      });
      series.onPrivate(PieChartConstant.DONUT_VALUE_SUM, () => {
        label.text.markDirtyText();
      });
      return series;
    }
  }
  function calculateLabelMaxWidth(widgetWidth, hasComparisonStatus) {
    let maxWidth = 0;
    // we are setting up label width for pie chart based upon resizing the widget width and also for comparison so the text will be wrapped with ellipsis
    if (widgetWidth === 3) {
      maxWidth = hasComparisonStatus ? 100 : 80;
    } else if (widgetWidth > 3 && widgetWidth <= 5) {
      maxWidth = hasComparisonStatus ? 100 : 120;
    } else if (widgetWidth > 5 && widgetWidth <= 7) {
      maxWidth = hasComparisonStatus ? 140 : 250;
    } else if (widgetWidth > 7 && widgetWidth <= 9) {
      maxWidth = hasComparisonStatus ? 130 : 230;
    } else if (widgetWidth > 9 && widgetWidth <= 12) {
      maxWidth = hasComparisonStatus ? 200 : 340;
    }
    /* Max width cant be set as percentage here */
    return omitNils({ maxWidth });
  }

  function setVariableRadiusForSlices(series) {
    series.slices.template.adapters.add(Constant.RADIUS, (radius, target) => {
      const { dataItem } = target;
      const high = series.getPrivate(Constant.VALUE_HIGH);
      if (dataItem) {
        const value = dataItem.get(Constant.VALUE_WORKING, 0);
        return (radius * value) / high;
      }
      return radius;
    });
  }

  return {
    createAllSeries,
  };
}
