'use strict';
import angular from 'angular';
import _ from 'lodash';
import EventBus from '@/modules/core/app/helpers/EventBus';

angular.module('widget.am5.ctrls', [])
    .controller('Am5ChartWidgetController', Am5ChartWidgetController);

/**
 * This Controller is the light version of ChartWidgetController in which we removed all amCharts related code because we
 * are implementing amCharts code in VueJS
 *
 * @ngInject
 */
function Am5ChartWidgetController(
    $scope,
    $q,
    ExportFactory,
    $WidgetEvents,
    $AnnotationEvents,
    ChartUtilFactory,
    LoadingState,
    DrawOption,
    WidgetType,
    AppFactory,
    DashboardContextService,
    WidgetFactory,
    WidgetUtilService,
    WidgetSortUtilService,
    ChartFactory,
    BubbleShapes,
    BulletShapes,
    LineTypes,
    DesignFactory,
    MomentDateFormat,
    DateRangeFactory,
    RelativeDateRange,
    WidgetBuilderService,
    WidgetBuilderConstants,
    ColumnFormat,
    ChartAxisService,
    ChartPlotType,
    HandTypes,
    GaugeBigNumberPosition,
    PictorialTypes,
    AppModule
) {
    _registerEvents();

    function $onDestroy() {
        _unregisterEvents();
    }

    // INIT
    if (!$scope.$parent.isComparison) {
        setDefaults();
        setMetadataDefaults();
        buildChart();
    }

    /**
     * Set default values
     */
    function setDefaults() {
        $scope.chartData = [];
        $scope.chartDataIndex = 0;
        $scope.canDrill = false;
        $scope.isRotated = false;
        $scope.isExporting = ExportFactory.getIsExporting();
        $scope.comparisonBubbleColor = '';
        $scope.chartPalette = DashboardContextService.resolveChartPalette($scope.widget.metadata.chart_palette);
        $scope.canShowWidget = canShowWidget;
        $scope.getChartId = getChartId;
    }

    /**
     * Set default metadata values
     */
    function setMetadataDefaults() {
        $scope.$emit('chart:setMetadataDefaults', $scope.widget.metadata, $scope.widgetTypeId);
    }

    function setHelpState(options, columns) {
        $scope.state.helpNeeded = false;
        if (options[DrawOption.SHOW_EMPTY_DATES] && !options.dateFormats.canShowEmptyDates) {
            $scope.state.helpNeeded = true;
            $scope.state.helpMessage = "Draw Option Not Applied";
            $scope.state.helpAction = ["The current Time Grouping cannot display empty dates.",
                "The chart will be unaffected."];
        }
        else if (options.helpNeeded) {
            $scope.state.helpNeeded = options.helpNeeded.enabled;
            $scope.state.helpMessage = options.helpNeeded.helpMessage;
            $scope.state.helpAction = options.helpNeeded.helpAction;
        }
    }

    function setDefaultValues(obj, values) {
        const keys = Object.keys(values);
        for (let i=0; i<keys.length; i++) {
            if (!obj[keys[i]]) {
                obj[keys[i]] = values[keys[i]];
            }
        }
    }

    function setChartDefaultOptions() {
        const options = $scope.widget.metadata.draw_options;
        const defaultFontColor = AppFactory.getUser().getThemeType() === 'dark' ? '#FFFFFF': '#000000';

        switch ($scope.widgetTypeId) {
            case WidgetType.BARCHART:
                setDefaultValues(options, {
                    [DrawOption.ROUNDED_CORNERS]: 0,
                    [DrawOption.SHOW_COLUMN_SHADOWS]: false,
                    [DrawOption.FILL_TYPE]: 'solid',
                    [DrawOption.GRADIENT_COLOR]: '#FFFFFF',
                    [DrawOption.IS_LOG_SCALED]: false,
                    [DrawOption.CURVED_COLUMNS]: false,
                    [DrawOption.RADIAL_INNER_RADIUS]: 40,
                    [DrawOption.BULLETS_SHAPE]: BulletShapes.CIRCLE,
                    [DrawOption.OPTION_FONT_COLOR_PICKER]: defaultFontColor,
                });
                break;
            case WidgetType.LINECHART:
                setDefaultValues(options, {
                    [DrawOption.BULLETS_SHAPE]: BulletShapes.CIRCLE,
                    [DrawOption.LINE_TYPE]: LineTypes.LINE,
                    [DrawOption.OPTION_FONT_COLOR_PICKER]: defaultFontColor,
                });
                break;
            case WidgetType.COMBINATIONCHART:
                setDefaultValues(options, {
                    [DrawOption.ROUNDED_CORNERS]: 0,
                    [DrawOption.SHOW_COLUMN_SHADOWS]: false,
                    [DrawOption.FILL_TYPE]: 'solid',
                    [DrawOption.GRADIENT_COLOR]: '#FFFFFF',
                    [DrawOption.BULLETS_SHAPE]: BulletShapes.CIRCLE,
                    [DrawOption.OPTION_FONT_COLOR_PICKER]: defaultFontColor,
                });
                break;
            case WidgetType.BUBBLECHART:
                setDefaultValues(options, {
                    [DrawOption.BACKGROUND_GRADIENT]: 'solid',
                    [DrawOption.GRADIENT_COLOR]: '#777',
                    [DrawOption.BUBBLE_MAX_BUBBLES]: 100,
                    [DrawOption.SHOW_VALUES_ON_SHAPES]: false,
                    [DrawOption.SHAPE_OPACITY]: 70,
                    [DrawOption.SHOW_SHAPE_SHADOW]: false,
                    [DrawOption.SHAPE_SIZE]: 50,
                    [DrawOption.BUBBLE_SHAPE]: BubbleShapes.CIRCLE,
                    [DrawOption.OPTION_FONT_COLOR_PICKER]: defaultFontColor,
                });
                break;
            case WidgetType.PIECHART:
                setDefaultValues(options, {
                    [DrawOption.ROUNDED_CORNERS]: 0,
                    [DrawOption.SHOW_COLUMN_SHADOWS]: false,
                    [DrawOption.PIE_SEMICIRCLE_ANGLE]: false,
                    [DrawOption.FILL_TYPE]: 'solid',
                    [DrawOption.GRADIENT_COLOR]: '#FFFFFF',
                    [DrawOption.SHOW_GRAINY_PATTERN]: false,
                    [DrawOption.PIE_COLOR_PICKER]: '#FFFFFF',
                    [DrawOption.PIE_VARIABLE_RADIUS]: false,
                    [DrawOption.CLASSIC_DRILL_DOWN_BEHAVIOUR]: false,
                    [DrawOption.PIE_GRADIENT_OPTIONS]: 'solid',
                    [DrawOption.OPTION_FONT_COLOR_PICKER]: defaultFontColor,
                    [DrawOption.OPTION_ALIGN_LABELS]: false,
                    [DrawOption.V2_INNER_RADIUS]: 0,
                });
                break;
            case WidgetType.FUNNELCHART:
                setDefaultValues(options, {
                    [DrawOption.PLOT_TYPE]: 'classic',
                    [DrawOption.FILL_TYPE]: 'solid',
                    [DrawOption.GRADIENT_COLOR]: '#FFFFFF',
                    [DrawOption.GRAIN_DENSITY]: 0,
                    [DrawOption.INVERT_METRICS]: false,
                    [DrawOption.ORDER_METRICS]: false,
                    [DrawOption.PICTORIAL_OPTIONS]: PictorialTypes.MALE,
                    [DrawOption.OPTION_FONT_COLOR_PICKER]: defaultFontColor,
                });
                break;
            case WidgetType.GAUGECHART:
                setDefaultValues(options, {
                    [DrawOption.PLOT_TYPE]: ChartPlotType.GAUGE,
                    [DrawOption.FILL_TYPE]: 'solid',
                    [DrawOption.SHOW_TICKS]: false,
                    [DrawOption.SHOW_LABELS]: true,
                    [DrawOption.GAUGE_BIG_NUMBER_POSITION]: GaugeBigNumberPosition.NONE,
                    [DrawOption.GAUGE_SHOW_VALUE]: true,
                    [DrawOption.GAUGE_HAND_TYPE]: HandTypes.NONE,
                    [DrawOption.GRADIENT_COLOR]: '#EEEEEE',
                    [DrawOption.GAUGE_THICKNESS]: 46,
                    [DrawOption.TOOLTIP]: true,
                    [DrawOption.HAND_COLOR]: '#C4C2C2',
                    [DrawOption.GAUGE_RANGE]: 180,
                    [DrawOption.GRAIN_DENSITY]: 0,
                    [DrawOption.GAUGE_GRADIENT_TO]: '#EEEEEE',
                    [DrawOption.BACKGROUND_COLOR]: '#EEEEEE',
                    [DrawOption.GAUGE_GRADIENT_TARGET]: 'metric',
                    [DrawOption.SHOW_SHAPE_SHADOW]: false,
                    [DrawOption.OPTION_FONT_COLOR_PICKER]: defaultFontColor,
                });
                break;
        }
    }

    /**
     * Build CHART widget
     */
    function buildChart(redrawOptions) {
        var state = $scope.state;

        if ($scope.widget.has_live_integration && DashboardContextService.widgetHasGlobalFilter($scope.widget.metadata.data_source)) {
            state.loadingState = LoadingState.HAS_GLOBAL_FILTERS;
            return false;
        }

        if (state.isCreating && !state.canSave) {
            return false;
        }

        // Build table if can save widget or if not a preview (i.e. display), and not a comparison chart
        if (!$scope.$parent.isComparison) {
            var metadata = $scope.widget.metadata;

            state.loadingState = LoadingState.BUILDING;
            // Set all columns needed to build x-y axes
            var columns = {};
            columns.selected = metadata.data_columns.selected;
            columns.grouped = metadata.data_columns.grouped;
            columns.data_source = metadata.data_source;

            columns.selected = metadata.data_columns.selected

            // Set all config chart options
            var options = _.extend({}, metadata.draw_options);

            options.widgetId = $scope.widget.id;
            options.support_state = _.get(metadata.data_source, 'geo_columns.support_state');
            options.support_country = _.get(metadata.data_source, 'geo_columns.support_country');
            // Boolean to tell chart to execute addInitHandler empty data handler
            options.allEmptyData = false;
            options.isSample = WidgetFactory.useSampleData(metadata);

            // State settings
            options.isThumbPreview = state.isThumbPreview;
            options.isDrilling = state.isDrilling;

            options.redrawOptions = redrawOptions ? redrawOptions.selected : {};
            options.forceComparison = metadata.compare_to_prior_period;
            options.annotations = metadata.dynamic ? metadata.dynamic.annotations : [];
            options.chartPalette = DashboardContextService.resolveChartPalette(metadata.chart_palette);

            options.plot_type = metadata.draw_options[DrawOption.PLOT_TYPE];
            options.chartId = $scope.chartId;
            options.chartType = $scope.widgetTypeId;
            options.chartDataIndex = $scope.chartDataIndex = redrawOptions ?  redrawOptions.chartDataIndex : $scope.chartDataIndex;
            options.titles = redrawOptions ? redrawOptions.title : [];
            options.is_multi_grouped = metadata.is_multi_grouped;
            options.dateFormats = WidgetFactory.getDateFormats(metadata.time_grouping);
            options.time_grouping = metadata.time_grouping ? metadata.time_grouping.toLowerCase() : null;

            options.is_overriding_date_range = metadata.is_overriding_date_range;
            options.start_date_override = metadata.start_date_override;
            options.end_date_override = metadata.end_date_override;
            options.relative_date_range = metadata.relative_date_range;

            options.line_columns = metadata.line_columns;

            options.move_y_axes = metadata.move_y_axes;

            const dateRange = AppFactory.getDateRange();
            const comparisonDateRange = AppFactory.getComparisonDateRange();
            $scope.isDemoMode = DashboardContextService.isDemoModeEnabled();
            $scope.isBenchmarksEnabled = canShowBenchmarkToggle();
            $scope.dashboardId = DesignFactory.getCurrentPage()?.id;
            $scope.dateRange = {
                start_date:  dateRange.start,
                end_date: dateRange.end,
            }

            if (comparisonDateRange.enabled) {
                $scope.dateRange = {
                    ...$scope.dateRange,
                    comparison_start_date:  comparisonDateRange.start,
                    comparison_end_date: comparisonDateRange.end,
                }
            }

            // Set the default chart options
            // NOTE: options set above need to be set before default chart options since they are needed
            options = _.extend(options, ChartFactory.getOptions(options, columns));
            options.listeners = {};

            $scope.canDrill = (columns.grouped.length > $scope.chartDataIndex + 1);
            $scope.isRotated = options.is_rotated;

            if (WidgetUtilService.isBubbleChart(options.chartType)) {
                options.is_multi_grouped = metadata.is_multi_grouped = false;
                if (columns.grouped && columns.grouped.length) {
                    $scope.canDrill = false;
                }
                $scope.comparisonBubbleColor = DashboardContextService.resolveChartPalette()[1];
                // Remove any non numeric columns that may have slipped in from other widget types
                columns.selected = metadata.data_columns.selected = _.filter(columns.selected, function (column) {
                    return AppFactory.util.column.isNumeric(column.format) && !AppFactory.util.column.isTime(column.format);
                });
            } else {
                // Remove any non numeric columns that may have slipped in from other widget types
                columns.selected = _.filter(columns.selected, (column) => {
                    return AppFactory.util.column.isNumeric(column.format);
                });
                columns.selected = columns.selected.map((column, index) => {
                    column.index = index;
                    return column;
                });
            }

            if (WidgetUtilService.isSerialChart(options.chartType)) {
                ChartAxisService.setYAxisPositionForAllColumns(metadata);
            }

            setHelpState(options, columns);

            state.loadingState = LoadingState.FETCHING;

            setChartDefaultOptions();

            options.dataPromise = $q.resolve(ChartFactory.getChartData($scope.widget, options, $scope.chartDataIndex)).then(function(json) {
                let finalData = json.data;

                if (!!finalData && WidgetUtilService.isBubbleChart(options.chartType) && columns.selected.length > 2 && !WidgetFactory.hasNoDataLoadingState(state.loadingState)) {
                    finalData = filterBubbleChartData(finalData, json.has_comparison_data, columns.selected.slice(0, 3));
                }

                state.loadingState = WidgetFactory.evaluateLoadingState(finalData, metadata, json.has_comparison_data);
                WidgetFactory.widgetLoaded($scope.widget);
                $scope.chartData = finalData;
                $scope.hasComparisonData = json.has_comparison_data;
                $scope.clientCurrencySymbol = AppFactory.getCurrentClientCurrency();

                if (!WidgetUtilService.isBubbleChart(options.chartType) && !WidgetFactory.hasNoDataLoadingState(state.loadingState)) {
                    const groupedFields = columns.grouped.map(({ field }) => field);
                    let selectedColumns = columns.selected.filter(column => !groupedFields.includes(column.field));
                    if (checkIsAllEmptyData(finalData, selectedColumns, $scope.hasComparisonData)) {
                        state.loadingState = LoadingState.ALL_EMPTY_DATA;
                        $scope.chartData = [];
                    }
                }

                if ($scope.isExporting) {
                    ExportFactory.chartReadyCounter.incRef();
                    ExportFactory.chartReadyCounter.decRef();
                }

                if (WidgetFactory.hasNoDataLoadingState(state.loadingState)) {
                    $scope.chartData = [];
                    return null;
                }

                return json;
            }, function(error) {
                state.loadingState = WidgetFactory.evaluateLoadingState(error.data || [], metadata);
                WidgetFactory.widgetLoaded($scope.widget);
                return null;
            });

            WidgetFactory.init(options, columns, $scope.widget);
        }
    }

    function checkIsAllEmptyData(finalData, selectedColumns, hasComparison) {
        const nullOrZeroValueStrings = ['null', 'undefined', '0', '00', '0.00', '00:00', '00:00:0', '0:00:00', '00:00:00'];

        return !_.some(finalData, datum =>
            _.some(selectedColumns, column => {
                if (column.format === ColumnFormat.FORMAT_TIME) {
                    if (hasComparison) {
                        return (datum['current_period'] && !nullOrZeroValueStrings.includes(datum['current_period'][column.field])) ||
                            (datum['prior_period'] && !nullOrZeroValueStrings.includes(datum['prior_period'][column.field]));
                    }
                    return !nullOrZeroValueStrings.includes(datum[column.field]);
                }
                if (hasComparison) {
                    return (datum['current_period'] && _.toNumber(datum['current_period'][column.field])) ||
                        (datum['prior_period'] && _.toNumber(datum['prior_period'][column.field]));
                }
                return !!_.toNumber(datum[column.field]);
            })
        );
    }

    function getChartId() {
        const { widget } = $scope;
        return WidgetBuilderService.isCurrentWidgetInBuildMode(widget.id)
        && !WidgetBuilderService.getIsEditing() // isEditing means not creating a new widget.
            ? WidgetBuilderConstants.PREVIEW_CHART
            : widget.chartId || widget.id;
    }

    function filterBubbleChartData(data, hasComparison, selected) {
        const fields = selected.map(metric => metric.field);
        if (hasComparison) {
            return data.filter((dataItem) => isBubbleChartValidDataItem(dataItem.current_period, fields) &&
                isBubbleChartValidDataItem(dataItem.prior_period, fields));
        } else {
            return data.filter((dataItem) => isBubbleChartValidDataItem(dataItem, fields));
        }
    }

    function isBubbleChartValidDataItem(dataItem, fields) {
        const values = fields.map(field => dataItem[field]);
        try {
            // Data for first and second metric should not be null (can be zero)
            // and data for third metric should be some value
            return values[0] !== null && values[1] !== null && Number(values[2]);
        } catch (e) {
            return false;
        }
    }

    /**
     * Build a fresh NEW chart
     */
    function buildNewChart() {
        $scope.chartDataIndex = 0;
        buildChart({
            selected: {},
            chartDataIndex: $scope.chartDataIndex,
            title: [],
            labels: []
        })
    }

    function canShowBenchmarkToggle() {
        return AppFactory.getUser().isModuleAvailable(AppModule.BENCHMARKS);
    }

    /**
     * Handles if and how we should rebuild the chart widget
     */
    function handleRebuild() {
        var state = $scope.state;
        var metadata = $scope.widget.metadata;

        updateColumnOrder(metadata);

        $scope.chartPalette = DashboardContextService.resolveChartPalette($scope.widget.metadata.chart_palette);

        // Widget type state needs to update if widget type has changed
        WidgetFactory.setStateForWidgetType(state, $scope.widgetTypeId, metadata);

        var hasSelectedColumns = _.size(metadata.data_columns.selected) > 0;
        var hasGroupedColumns = _.size(metadata.data_columns.grouped) > 0;
        var requiresGroupby = state.widgetType.requires_group_by;

        // If a group by is selected, GAUGECHART needs to switch to a bar chart
        if (!hasSelectedColumns || (WidgetUtilService.isSerialChart(state.widgetType.id) && !hasGroupedColumns)) {
            // clearChart();
            // state.loadingState = LoadingState.INCOMPLETE;
        } else if ((hasSelectedColumns && hasGroupedColumns) || (hasSelectedColumns && !requiresGroupby)) {
            buildNewChart();
        }
    }

    function updateColumnOrder(metadata) {
        var selectedObj = AppFactory.arrayToMemoizedObj(metadata.data_columns.selected, 'field');

        // Delete element from sort array if not in selected columns any more
        var index = _.findIndex(metadata.sort_by, function(field) {
            return !selectedObj[field];
        });
        WidgetSortUtilService.ensureColumnOrder(metadata, index);
    }

    var $widgetRebuildFn;
    function _registerEvents() {
        $widgetRebuildFn = $scope.$on($WidgetEvents.WIDGET_REBUILD, handleRebuild);
        $scope.$on($WidgetEvents.WIDGET_FETCH_STATUS, function (e, status) {
            $scope.state.loadingState = status;
        });
        $scope.$on('widget:setOrder', function(e, column, isMultiSorting) {
            ChartFactory.setOrder($scope.widget.metadata, column, isMultiSorting);
            handleRebuild();
        });

        $scope.$on('widget:setYAxisPosition', function(e, column) {
            ChartAxisService.setYAxisPosition($scope.widget.metadata, column);
            handleRebuild();
        });

        $scope.$on('$destroy', function() {
            ChartUtilFactory.resetAll();
            $widgetRebuildFn();
        });

        EventBus.listen($WidgetEvents.AM5_COUNTRY_DRILLDOWN, (data) => {
            $scope.widget.metadata.map_id = data.map_id;
        });
    }

    function _unregisterEvents() {
        EventBus.destroy($WidgetEvents.AM5_COUNTRY_DRILLDOWN);
    }

    function canShowWidget() {
        return !$scope.loadingState;
    }
}
