import { ChartConfig } from '@/modules/core/charts/am5/base/models/ChartConfig';
import { FunnelSeries } from '@/modules/core/charts/models/FunnelSeries';

/**
 * @class SliceChartConfig
 * @extends ChartConfig
 */
export class SliceChartConfig extends ChartConfig {
  /**
   * @var {Array<SliceSeries>}
   */
  series;

  plotType;

  showLegend;

  isRotated;

  isNormalized;

  showLabels;

  showLabelNames;

  showLabelPercent;

  showLabelValues;

  hasTooltip;

  isHandDrawn;

  showTicks;

  otherSlicePct;

  labelPct;

  neckHeight;

  neckWidth;

  backgroundGradient;

  gradientColor;

  legendData;

  chartPalette;

  hideGridLines;

  isComparison;

  dataFormats;

  fillType;

  smoothLines;

  canDrillDown;

  grainDensity;

  invertMetrics;

  orderMetrics;

  comparisonData;

  comparisonDataFormats;

  pictorialOptions;

  constructor(model = {}) {
    super(model);

    this.plotType = model.plotType;
    this.showLegend = model.showLegend;
    this.isRotated = model.isRotated;
    this.isNormalized = model.isNormalized;
    this.showLabels = model.showLabels;
    this.showLabelNames = model.showLabelNames;
    this.showLabelPercent = model.showLabelPercent;
    this.showLabelValues = model.showLabelValues;
    this.hasTooltip = model.hasTooltip;
    this.isHandDrawn = model.isHandDrawn;
    this.isComparison = model.hasComparison;
    this.showTicks = model.showTicks;
    this.otherSlicePct = model.otherSlicePct;
    this.labelPct = model.labelPct;
    this.neckHeight = model.neckHeight;
    this.neckWidth = model.neckWidth;
    this.backgroundGradient = model.backgroundGradient;
    this.gradientColor = model.gradientColor;
    this.legendData = model.legendData;
    this.chartPalette = model.chartPalette;
    this.cursorOverStyle = model.cursorOverStyle === undefined ? model.cursorOverStyle : null;
    this.hideGridLines = model.hideGridLines;
    this.dataFormats = model.dataFormats;
    this.fillType = model.fillType;
    this.smoothLines = model.smoothLines;
    this.canDrillDown = model.canDrillDown;
    this.grainDensity = model.grainDensity;
    this.invertMetrics = model.invertMetrics;
    this.orderMetrics = model.orderMetrics;
    this.pictorialOptions = model.pictorialOptions;
    this.fontColorPicker = model.fontColorPicker;

    this.series = model.series.map((singleSeries) => this.getSeriesElement(singleSeries));
    this.series.forEach((singleSeries) => {
      singleSeries.seriesType = this.getSeriesType();
    });
  }

  hasComparison() {
    return this.comparisonData && this.comparisonData.length;
  }

  /**
   * Return Pyramid / Funnel
   */
  getSeriesType() {}

  isFunnelSeries() {
    return false;
  }

  isPyramidSeries() {
    return false;
  }

  isPictorialSeries() {
    return false;
  }

  getSeriesElement(singleSeries) {
    return new FunnelSeries(singleSeries);
  }
}
